import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchableField } from '../../directives/grid-filter/model/model';
import { Condition } from '../../model/criteria/condition.model';

@Component({
  selector: 'vl-filter-group',
  templateUrl: 'filter-group.component.html',
  styleUrls: ['filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterGroupComponent {
  @Input() searchableFields: SearchableField[];
  @Input() cookieBucket: string;
  @Input() filterContainer: string;

  @Output() updateSearchFilters = new EventEmitter<Condition[]>();

  onFiltersUpdate(conditions: Condition[]): void {
    this.updateSearchFilters.emit(conditions);
  }
}
