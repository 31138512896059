import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Condition } from '../../model/criteria/condition.model';
import { Operator } from '../../model/criteria/operator.model';
import { EntityUtil } from '../../service/entity.util';
import { OperatorsByType } from './model/model';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'grid-filter',
  templateUrl: 'grid-filter.component.html'
})
export class GridFilterComponent implements OnInit {
  @Input()
  condition: Condition;

  @Input()
  container: string;

  @Output()
  onRemove = new EventEmitter<any>();

  @Output()
  onUpdate = new EventEmitter<Condition>();

  @ViewChild('filterDropdown')
  filterDropdown: BsDropdownDirective;

  @ViewChild('filterValueInput')
  filterValueInput: ElementRef;

  menuState: { isOpen: boolean } = { isOpen: false };

  filterForm: FormGroup;
  opForm: FormGroup;

  operatorsByType: string[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.operatorsByType = OperatorsByType[this.condition.type];

    this.filterForm = this.formBuilder.group({
      filterValue: (EntityUtil.isPresent(this.condition.value) && this.condition.value) || ''
    });

    this.opForm = this.formBuilder.group({
      filterType:
        (EntityUtil.isPresent(this.condition.operator) && Operator[this.condition.operator]) || this.operatorsByType[0]
    });
  }

  changeOp(event: any, operator: string): void {
    setTimeout(() => {
      this.opForm.controls['filterType'].setValue(operator);
      this.opForm.controls['filterType'].markAsDirty();
      this.focusInput();
    });
  }

  handleClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  removeFilter() {
    this.onRemove.emit();
  }

  save() {
    if (this.filterForm.dirty || this.opForm.dirty) {
      let operatorDescription = this.opForm.controls['filterType'].value;

      let operator = Object.keys(Operator).find(key => {
        return Operator[key] === operatorDescription;
      });

      this.condition.value = this.filterForm.controls['filterValue'].value;
      this.condition.operator = operator;
      this.condition.operatorDescription = operatorDescription;

      this.onUpdate.emit(this.condition);
    }

    this.filterDropdown.hide();
  }

  getDisplayValue(condition: Condition) {
    if (EntityUtil.isPresent(condition)) {
      return condition.type === 'LIST' && EntityUtil.isPresent(condition.valueOptions)
        ? condition.valueOptions[condition.value]
        : condition.value;
    }
  }

  private keyBindFilterBound: EventListener = this.keyBindFilter.bind(this);

  public onFilterShown() {
    window.document.addEventListener('keydown', this.keyBindFilterBound);
    this.focusInput();
  }

  public onFilterHidden() {
    window.document.removeEventListener('keydown', this.keyBindFilterBound);
    this.focusInput();
  }

  private focusInput() {
    if (this.filterValueInput) {
      this.filterValueInput.nativeElement.focus();
    }
  }

  public changeMenuState(isOpen: boolean): void {
    this.menuState.isOpen = isOpen;
  }

  private keyBindFilter(event: any): void {
    const which = event && event.which;

    if (which === 13) {
      this.save();
    } else if (which === 27) {
      this.menuState.isOpen = false;
    }
  }
}
