import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetAction } from '../../models';
import { DiscountType, OutputDiscountData } from '../../models/apply-discount-form.model';
import { FrequencyUnit, PartialChargeGroupItem, PartialLineItem } from '../../models/line-item.model';
import { ListItem } from '../../models/widget-item-list.model';

interface ListItemMap {
  [key: string]: ListItem;
}

@Component({
  selector: 'vl-quote-totals-widget',
  styleUrls: ['quote-totals-widget.component.scss'],
  templateUrl: 'quote-totals-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteTotalsWidgetComponent implements OnInit {
  @Input() lineItems: PartialLineItem[];
  @Input() isReadonly: boolean;

  totals: ListItem[];
  selectedTotalItemId: string;
  discountTypes = [{ value: DiscountType.MARKUP_PERCENT }, { value: DiscountType.DISCOUNT_PERCENT }];

  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();
  @Output() private handleDiscountChange = new EventEmitter<OutputDiscountData>();

  ngOnInit(): void {
    const totals = this.lineItems
      .flatMap(({ chargeGroupItems }) => chargeGroupItems)
      .map(chargeGroupItem => {
        const { frequencyUnit, frequencyDuration } = chargeGroupItem;
        const isYearlyAlias = frequencyUnit === FrequencyUnit.MONTHLY && frequencyDuration === 12;

        if (isYearlyAlias) {
          return {
            ...chargeGroupItem,
            frequencyUnit: FrequencyUnit.MONTHLY,
            frequencyDuration: 1
          };
        }

        return chargeGroupItem;
      })
      .reduce(
        (
          totalsMap: ListItemMap,
          { chargeMethod, frequencyUnit, frequencyDuration, netPrice }: PartialChargeGroupItem
        ) => {
          const id = [chargeMethod, frequencyUnit, frequencyDuration].filter(Boolean).join('-');
          const chargeGroupItem = totalsMap[id];
          const currentPrice = (chargeGroupItem && chargeGroupItem.value) || 0;

          return {
            ...totalsMap,
            [id]: {
              id,
              label: id,
              value: currentPrice + netPrice,
              isClickable: true
            }
          };
        },
        {}
      );

    this.totals = Object.values(totals);
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }

  onTotalItemSelected(id: string): void {
    this.selectedTotalItemId = id;
  }

  onDiscountChange(discountData: OutputDiscountData): void {
    this.selectedTotalItemId = undefined;
    this.handleDiscountChange.emit(discountData);
  }
}
