import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItem, WidgetAction } from '../../models';

@Component({
  selector: 'vl-base-widget',
  templateUrl: './base-widget.component.html',
  styleUrls: ['./base-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseWidgetComponent {
  @Input() widgetTitle: string;

  actionDropdownActions = Object.values(WidgetAction).map(v => ({ id: v, value: v }));

  @Output() handleWidgetActionSelect = new EventEmitter<WidgetAction>();

  onWidgetActionSelect(item: DropdownItem<WidgetAction>): void {
    this.handleWidgetActionSelect.emit(item.value);
  }
}
