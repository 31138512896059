import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItem, Filter, Filterable } from '../../../models';

@Component({
  selector: 'vl-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFilterComponent implements OnInit {
  @Input() isRightAligned?: boolean;
  @Input() dropdownId?: string;

  dropdownItems: DropdownItem[];

  @Output() private filterSelected = new EventEmitter<Filter>();
  private filterables: Filterable[] = [];

  get menuItems(): Filterable[] {
    return this.filterables;
  }

  @Input()
  set menuItems(value: Filterable[]) {
    this.filterables = value;

    this.initDropdownItems();
  }

  onMenuItemSelect({ id: index }: DropdownItem): void {
    this.filterSelected.emit(new Filter(this.filterables[Number(index)]));
  }

  ngOnInit(): void {
    this.initDropdownItems();
  }

  private initDropdownItems() {
    this.dropdownItems = this.filterables.map(({ label, className }, index) => ({
      id: String(index),
      value: label,
      className
    }));
  }
}
