import { BoundData } from './bound.data';
import { LineItemDTO } from '../../../catalog/model/line-item.dto.model';
import { UUID } from '../../../../service/uuid.service';
import { Selectable } from '../../model/selectable.model';

export class SectionValue {
  value: any = '';
  valueOptions: any[] = [];
  displayValues: { [index: string]: string } = {};
  properties: any;
}

export class PriceValue {
  priceOptions: { [index: string]: any[] } = {};
}

// TODO ALE REVIEW AND REFACTOR
export class Section implements Selectable {
  id: string;
  name: string;
  readonly classId: string;
  dynamicSelector: string;

  label: string;
  pricingEnabled: boolean;

  // path: string;

  get path(): string {
    return (this.boundData && this.boundData.path.path) || '';
  }

  get abstractPath(): string {
    return (this.boundData && this.boundData.path.abstractPath) || '';
  }

  controlType: string;
  order: number;
  cssClass: string;

  parent: Section;
  children: Section[] = [];

  parentId: string;

  page: string;
  typeName: string;
  portName: string;

  hash?: string;

  model: LineItemDTO;
  template: string;
  styles: string;
  script: string;
  properties: { [index: string]: string } = {};

  templateUrl?: string;
  stylesUrl?: string;
  scriptUrl?: string;
  propertiesUrl: string;

  boundData: BoundData;

  readOnly: boolean;
  required: boolean;

  sectionValue: SectionValue;
  priceValue: PriceValue;

  hidden: boolean;
  isStatic: boolean;

  constructor(name: string) {
    this.id = UUID.UUID();
    this.classId = this.id;
    this.name = name;
    this.label = name;
  }

  addChild(child: Section): void {
    child.parent = this;
    child.parentId = this.id;
    this.children.push(child);
  }

  removeChild(child: Section): void {
    const index = this.children.findIndex(c => c.id === child.id);
    this.children.splice(index, 1);

    child.parent = void 0;
    child.parentId = void 0;
  }
}

export enum fileName {
  properties = 'properties.json',
  metadata = 'metadata.json'
}
