import {Type} from "../model/type.model";

import * as _ from 'lodash';
import {Port} from "../model/port.model";
import {ProductModel} from "../model/product.model";
import {EntityUtil} from "../../../service/entity.util";
import {Dictionary} from "../../../model/dictionary.model";
import {Attribute} from "../model/attribute.model";

export class ModelUtils {

    static collectHierarchically(type: Type, collectionName: string) {
        let currentType = type;

        let list = [].concat(currentType[collectionName]);

        while (currentType.parentType) {
            currentType = currentType.parentType;

            list = list.concat(currentType[collectionName]);
        }

        return _.uniqWith(list, (objValue, othValue) => {
            return (objValue['name'] === othValue['name']);
        });
    }

    static getTypeChildren(type: Type, modelTypes: Type[]): Type[] {
        if (!modelTypes) { return [] }

        return modelTypes.filter(child => child.parentType && child.parentType.id === type.id);
    }

    //Returns abstract Type along with all children
    static getTypeHierarchy(type: Type, modelTypes: Type[]): Type[] {
        if (!modelTypes) { return [] }
        let result: Type[] = [type];

        modelTypes.filter(child => child.parentType && child.parentType.id === type.id)
            .forEach((t) => result = result.concat(ModelUtils.getTypeHierarchy(t, modelTypes)));

        return result;
    }

    static getTypeLeafs(type: Type, modelTypes: Type[]): Type[] {
        if (!modelTypes) { return [] }

        let result: Type[] = [];
        for (let child of modelTypes) {
            if (child.parentType && child.parentType.id === type.id) {
                result = result.concat(ModelUtils.getTypeLeafs(child, modelTypes));
            }
        }
        return result.length > 0 ? result : [type];
    }


    static isTypeInPort(type: Type, modelTypes: Type[]): boolean {
        let portTypes = _.flatten(modelTypes
            .filter(type =>
                type.ports && type.ports.length > 0
            )
            .map(type => {
                return type.ports.map(port => port.portType)
            }));


        return portTypes.some(portType => {
            return type.id === portType.id || ModelUtils.isChildOf(type, portType);
        });
    }

    static isChildOf(child: Type, parent: Type): boolean {
        if (child.id === parent.id) { return false; }
        if (child.parentType && (child.parentType.id === parent.id)) { return true; }

        let currentType = child;

        while (currentType.parentType) {
            currentType = currentType.parentType;

            if (currentType.id === parent.id) {
                return true;
            }
        }

        return false;
    }

    static collectNamedConstraints(type: Type): String[] {
        return ModelUtils.collectHierarchically(type, 'constraints').filter(c => c.named).map(c => c.name);
    }

    static collectTypePorts(type: Type): Port[] {
        let ports: Port[] = [].concat(type.ports);

        let currentType = type;

        while (currentType.parentType) {
            currentType = currentType.parentType;

            ports = ports.concat(currentType.ports);
        }

        return ports;
    }

    static getPortDomain(port: Port, types: Type[]) {
        let start = port.order && port.order.length > 0 ? port.order : [port.type];
        let result = [];
        start.forEach((typeName) => {
            let t = types.find((t) => t.name === typeName);
            result = result.concat(ModelUtils.getTypeHierarchy(t, types));
        });
        return result;
    }

    static getTypePorts(type: Type, modelTypes: Type[]): Port[] {
        let typeChildren = ModelUtils.getTypeChildren(type, modelTypes);

        if (typeChildren.length > 0) {
            return type.ports;
        } else {
            if (type.parentType.ports && type.parentType.ports.length > 0) {
                return type.parentType.ports;
            } else {
                return type.ports;
            }
        }
    }

    static getPortTypes(port: Port, modelTypes: Type[]) {
        let portType = port.portType;

        let typeLeafs = ModelUtils.getTypeLeafs(portType, modelTypes);

        let portTypes = [portType].concat(typeLeafs);

        return _.uniqWith(portTypes, (objValue, othValue) => {
            return (objValue['id'] === othValue['id']);
        });
    }

    static discardOverrides(model: ProductModel, linked: ProductModel[]) {
        let types: Type[] = ModelUtils.collectTypes(linked);
        model.types.forEach(type => ModelUtils.discardTypeOverrides(type, types));
        return model;
    }

    private static discardTypeOverrides(type: Type, types: Type[]) {
        let extended = types.find(t => t.name === type.name);
        if (extended) {
            type.parentType = type.parent = type.constraints = type.preferences = type.sequentialRules = type.requireRules = type.messages = void 0;
            type.annotations = ModelUtils.discardedAnnotations(type.annotations, extended.annotations);
            type.ports = ModelUtils.discardedPorts(type, extended);
            type.attributes = ModelUtils.discardedAttributes(type, extended);
        }
    }

    private static discardedAttributes(type: Type, extended) {
        let attributes: Attribute[] = [];
        type.attributes.forEach(a => {
            let e = extended.attributes.find(attribute => attribute.name === a.name);
            if (e) {
                let annotations = this.discardedAnnotations(a.annotations, e.annotations);

                let annotationsChanged = Object.keys(annotations).length > 0;
                let declarationChanged = a.declaration && a.declaration !== e.declaration;
                let precisionChanged = a.precision !== e.precision;
                if (annotationsChanged || declarationChanged || precisionChanged) {
                    let attribute = new Attribute();
                    attribute.id = attribute.name = a.name;
                    attribute.primitiveType = a.primitiveType;
                    if (precisionChanged) {
                        attribute.precision = a.precision;
                    }
                    if (declarationChanged) {
                        attribute.declaration = a.declaration;
                    }
                    if (annotationsChanged) {
                        attribute.annotations = annotations;
                    }
                    attributes.push(attribute);
                }
            } else {
                attributes.push(a);
            }
        });
        return attributes;
    }

    private static discardedPorts(type: Type, extended) {
        let ports: Port[] = [];
        type.ports.forEach(p => {
            let e = extended.ports.find(port => port.name === p.name);
            if (e) {
                let annotations = this.discardedAnnotations(p.annotations, e.annotations);

                let orderChanged = !_.isEqual(e.order, p.order);
                let cardinalityChanged = e.min !== p.min || e.max !== p.max;
                let annotationsChanged = Object.keys(annotations).length > 0;

                if (annotationsChanged || cardinalityChanged || orderChanged) {
                    let port = new Port(e.id, e.name, e.portType);
                    if (annotationsChanged) {
                        port.annotations = annotations;
                    }
                    if (cardinalityChanged) {
                        port.min = p.min;
                        port.max = p.max;
                    }
                    if (orderChanged) {
                        port.order = p.order;
                    }
                    ports.push(port);
                }
            } else {
                ports.push(p);
            }
        });
        return ports;
    }

    private static discardedAnnotations(orig, ext) {
        let annotations: Dictionary = {};
        Object.keys(orig).forEach(k => {
            if (ext[k] !== orig[k]) {
                annotations[k] = orig[k];
            }
        });
        return annotations;
    }

    static override(productModel: ProductModel, linked: ProductModel[]) {
        let types: Type[] = ModelUtils.collectTypes(linked);
        productModel.types.forEach(type => ModelUtils.overrideType(type, types));
    }

    static overrideType(type: Type, types: Type[]) {
        let src = types.find(t => type.name === t.name);
        if (src) {
            type.overridden = true;
            ModelUtils.overrideAnnotations(type.annotations, src.annotations);
            ModelUtils.overridePorts(type, src);
            ModelUtils.overrideAttributes(type, src);
        }
    }

    private static overrideAnnotations(orig, src) {
        Object.keys(src).forEach(k => {
            if (!orig[k]) {
                orig[k] = src[k];
            }
        });
    }

    private static overridePorts(type: Type, src: Type) {
        src.ports.forEach(s => {
            let port = type.ports.find(p => s.name === p.name);
            if (port) {
                ModelUtils.overrideAnnotations(port.annotations, s.annotations);
                if (!port.order) {
                    port.order = EntityUtil.clone(s.order);
                }
                if (!port.min) {
                    port.min = s.min;
                    port.max = s.max;
                }
                port.type = s.type;
            } else {
                type.ports.push(EntityUtil.clone(s));
            }
        })
    }

    private static overrideAttributes(type: Type, src: Type) {
        src.attributes.forEach(s => {
            let attribute = type.attributes.find(a => s.name === a.name);
            if (attribute) {
                ModelUtils.overrideAnnotations(attribute.annotations, s.annotations);
                if (!attribute.declaration) {
                    attribute.declaration = s.declaration;
                }
                if (!attribute.precision) {
                    attribute.precision = s.precision;
                }
            } else {
                type.attributes.push(EntityUtil.clone(s));
            }
        })
    }

    static collectTypes(models: ProductModel[]) {
        let types: Type[] = [];
        if (models.length > 0) {
            models.forEach(m => types = types.concat(m.types));
        }
        return types;
    }

    static collectModelPorts(modelTypes: Type[]): Map<string, Port> {
        let result: Map<string, Port> = new Map();

        modelTypes.forEach(type => {
            let portsInType = ModelUtils.getTypePorts(type, modelTypes);

            portsInType.forEach(port => {
                let portId = (type.name + '/' + port.name).toLowerCase();

                result.set(portId, port);
            });
        });

        return result;
    }
}