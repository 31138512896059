import * as _ from 'lodash';
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MessageService } from '@veloce/shared';
import { ErrorCodes } from '../service/validation.messages';

@Directive({ selector: '[prevalidate]' })
export class PreValidateDirective {
    @Input() form: FormGroup;
    @Input() messageBucketId: string;
    @Output() onClick = new EventEmitter<any>();

    constructor(private messageService: MessageService) {}

    @HostListener('click')
    handleClick() {
        if (this.form.valid) {
            this.onClick.emit();
        } else {
            const formErrors = [];
            for (const controlName in this.form.controls) {
                const control = this.form.controls[controlName];

                if (control.errors) {
                    this.form.controls[controlName].markAsDirty(); // To highlight validation messages

                    Object.keys(control.errors).some(key => {
                        if (ErrorCodes[key]) {
                            const capitalizedControlName = _.words(_.startCase(controlName))
                                .map(w => _.capitalize(w))
                                .join(' ');

                            const message = control.errors[key].message || capitalizedControlName + ' ' + ErrorCodes[key];

                            formErrors.push(message);
                            return true;
                        }
                    });
                }

                this.messageService.publishMsg(formErrors, this.messageBucketId); //SHOW messages on by one (not all a the same time )
            }
        }
    }
}
