export interface DataSelectorItem {
  id: string;
  label: string;
  isChecked?: boolean;
}

export enum DataSelectorEventType {
  TOGGLE_ITEM = 'TOGGLE_ITEM',
  SORT_ORDER = 'SORT_ORDER'
}

export interface DataSelectorEvent {
  type: DataSelectorEventType;
  payload?: unknown;
}
