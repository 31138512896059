import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {LoginComponent} from "./components/login.component";

const routes: Routes = [
    { path: 'login', component: LoginComponent, data: [{mode: 'login'}] },
    { path: 'signup', component: LoginComponent, data: [{mode: 'signup'}] },
    { path: 'reset-password', component: LoginComponent, data: [{mode: 'reset'}] },
    { path: 'change-password/:key', component: LoginComponent, data: [{mode: 'change'}] },
    { path: 'change-password', pathMatch: 'full', redirectTo: 'reset-password', },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoginRoutingModule { }


// export const routedComponents = [RuntimeComponent];
