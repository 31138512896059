import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';
import { apiBaseUrl } from '@veloce/shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorDialog: ErrorDialogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // tslint:disable-next-line:no-console
        console.error('Error from error interceptor', error);
        if (!request.url.startsWith(apiBaseUrl)) {
          this.errorDialog.showError(error.error?.message || error.message || JSON.stringify(error));
        }
        throw error;
      })
    ) as Observable<HttpEvent<any>>;
  }
}
