import * as fflate from 'fflate';
import { from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from './message.service';
import { XrayService } from './xray.service';
import { HttpRequestOptions } from '../model';
import { extractErrorMessage } from '../utils';

export const apiBaseUrl: string = window['VELO_API'] + '/services';

@Injectable()
export class BaseHttpService {
  private readonly compressionMethod: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    private xray: XrayService
  ) {
    this.compressionMethod = (window['COMPRESSION_METHOD'] || 'gzip').toLowerCase();
  }

  api(requestOptions: HttpRequestOptions): Observable<any> {
    return from(this.adjustRequestBody(requestOptions)).pipe(
      switchMap(() => {
        const method = requestOptions.method || 'get';
        const url = apiBaseUrl + requestOptions.url;
        let headers = requestOptions.headers || this.getHeaders();
        const segment = this.xray.beginSegment(method, url);
        headers = headers.append('X-Amzn-Trace-Id', this.xray.getTraceHeader(segment));

        return this.http
          .request(method, url, {
            withCredentials: true,
            body: requestOptions.body,
            headers,
            params: requestOptions.params,
            responseType: requestOptions.responseType,
            observe: requestOptions.observe || 'body'
          })
          .pipe(
            map(result => {
              this.xray.endSegment(segment);
              return <any>result;
            }),
            catchError((err: HttpErrorResponse) => {
              this.xray.endSegmentWithError(segment, err.status, err);
              if (err.status == 401 || err.status == 403) {
                this.router.navigate(['/login']);

                return throwError(err);
              } else {
                if (requestOptions.skipErrorHandler) {
                  return throwError(() => err);
                }

                if (requestOptions.errorHandler) {
                  return requestOptions.errorHandler.apply(null, [err]);
                } else {
                  this.publishErrorMessage(err);
                  return throwError(err);
                }
              }
            })
          );
      })
    );
  }

  upload(requestOptions: HttpRequestOptions): Observable<any> {
    let headers = this.getHeadersForUpload();
    const method = requestOptions.method || 'post';
    const url = apiBaseUrl + requestOptions.url;
    const segment = this.xray.beginSegment(method, url);
    headers = headers.append('X-Amzn-Trace-Id', this.xray.getTraceHeader(segment));

    return this.http
      .request(method, url, {
        withCredentials: true,
        body: requestOptions.body,
        headers,
        params: requestOptions.params,
        reportProgress: requestOptions.reportProgress,
        responseType: requestOptions.responseType,
        observe: requestOptions.observe || 'body'
      })
      .pipe(
        map(result => {
          this.xray.endSegment(segment);
          return <any>result;
        }),
        catchError(err => {
          this.xray.endSegmentWithError(segment, err.status, err);
          if (err.status == 401 || err.status == 403) {
            this.router.navigate(['/login']);
          } else {
            return throwError(err);
          }
        })
      );
  }

  getHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Veloce-Accept-Encoding': this.compressionMethod
    });
    const token = window['VELO_KEY'];

    if (token) {
      headers = headers.append('Authorization', token);
    }

    return headers;
  }

  getHeadersForUpload(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    const token = window['VELO_KEY'];

    if (token) {
      headers = headers.append('Authorization', token);
    }

    return headers;
  }

  toParams(map: Map<string, string>): HttpParams {
    let params = new HttpParams();

    if (map) {
      map.forEach((value, key) => {
        params = params.set(key, value);
      });
    }

    return params;
  }

  private publishErrorMessage(err: HttpErrorResponse) {
    const errMsg = extractErrorMessage(err);

    this.messageService.publishToast({
      comment: errMsg,
      messageType: 'ERROR',
      keepMessage: true
    });
  }

  private async adjustRequestBody(requestOptions: HttpRequestOptions) {
    if (requestOptions.body) {
      const content = JSON.stringify(requestOptions.body);
      if (this.compressionMethod !== 'none' && content.length > 10000) {
        const getAdjustedHeaders = veloceEncoding => {
          let headers = requestOptions.headers || this.getHeaders();
          headers = headers
            .delete('Content-Type')
            .append('Content-Type', 'text/plain')
            .append('Content-Encoding', veloceEncoding)
            .append('Veloce-Encoding', veloceEncoding);
          return headers;
        };

        const uint8Array = new TextEncoder().encode(content);
        const encoded = fflate.gzipSync(uint8Array);
        requestOptions.body = new Blob([encoded]);
        requestOptions.headers = getAdjustedHeaders('gzip');
      } else {
        requestOptions.body = content;
      }
    }
  }
}
