import {Attribute} from "./attribute.model";
import {DefaultComponent} from "./defaultComponent.model";
import {Type} from "./type.model";
import {Dictionary} from "../../../model/dictionary.model";
import {Annotated} from "./annotated.model";

export class Port implements Annotated {
    public id: string; //frontend
    public name: string; //backend
    public attributes: Attribute[];
    public annotations: Dictionary = {};
    public type: string; //backend
    public portType: Type; //frontend
    public hostType: string; //frontend

    public min: string;
    public max: string;

    public order: string[];

    public defaultComponents: DefaultComponent[];
    domain: string[];

    constructor(id: string, name?: string, portType?: Type) {
        this.id = id;
        this.name = name;
        this.portType = portType;
        this.type = portType.name;
        this.attributes = [];
        this.defaultComponents = [];
    }

    static newInstance(name: string, portType:Type):Port {
        return new Port(name, name, portType);
    }
}

