import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@veloce/components';
import { DemoComponentsModule } from '@veloce/demo-components';
import { GartnerComponentsModule } from '@veloce/gt-components';
import { HRComponentsModule } from '@veloce/hr-components';
import { AddPropertyComponent } from '../../components/dictionary/add-property.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { TreeComponent } from '../../components/tree/tree-component';
import { CarouselSidebarDirective, CarouselSidebarSeat } from '../../directives/carousel-sidebar/carousel-sidebar';
import { GridFilterComponent } from '../../directives/grid-filter/grid-filter.component';
import { GridFiltersDirective } from '../../directives/grid-filter/grid-filters.directive';
import { ListsMoreComponent } from '../../directives/lists.more.directive';
import { ValidationMessagesDirective } from '../../directives/messages/messages.directive';
import { NotificationsDirective } from '../../directives/notifications/notifications.directive';
import { PagedEntityDirective } from '../../directives/pagination/paged-entity.directive';
import { PreValidateDirective } from '../../directives/pre-validate.directive';
import { SidebarTabsDirective } from '../../directives/sidebar-tabs.directive';
import { StickyDirective } from '../../directives/sticky.directive';
import { ColFilterComponent, TableFilterDirective } from '../../directives/table-filter/table-filter.directive';
import { TableSortbableColumnDirective } from '../../directives/table-sortable-column/table-sortable-column.directive';
import { TogglerDirective } from '../../directives/toggler';
import { ValidationDirective } from '../../directives/validation/validation.directive';
import { WindowResizeAwareDirective } from '../../directives/window-resize-aware.directive';
import { CriteriaFilterPipe } from '../../pipes/criteria.filter.pipe';
import { DateFormatPipe } from '../../pipes/date.format.pipe';
import { OrderByPipe } from '../../pipes/order-by.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { SearchFilterPipe } from '../../pipes/search.filter.pipe';
import { ValuesPipe } from '../../pipes/values.pipe';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ListViewHeaderComponent } from './components/list-view-header/list-view-header.component';
import { LoaderButtonComponent } from './components/loader-button/loader-button.component';
import { RootNavigationComponent } from './components/root-navigation.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { GridSortService } from './services/grid-sort.service';
import { VeloceElementsModule } from '@veloce/elements';
import { FilterGroupComponent } from '../../components/filter-group/filter-group.component';
 import { CardMenuComponent } from './components/card-menu/card-menu.component';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), { showWeekNumbers: false, containerClass: 'theme-velo-default' });
}

// imports: imports the module's exports. which are usually
// declarables(components / directives / pipes) and providers.
// in our case the FilterTextModule has a provider.
//
// exports: exports modules AND declarables (components/directives/pipes) that other modules may want to use
// SharedModule does not use CommonModule, but does use FormsModule.
// Even so, we import/export both of these because most other modules will import SharedModule and will need them.
@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    VeloceElementsModule,
    DemoComponentsModule,
    FormsModule,
    GartnerComponentsModule,
    HRComponentsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [
    AddPropertyComponent,
    CarouselSidebarDirective,
    CarouselSidebarSeat,
    ColFilterComponent,
    ConfirmDialogComponent,
    CriteriaFilterPipe,
    DateFormatPipe,
    FilterGroupComponent,
    GridFilterComponent,
    GridFiltersDirective,
    ListsMoreComponent,
    ListViewHeaderComponent,
    LoaderButtonComponent,
    NotificationsDirective,
    OrderByPipe,
    PagedEntityDirective,
    PreValidateDirective,
    RootNavigationComponent,
    SafePipe,
    SearchFilterPipe,
    SidebarTabsDirective,
    SpinnerComponent,
    StickyDirective,
    TableFilterDirective,
    TableSortbableColumnDirective,
    TogglerDirective,
    TreeComponent,
    ValidationDirective,
    ValidationMessagesDirective,
    ValuesPipe,
    WindowResizeAwareDirective,
    CardMenuComponent
  ],
  exports: [
    AddPropertyComponent,
    BsDatepickerModule,
    BsDropdownModule,
    CarouselSidebarDirective,
    CarouselSidebarSeat,
    ColFilterComponent,
    CommonModule,
    ComponentsModule,
    VeloceElementsModule,
    ConfirmDialogComponent,
    CriteriaFilterPipe,
    DateFormatPipe,
    DemoComponentsModule,
    FilterGroupComponent,
    FormsModule,
    GartnerComponentsModule,
    GridFilterComponent,
    GridFiltersDirective,
    HRComponentsModule,
    ListsMoreComponent,
    ListViewHeaderComponent,
    LoaderButtonComponent,
    ModalModule,
    NotificationsDirective,
    OrderByPipe,
    PagedEntityDirective,
    PopoverModule,
    PreValidateDirective,
    ReactiveFormsModule,
    RootNavigationComponent,
    SafePipe,
    SearchFilterPipe,
    SidebarTabsDirective,
    SpinnerComponent,
    StickyDirective,
    TableFilterDirective,
    TableSortbableColumnDirective,
    TogglerDirective,
    TooltipModule,
    TreeComponent,
    ValidationDirective,
    ValidationMessagesDirective,
    ValuesPipe,
    WindowResizeAwareDirective,
    CardMenuComponent
  ],
  providers: [{ provide: BsDatepickerConfig, useFactory: getDatepickerConfig }, GridSortService, CanDeactivateGuard]
})
export class SharedModule {}
