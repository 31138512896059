import {Cookie} from "ng2-cookies";
import {UUID} from "./uuid.service";

export class CookieStore<T> {

    name: string;

    constructor(name: string) {
        this.name = UUID.hex(name);
    }

    get(): T {
        let string = Cookie.get(this.name);
        if (string) {
            return <T>(JSON.parse(string));
        }

        return;
    }

    store(value: any) {
        Cookie.set(this.name, JSON.stringify(value));
    }

    clear() {
        Cookie.delete(this.name);
    }
}