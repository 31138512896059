import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'vl-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnChanges {
  @Input() name?: string;
  @Input() size?: number;
  @Input() isChecked?: boolean;
  @Input() isIndeterminate?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isRadio?: boolean;

  @Output() private checkedChange = new EventEmitter<Event>();

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size && changes.size.currentValue !== changes.size.previousValue) {
      this.el.nativeElement.style.setProperty('--checkbox-size', `${changes.size.currentValue}px`);
    }
  }

  onChange(event: Event): void {
    this.checkedChange.emit(event);
  }
}
