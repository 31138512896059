export enum RowType {
  PRODUCT = 'Product',
  COMMENT = 'Comment',
  SUBTOTAL = 'Subtotal',
  EMPTY = 'Empty'
}

export enum ProductAttribute {
  EXCLUDED = 'EXCLUDED',
  HIDE_PRICE = 'HIDE_PRICE',
  PRINT_LINE = 'PRINT_LINE'
}

export interface BaseRow {
  id?: string;
  isSelected?: boolean;
  rowType: RowType;
}

export interface ProductRow extends BaseRow {
  [key: string]: any;
  attribute?: string;
}

export interface CommentRow extends BaseRow {
  text: string;
}

export type TableRow = ProductRow | CommentRow;

export enum EditorType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TYPEAHEAD = 'typeahead',
  DATEPICKER = 'datepicker',
  DROPDOWN = 'dropdown'
}
