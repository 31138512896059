import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormControlGroup, PartialLineItem, RampData, WidgetAction } from '../../models';

@Component({
  selector: 'vl-ramp-widget',
  styleUrls: ['ramp-widget.component.scss'],
  templateUrl: 'ramp-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RampWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lineItem: PartialLineItem;
  @Input() isEndDateOnly: boolean;

  form: FormControlGroup;

  @Output() private widgetActionSelect = new EventEmitter<WidgetAction>();
  @Output() private rampDiscard = new EventEmitter<void>();
  @Output() private updateRamp = new EventEmitter<RampData>();
  @Output() private endDateChange = new EventEmitter<Date>();
  @Output() private termChange = new EventEmitter<string>();

  private onDestroy = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.buildForm(this.lineItem);

    this.form.endDateControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((endDate: Date) => {
      this.endDateChange.emit(endDate);
    });

    this.form.termControl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe((term: string) => {
      this.termChange.emit(term);
    });
  }

  ngOnChanges({ lineItem }: SimpleChanges): void {
    const { currentValue, firstChange } = lineItem;
    const { startDate, endDate, term, qty: quantity } = currentValue;

    if (firstChange) {
      return;
    }

    this.form.formGroup.patchValue({ startDate, endDate, term, quantity }, { emitEvent: false });
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.widgetActionSelect.emit(action);
  }

  onDiscardRamp(): void {
    this.rampDiscard.emit();
  }

  onCreateRamp(): void {
    const { value } = this.form.formGroup;

    this.updateRamp.emit(value);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  private buildForm({ startDate, endDate, qty, term }: PartialLineItem): FormControlGroup {
    const formGroup = this.fb.group({
      startDate,
      endDate,
      term: [term, Validators.required],
      quantity: [qty, Validators.required]
    });
    const startDateControl = formGroup.get('startDate');
    const endDateControl = formGroup.get('endDate');
    const termControl = formGroup.get('term');
    const quantityControl = formGroup.get('quantity');

    return {
      formGroup,
      startDateControl,
      endDateControl,
      termControl,
      quantityControl
    };
  }
}
