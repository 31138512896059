import { BehaviorSubject, Subject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class MessageService {
  private validationSubject = new Subject<any>();
  private notificationSubject = new Subject<any>();
  private inactiveProductsShownSubject = new BehaviorSubject<boolean>(false);

  validationMessages = this.validationSubject.asObservable();
  notificationMessages = this.notificationSubject.asObservable();
  inactiveProductsShown$ = this.inactiveProductsShownSubject.asObservable();

  constructor(@Inject('GLOBAL_BUCKET_ID') public globalBucketId: string) {}

  publishMsg(message: any, messageBucketId: string) {
    this.publish(message, messageBucketId, true);
  }

  clearMsg(messageBucketId: string) {
    this.publish(null, messageBucketId, true);
  }

  publishToast(message: any, messageBucketId?: string) {
    this.publish(message, messageBucketId || this.globalBucketId, false);
  }

  clearToasts(messageBucketId: string) {
    this.publish(null, messageBucketId, false);
  }

  setInactiveProductsShown(value: boolean) {
    this.inactiveProductsShownSubject.next(value);
  }

  private publish(message: any, messageBucketId: string, validation: boolean) {
    let subject = validation ? this.validationSubject : this.notificationSubject;

    subject.next({ message: message, messageBucketId: messageBucketId });
  }
}
