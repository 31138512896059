export enum DiscountType {
  MARKUP_PERCENT = '+%',
  DISCOUNT_PERCENT = '-%',
  MARKUP_AMOUNT = '+$',
  DISCOUNT_AMOUNT = '-$',
  OVERRIDE_AMOUNT = '(New price)'
}

export interface OutputDiscountData {
  discountType: DiscountType;
  amount: number;
}
