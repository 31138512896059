import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '../../models/widget-item-list.model';

@Component({
  selector: 'vl-widget-item-list',
  styleUrls: ['widget-item-list.component.scss'],
  templateUrl: 'widget-item-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetItemListComponent {
  @Input() itemList: ListItem[];
  @Input() headerItemList?: ListItem[];
  @Input() footerItemList?: ListItem[];
  @Input() selectedItemId?: string;
  @Input() isReadonly: boolean;

  @Output() private readonly handleListItemSelect = new EventEmitter<string>();

  onListItemSelect(id: string): void {
    if (this.isReadonly) {
      return;
    }

    this.handleListItemSelect.emit(id);
  }

  trackById(_: number, { id }: ListItem): string {
    return id;
  }
}
