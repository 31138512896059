import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormControlGroup } from '../../models';

@Component({
  selector: 'vl-price-override-form',
  styleUrls: ['price-override-form.component.scss'],
  templateUrl: 'price-override-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceOverrideFormComponent implements OnInit {
  form: FormControlGroup;

  @Output() private handlePriceOverride = new EventEmitter<string | number>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.buildForm();
  }

  onDiscardPriceOverride(): void {
    this.handlePriceOverride.emit(0);
  }

  onPriceOverride(): void {
    const { value } = this.form.amountControl;

    this.handlePriceOverride.emit(value);
  }

  private buildForm(): FormControlGroup {
    const formGroup = this.fb.group({
      amount: ['', Validators.required]
    });
    const amountControl = formGroup.get('amount');

    return {
      formGroup,
      amountControl
    };
  }

  get isFormInvalid(): boolean {
    return this.form.formGroup.invalid;
  }
}
