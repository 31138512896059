import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize, Filter, Filterable } from '../../../models';

@Component({
  selector: 'vl-default-filter',
  styleUrls: ['default-filter.component.scss'],
  templateUrl: 'default-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultFilterComponent {
  @Input() filterMenuItems: Filterable[] = [];
  @Input() errorFilterMenuItems: Filterable[] = [];
  @Input() searchInputFilter: Filter;
  @Input() defaultFilters: Filter[] = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ButtonSize = ButtonSize;

  @Output() private filterUpdate = new EventEmitter<Filter[]>();

  onFilterUpdate(filters: Filter[]): void {
    this.filterUpdate.emit(filters);
    this.cdr.detectChanges();
  }

  trackByFilterName = (_: number, { filterable }: Filter): string => (filterable ? filterable.name : null);
}
