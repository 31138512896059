import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Traversable } from '../modules/runtime/model/traversable.model';

export class EntityUtil {
    static clone<T>(object: T): T {
        return _.cloneDeep(object);
    }

    static contains<T>(collection: Array<T>, value: T): boolean {
        return _.includes(collection, value);
    }

    static containsString(collection: Array<string> = [], value: string = ''): boolean {
        return _.includes(
            collection.map(k => k.toLowerCase()),
            value.toLowerCase()
        );
    }

    static isEmpty(object: any) {
        if (!EntityUtil.isPresent(object)) {
            return true;
        }

        if (_.isBoolean(object) || _.isNumber(object)) {
            return false;
        }

        if (_.isString(object) && object.length > 0) {
            return false;
        }

        if (Array.isArray(object)) {
            return _.isEmpty(object);
        }

        return true;
    }

    static isPresent(object: any) {
        return object !== undefined && object !== null;
    }

    static isEqual(value: any, other: any): boolean {
        return _.isEqual(value, other);
    }

    static isDate(object: any) {
        return _.isDate(object);
    }

    static findById(id: any, container: any) {
        if (!_.isUndefined(id) && !_.isNull(id) && Array.isArray(container)) {
            return container.find(item => item.id.toLowerCase() === id.toLowerCase());
        }

        return void 0;
    }

    static removeById(id: any, array: any): boolean {
        if (!_.isUndefined(id) && !_.isNull(id) && Array.isArray(array)) {
            let index = _.findIndex(array, item => item.id === id);
            if (index != -1) {
                array.splice(index, 1);
                return true;
            }
        }
        return false;
    }

    static uniqueBy(arr: any[], field: string) {
        return _.uniqWith(arr, (objValue, othValue) => {
            return objValue[field] === othValue[field];
        });
    }

    static findByFieldHierarchically(fieldName: string, fieldValue: string, container: any[], childBagName: string) {
        if (
            this.isBlank(fieldName) ||
            this.isBlank(fieldValue) ||
            this.isBlank(childBagName) ||
            !Array.isArray(container)
        ) {
            return void 0;
        }

        let result = void 0;

        container.some(el => {
            EntityUtil.traverseFn(el, childBagName, item => {
                if (item[fieldName] && item[fieldName].toLowerCase() === fieldValue.toLowerCase()) {
                    result = item;
                    return item;
                }
            });

            return result;
        });

        return result;
    }

    static filterHierarchically<T>(container: T[], childBagName: string, condition: Function): T[] {
        if (this.isBlank(childBagName) || !Array.isArray(container)) {
            return [];
        }

        let result = [];

        container.forEach(el => {
            EntityUtil.traverseFn(el, childBagName, item => {
                let isTruthy = condition.apply(this, [item]);

                if (isTruthy) {
                    result.push(item);
                }
            });
        });

        return result;
    }

    static removeByProperty(container: any, itemToRemove: any, listName: string, uniquePropertyName: string) {
        let index = _.findIndex(
            container[listName],
            listItem => listItem[uniquePropertyName] === itemToRemove[uniquePropertyName]
        );

        if (index != -1) {
            let removedObject = container[listName].splice(index, 1);
            return removedObject[0];
        } else {
            throw Error(`Unable to find ${uniquePropertyName} in container`);
        }
    }

    static traverseTree(node: Traversable<any>, callbackFn: any, bottomUp?: boolean) {
        if (!bottomUp) {
            callbackFn.apply(this, [node]);
        }

        if (node['poisonPill']) {
            delete node['poisonPill'];
            return;
        }

        if (node.children && node.children.length > 0) {
            node.children.forEach(childContainer => {
                EntityUtil.traverseTree(childContainer, callbackFn, bottomUp);
            });
        }

        if (bottomUp) {
            callbackFn.apply(this, [node]);
        }
    }

    static traverseFn<T>(node: T, childBagName: string, callbackFn: Function) {
        let stopHere = callbackFn.apply(this, [node]);

        if (!stopHere && node[childBagName] && node[childBagName].length > 0) {
            node[childBagName].forEach(childLineItem => {
                if (!stopHere) {
                    EntityUtil.traverseFn(childLineItem, childBagName, callbackFn);
                }
            });
        }
    }

    static isBlank(text: string, trim?: boolean): boolean {
        if (_.isUndefined(text) || _.isNull(text)) {
            return true;
        }

        return (trim ? _.trim(text) : text).length < 1;
    }

    static filterByCriteria<T>(criteria: Map<string, string>, unfilteredArray: T[]): T[] {
        return unfilteredArray.filter(item => EntityUtil.criteriaFilter(criteria, item));
    }

    static criteriaFilter(criteria: Map<string, string>, item): boolean {
        if (criteria.size < 1) {
            return true;
        }

        let valuesMatch = Array.from(criteria.keys()).every(filterName => {
            let filterValue = criteria.get(filterName);

            if (EntityUtil.isBlank(filterValue)) {
                return true;
            }

            let itemValue = item[filterName].toLowerCase();

            return itemValue.indexOf(filterValue.toLowerCase()) != -1;
        });

        return valuesMatch;
    }

    static unsubscribeSafe(...subscriptions: Subscription[]) {
        if (subscriptions && Array.isArray(subscriptions)) {
            subscriptions.forEach(subscription => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
    }

    static clearIds<T>(collection: Array<T>) {
        if (collection && Array.isArray(collection)) {
            collection.forEach(el => {
                if (el && el['id']) {
                    el['id'] = void 0;
                }
            });
        }
    }

    static groupByField<V>(array: Array<V>, fieldName: string): Map<string, V[]> {
        let result = new Map<string, V[]>();

        if (!EntityUtil.isEmpty(array) && !EntityUtil.isBlank(fieldName)) {
            result = _.transform(
                array,
                function(result, item: V) {
                    if (!result.has(item[fieldName])) {
                        result.set(item[fieldName], []);
                    }

                    result.get(item[fieldName]).push(item);
                },
                new Map<string, V[]>()
            );
        }

        return result;
    }

    static mapToDictionary<V>(map: Map<string, V>): { [index: string]: V } {
        let result: { [index: string]: V } = {};

        if (EntityUtil.isPresent(map)) {
            map.forEach((value, key) => {
                result[key] = value;
            });
        }

        return result;
    }

    static dictionaryToMap<V>(dictionary: { [index: string]: V }): Map<string, V> {
        let result = new Map<string, V>();

        if (EntityUtil.isPresent(dictionary)) {
            Object.keys(dictionary).forEach(key => {
                result.set(key, dictionary[key]);
            });
        }

        return result;
    }
}
