import { Directive, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';

@Directive({ selector: '[vlSticky]' })
export class StickyDirective implements OnInit, OnDestroy {
  @Input() container: Element;

  @Input() set offset(value: any) {
    this._offset = value;
  }
  get offset(): any {
    return this._offset || this.defaultOffset;
  }

  @HostBinding('style.position') position: string;
  @HostBinding('style.top') top: any;
  @HostBinding('style.width') width: any;

  @Output() scrollChanges = new EventEmitter<any>();

  private _offset: any;
  private readonly defaultOffset = 32;
  private sensor: ResizeSensor;

  constructor(private stickyElementRef: ElementRef) {}

  ngOnInit(): void {
    this.container.addEventListener('scroll', this.onScrollOrResize.bind(this));
    this.sensor = new ResizeSensor(this.container, () => {
      this.onScrollOrResize();
    });
  }

  onScrollOrResize(): void {
    this.updatePosition();

    this.scrollChanges.emit({});
  }

  ngOnDestroy(): void {
    this.sensor.detach();
  }

  private updatePosition(): void {
    const parentElement = this.stickyElementRef.nativeElement.parentElement;

    if (!parentElement) {
      return;
    }

    const top = parentElement.getBoundingClientRect().top;
    const width = parentElement.getBoundingClientRect().width;

    this.position = 'static';
    this.top = 'unset';
    this.width = 'unset';

    if (top <= this.offset) {
      this.position = 'fixed';
      this.top = `${this.offset}px`;
      this.width = `${width}px`;
    }
  }
}
