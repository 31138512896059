import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { Injectable } from "@angular/core";

export interface UnsaveAware {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<UnsaveAware> {
  canDeactivate(component: UnsaveAware): Observable<boolean> | boolean {
    return component.canDeactivate();
  }
}
