import { Directive, ElementRef, Host, HostBinding, HostListener, Input, OnInit, Self } from '@angular/core';

@Directive({ selector: '[windowResizeAware]', exportAs: 'resizeAware' })
export class WindowResizeAwareDirective implements OnInit {
    private _windowResizeAware: any;

    appOffset: number = 0;

    @HostBinding('style.min-height')
    @HostBinding('style.height')
    public height: string;

    @Input()
    set windowResizeAware(value: number) {
        this._windowResizeAware = value;
    }

    @Input() heightBased: boolean = false;

    constructor(@Self() @Host() private targetElement: ElementRef) {}

    @HostListener('window:scroll')
    @HostListener('window:resize')
    onResize() {
        this.resize();
    }

    ngOnInit(): void {
        let el = document.getElementsByClassName('velo-app');
        if (el && el[0]) {
            this.appOffset = el[0].getBoundingClientRect().top;
        }

        setTimeout(() => {
          this.resize();
        }, 0);
    }

    private resize() {
        let offset = this.getOffset();
        let height = window.innerHeight - this._windowResizeAware - offset;

        this.height = height + 'px';
    }

    private getOffset() {
        if (this.heightBased) {
            return this.targetElement.nativeElement.getBoundingClientRect().top;
        } else {
            return this.appOffset > 0 ? this.appOffset - 10 : this.appOffset;
        }
    }
}
