import { Component, OnInit } from '@angular/core';
import { ToastPosition } from '@veloce/elements';
import { ErrorDialogService } from './error-dialog.service';
import { MessageService } from '@veloce/shared';

@Component({
  selector: 'vl-error-dialog',
  templateUrl: 'error-dialog.component.html',
  styleUrls: ['error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {
  position = ToastPosition.bottomLeft;
  timeout = 5000;
  readonly messageBucketId = 'global-error';

  constructor(
    private errorDialog: ErrorDialogService,
    private messageService: MessageService,
    private toastService: MessageService
  ) {}

  ngOnInit(): void {
    this.errorDialog.errorMethodCalled$.subscribe(this.showError.bind(this));
  }

  private showError(errorMessage: string): void {
    this.toastService.publishToast({ comment: errorMessage, messageType: 'ERROR' }, this.messageBucketId);
  }
}
