export interface OptionItem {
  id: string;
  name: string;
  price: number;
  isSelected: boolean;
  isDisabled?: boolean;
}

export enum InputType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox'
}
