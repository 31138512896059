import { Injectable } from '@angular/core';
import { UserProfileRepository } from '../modules/login/user.profile.repository';
import { Observable } from 'rxjs';

@Injectable()
export class UserProfileService {
  get loggedInUser(): string {
    return this._loggedInUser;
  }

  set loggedInUser(loggedInUser: string) {
    this._loggedInUser = loggedInUser;
  }

  private _loggedInUser: string = null;

  constructor(private userProfileRepo: UserProfileRepository) {}

  getUser(): Observable<any> {
    return this.userProfileRepo.getUser();
  }
}
