import {Pipe, PipeTransform} from "@angular/core";
import * as _ from "lodash";


@Pipe({
    name: 'orderBy',
    pure: true
})
export class OrderByPipe implements PipeTransform {
    transform(iterable: any, iteratee: string, order: string): any {
        return _.orderBy(iterable, [it => {
            let el = it[iteratee];

            return _.isString(el) && el.toLowerCase() || el;
        }], [order]);
    }
}