import { KeyValue } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { TypeaheadItem } from '@veloce/components';

export enum MassEditControlType {
  TEXT = 'text',
  SELECT = 'select',
  TYPEAHEAD = 'typeahead',
  CHECKBOX = 'checkbox',
  DATE = 'date'
}

export interface MassEditControl {
  name: string;
  label: string;
  className?: string;
  controlType?: MassEditControlType;
  size?: string;
  onValueChange?: (form: FormGroup, newValue: any) => void;
  lov?: (data: KeyValue<string, string>) => string[];
  formControl?: FormControl;
  typeAheadItemGetter?: (value: string) => Promise<TypeaheadItem[]>;
}
