import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vd-admin-list',
  styleUrls: ['admin-list.component.scss'],
  templateUrl: 'admin-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminListComponent {
  @Input() isLoading = false;
  @Input() list: string[];
  @Input() itemNamePlural: string = 'items';

  @Output() private configure = new EventEmitter<number>();
  @Output() private delete = new EventEmitter<number>();
  @Output() private selected = new EventEmitter<number>();

  onConfigure(e: MouseEvent, itemIndex: number): void {
    e.stopPropagation();
    this.configure.emit(itemIndex);
  }

  onDelete(e: MouseEvent, itemIndex: number): void {
    e.stopPropagation();
    this.delete.emit(itemIndex);
  }

  onSelect(itemIndex: number): void {
    this.selected.emit(itemIndex);
  }

  trackByValue = (_: number, value: string): string => value;
}
