import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../../service/user.profile.service';
import { AuthService } from '../auth.service';

@Component({
    templateUrl: 'login-social.component.html',
    providers: [AuthService]
})
export class LoginComponent implements OnInit, OnDestroy {
    readonly messageBucketId = 'login';

    private authSub: Subscription;
    loginForm: FormGroup;
    signupForm: FormGroup;
    resetForm: FormGroup;
    changePasswordForm: FormGroup;
    innerHeight: any;
    mode: string;
    signUpMsg: string = null;
    resetMsg: string = null;
    changePasswordMsg: string = null;
    confirmKey: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userProfileService: UserProfileService,
        private formBuilder: FormBuilder
    ) {
        this.innerHeight = window.innerHeight;
    }

    ngOnInit(): void {
        this.mode = this.route.snapshot.data[0]['mode'];

        if (this.mode === 'change') {
            this.confirmKey = this.route.snapshot.params['key'];
        }

        if (this.mode === 'login') {
            this.logout();
        }

        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, LoginComponent.validEmail]],
            password: ['', [Validators.required]]
        });

        this.signupForm = this.formBuilder.group({
            fullName: ['', [Validators.required]],
            username: ['', [Validators.required, LoginComponent.validEmail]],
            password: ['', [Validators.required]]
        });

        this.resetForm = this.formBuilder.group({
            username: ['', [Validators.required, LoginComponent.validEmail]]
        });

        this.changePasswordForm = this.formBuilder.group({
            password: ['', [Validators.required]]
        });

        this.signUpMsg = null;
    }

    login(formValue: any) {
        this.authSub = this.authService
            .login(formValue.username, formValue.password, this.messageBucketId)
            .pipe(
                mergeMap(loginResult => this.route.queryParams),
                map(qp => qp['redirectTo'])
            )
            .subscribe(redirectTo => {
                if (this.authService.isLoggedIn) {
                    let url = redirectTo ? [redirectTo] : ['/models'];

                    this.router.navigate(url);
                }
            });
    }

    signup(formValue: any) {
        this.authSub = this.authService
            .signup(formValue.fullName, formValue.username, formValue.password, this.messageBucketId)
            .subscribe(message => {
                this.signUpMsg = message;
            });
    }

    reset(formValue: any) {
        this.authSub = this.authService.reset(formValue.username, this.messageBucketId).subscribe(message => {
            this.resetMsg = message;
        });
    }

    changePassword(formValue: any) {
        this.authSub = this.authService
            .changePassword(formValue.password, this.confirmKey, this.messageBucketId)
            .subscribe(
                message => {
                    // console.log('success');
                    this.changePasswordMsg = message;
                },
                error => {
                    console.log('error');
                }
            );
    }

    switchMode(mode: string) {
        this.mode = mode;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerHeight = event.target.innerHeight;
    }

    logout() {
        this.authService.logout();
    }

    ngOnDestroy() {
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
    }

    static validEmail(control: FormControl) {
        let regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (control.value != '' && !regexp.test(control.value)) {
            return { username: { message: ' invalid format' } };
        }

        return null;
    }
}
