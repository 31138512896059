import {Directive, Input, OnInit} from "@angular/core";
import {UUID} from "../service/uuid.service";
import {EntityUtil} from "../service/entity.util";

@Directive({selector: 'toggler', exportAs: 'toggler'})
export class TogglerDirective implements OnInit {

    id: string;

    private _state: Map<string, any> = new Map();

    private _multiselect: boolean;

    get state(): Map<string, any> {
        return this._state;
    }

    set state(value: Map<string, any>) {
        this._state = value;
    }

    @Input()
    set container(value: any) {
        EntityUtil.dictionaryToMap(EntityUtil.clone(value)).forEach((value, key) => {
            this._state.set(key, value);
        });
    }

    @Input()
    set multiselect(value: boolean) {
        this._multiselect = value;
    }

    constructor() {
        this.id = UUID.UUID();
    }

    ngOnInit(): void {
    }

    toggle(id: string, value: any = true, $event?: Event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        if (this.isOn(id)) {
            this._state.delete(id);
        } else {
            if (!this._multiselect) {
                this._state.clear();
            }

            this._state.set(id, value);
        }
    }

    isOn(id: string): boolean {
        return this._state.has(id);
    }

    update(id: string, value: any = true) {
        this._state.set(id, value);
    }

    getOn(id?: string) {
        if (this._state.size < 1) { return }

        if (!EntityUtil.isEmpty(id)) {
            return this._state.get(id);
        } else {
            const [key, value] = this._state.entries().next().value;

            return value;
        }
    }

    getState(): Map<string, any> {
        return this._state;
    }

    getStateObject(): {[ index: string ]: any} {
        return EntityUtil.mapToDictionary(this.state);
    }

}
