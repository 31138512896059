import { Directive, Input } from '@angular/core';
import { BasicPagedEntity } from '../../model/pagination/basic-paged-entity.model';
import { PAGE_SIZE } from '../../service/page.constants';

export interface PagedEntityConfig<T> {
    items: T[],
    page?: number,
    pageSize?: number,
}

@Directive({selector: '[pagedEntity]', exportAs: 'pagedEntity'})
export class PagedEntityDirective<T> {
    state: BasicPagedEntity<T>;

    @Input()
    set pagedEntity(config: PagedEntityConfig<T>) {
        this.state = new BasicPagedEntity(config.items, config.page || 0, config.pageSize || PAGE_SIZE);
    }

    trackByFn(_: number, { id }: any): string {
        return id;
    }
}
