export interface VeloceListProduct extends CatalogItem {
  productGroupId: string;
  productLineId: string;
  quantityUnitOfMeasure: string;
  className: string;
  typeName: string;
  modelId: string;
  modelName: string;
  displayUrl: string;
  bundleModel: boolean;
  configurable: boolean;
  properties: { [index: string]: string };

  price: number;
  isInstalled: boolean;
  quantity: number;
}

interface CatalogItem extends NamedEntity {
  description: string;
  startDate: string;
  endDate: string;
}

interface NamedEntity {
  id: string;
  name: string;
}

export interface VeloceListAddedItem {
  item: VeloceListProduct;
  quantity: number;
}

export enum VeloceListView {
  CARDS = 'Cards',
  LIST = 'List'
}
