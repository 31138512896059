import { LineItemDTO } from '../modules/catalog/model/line-item.dto.model';
import { RuntimeContext } from './runtime.context';

export enum ConfigurationContextMode {
  QUOTE,
  ACCOUNT,
  ORDER,
  TEST,
  REMOTE
}

export class ConfigurationContext {
  mode: ConfigurationContextMode;
  headerId: string;
  priceListId: string;
  uiDefinitionId: string;
  properties: RuntimeContext['properties'] = {};

  restoreState: boolean = false;
  initialState: LineItemDTO[] = [];
  currentState: LineItemDTO[] = [];
  renewItems: LineItemDTO[] = [];
  deletedItems: LineItemDTO[] = [];
  modelId?: string;
  debugModelId?: string;
}
