export interface PartialChargeItem {
  id: string;
  listPrice: number;
  listUnitPrice: number;
  netPrice: number;
  netUnitPrice: number;
  sellingTerm: number;
}

export enum ChargeItemId {
  LIST_PRICE = 'LIST_PRICE',
  NET_PRICE = 'NET_PRICE',
  RECURRING_PRICE = 'RECURRING_PRICE',
  NET_RECURRING_PRICE = 'NET_RECURRING_PRICE',
  UNIT_PRICE = 'UNIT_PRICE',
  NET_UNIT_PRICE = 'NET_UNIT_PRICE'
}
