import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

export interface InlineEditorOptions {
  id: string;
  name: string;
  value: string;
  controlType?: string;
  selectValues?: string[];
  noAutofocus?: boolean;
  validators?: ValidatorFn[];
}

@Component({
  selector: 'vl-inline-editor',
  templateUrl: './vl-inline-editor.component.html',
  styleUrls: ['./vl-inline-editor.component.scss']
})
export class InlineEditorComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Output() handleSubmit = new EventEmitter();
  @Output() handleCancel = new EventEmitter();

  @Input() set options(options: InlineEditorOptions) {
    this.name = options.name;
    this.value = options.value;
    this.controlType = options.controlType || 'text';
    this.selectValues = options.selectValues;
    this.validators = options.validators || [Validators.required];
    this.noAutofocus = options.noAutofocus;

    this.initForm();
  }

  @Input() set loadingStatus(status: boolean) {
    this.isLoading = status;
  }

  name: string;
  controlType: string;
  value: string;
  selectValues: string[];
  noAutofocus: boolean;
  validators: ValidatorFn[];
  form: FormGroup;
  isLoading: boolean;

  @ViewChild('elementRef') private elementRef: ElementRef;
  @ViewChild('inputElement') private inputElement: ElementRef;

  private documentClickBind: EventListener = this.onDocumentClick.bind(this);

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      value: ''
    });
  }

  ngOnInit(): void {
    window.document.addEventListener('click', this.documentClickBind);
  }

  ngAfterViewChecked(): void {
    if (this.controlType === 'text' && !this.noAutofocus && this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    window.document.removeEventListener('click', this.documentClickBind);
  }

  @HostListener('document:keydown.escape') handleEscape(): void {
    this.clear();
  }

  @HostListener('document:keydown.enter', ['$event']) handleEnter(event: KeyboardEvent): void {
    this.submit(event);
  }

  @Output() submit(event: UIEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.form.valid) {
      return;
    }

    if (this.form.controls.value.value === this.value) {
      this.clear();

      return;
    }

    this.isLoading = true;
    this.handleSubmit.emit({
      name: this.name,
      value: this.form.controls.value.value
    });
  }

  handlePickValue(event: UIEvent, value: string): void {
    this.form.controls.value.patchValue(value);
    this.submit(event);
  }

  trackBystringValue(_: number, value: string): string {
    return value;
  }

  private initForm(): void {
    const valueControl = this.form.controls.value;

    valueControl.setValue(this.value);
    valueControl.setValidators(this.validators);
  }

  private clear(): void {
    window.document.removeEventListener('click', this.documentClickBind);
    this.handleCancel.emit();
  }

  private onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target;

    if (!targetElement) {
      return;
    }

    if (
      event.button !== 2 &&
      !this.elementRef.nativeElement.contains(targetElement) &&
      targetElement !== this.elementRef.nativeElement
    ) {
      this.clear();
    }
  }
}
