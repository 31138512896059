import {Attribute} from "./attribute.model";
import {Type} from "./type.model";
import {UIDefinition} from "../../runtime/model/uiDefinition.model";

export class ProductModel {
    id: string;
    name: string;
    externals: Attribute[] = [];
    constants: Attribute[] = [];
    types?: Type[];
    uiDefinitions?: UIDefinition[] = [];
    uiDefinitionsSource?: string;
    version: string;
    comment: string;
}

export class ProductModelVersion {
    date: Date;
    version: string;
    comment: string;
}

export class ProductModelsContainer {
    main: ProductModel;
    linked: ProductModel[];

    public getAllTypes(): Type[] {
        let types: Type[] = [].concat(this.main.types);
        if (this.linked.length > 0) {
            this.linked.forEach(m => types = types.concat(m.types));
        }
        return types;
    }

    public getUniqueTypes(): Type[] {
        let types: Type[] = [];
        types = types.concat(this.main.types);
        if (this.linked.length > 0) {
            this.linked.forEach(m => types = types.concat(
                m.types.filter(t => types.findIndex(type => type.name === t.name) === -1))
            );
        }
        return types;
    }
}