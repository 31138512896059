import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChildren,
  Directive,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import { InlineEditorComponent, InlineEditorOptions } from './vl-inline-editor.component';

@Directive({
  selector: '[vlInlineEditable]',
  exportAs: 'inlineEditorApi'
})
export class InlineEditableDirective implements OnDestroy {
  @ContentChildren('editable', { read: ViewContainerRef }) entries: QueryList<ViewContainerRef>;

  @Output() handleFormSubmit = new EventEmitter<unknown>();

  componentRef: ComponentRef<InlineEditorComponent>;

  private readonly unsubscribe = new Subject<void>();

  constructor(private resolver: ComponentFactoryResolver, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.unsubscribe.next();

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  init(event: MouseEvent, options: InlineEditorOptions): void {
    event.stopPropagation();

    const viewContainerRef = this.entries.find(entry => {
      const { nativeElement } = entry.element;
      const id = nativeElement.getAttribute('id');
      return options.id === id;
    });

    this.createEditorComponent(viewContainerRef, options);
  }

  createEditorComponent(viewContainerRef: ViewContainerRef, options: InlineEditorOptions): void {
    if (!viewContainerRef || !viewContainerRef.element || !viewContainerRef.element.nativeElement) {
      return;
    }

    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }

    const factory = this.resolver.resolveComponentFactory(InlineEditorComponent);
    this.componentRef = viewContainerRef.createComponent<InlineEditorComponent>(factory, 0);

    this.componentRef.instance.options = options;
    this.componentRef.instance.handleSubmit.pipe(takeUntil(this.unsubscribe)).subscribe(next => {
      const value = {
        ...next,
        id: options.id
      };

      this.handleFormSubmit.emit(value);
      this.componentRef.destroy();
    });

    this.componentRef.instance.handleCancel.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.componentRef.destroy();
    });

    this.changeDetectorRef.detectChanges();
  }
}
