export class BasicPagedEntity<T> {
    private readonly _items: T[];
    page: number = 0;
    pageSize: number;

    constructor(items: T[] = [], page: number, pageSize: number) {
        this._items = items;
        this.page = page;
        this.pageSize = pageSize;
    }

    hasNext(): boolean {
        if (this._items && this._items.length > 0) {
            let currentPage = this.page + 1;
            return currentPage < this.getTotalPages();
        }

        return;
    }

    hasPrev(): boolean {
        return this.page > 0;
    }

    getTotalPages() {
        let pages = Math.floor(this.getTotal()/this.pageSize);

        if (this.getTotal()%this.pageSize > 0) {
            pages++;
        }

        return pages;
    }

    get items(): T[] {
        return this._items.slice(this.getStart(), this.getEnd());
    }

    get allItems(): T[] {
        return this._items;
    }

    getStart() {
        return this.page > 0 ? this.page * this.pageSize : 0;
    }

    getEnd() {
        let end = this.getStart() + this.pageSize;

        let total = this.getTotal();
        if (end > total) {
            end = total;
        }

        return end;
    }

    getTotal() {
        return this._items.length;
    }

    nextPage() {
        this.page++;
    }

    prevPage() {
        this.page--;
    }
}
