import { BaseHttpService, HttpRequestConfigurableOptions } from '@veloce/shared';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

export interface SettingsItem {
  id: string;
  key: string;
  value: string | boolean;
  [key: string]: any;
}

interface SettingsRowData {
  id: string;
  key: string;
  value: string;
}

export class SettingsModel {
  constructor(private baseHttpService: BaseHttpService, private key: string) {}

  fetchSettings(options?: HttpRequestConfigurableOptions): Observable<SettingsRowData | null> {
    return this.baseHttpService
      .api({
        url: `/configuration-settings/byKey/${this.key}`,
        method: 'get',
        ...options,
      })
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 404) {
            return of(null);
          }

          throw err;
        }),
        map(setting => {
          if (!setting) {
            return { key: this.key };
          }
          return setting;
        }),
      );
  }

  saveSettings(body: SettingsItem): Observable<void> {
    return this.baseHttpService.api({
      url: `/configuration-settings/${body.id}`,
      method: 'put',
      body
    });
  }
}
