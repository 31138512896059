import * as JSZip from 'jszip';
import { Injectable } from '@angular/core';
import { fileName, Section } from '../modules/runtime/mockups/model/section.model';
import { UIDefinition } from '../modules/runtime/model/uiDefinition.model';

@Injectable()
export class DefinitionImportService {
  constructor() {}

  getDefinitionFromArchive(archive: File): Promise<UIDefinition> {
    const definitionPromise = new Promise<UIDefinition>(resolve => {
      const zip = new JSZip();
      let zipContentMap = {};
      let definitionFromZip;
      zip.loadAsync(archive).then(data => {
        const filesLength = Object.keys(data.files).length;
        let counter = 0;
        zip.forEach((_, zipEntry) => {
          zipEntry.async('text').then(content => {
            if (content && zipEntry.name.indexOf('json') < 0) {
              zipContentMap[zipEntry.name] = btoa(content);
            }

            if (zipEntry.name.indexOf(fileName.properties) > -1) {
              zipContentMap[zipEntry.name] = content;
            }

            if (zipEntry.name.indexOf(fileName.metadata) > -1) {
              definitionFromZip = JSON.parse(content);
            }
            counter++;

            if (counter === filesLength) {
              resolve(this.restoreDefinition(definitionFromZip, zipContentMap));
            }
          });
        });
      });
    });
    return definitionPromise;
  }

  restoreDefinition([definition]: UIDefinition[], contentMap: any): UIDefinition {
    definition.sections = definition.sections.map(section => {
      const sectionObj = new Section('');
      const tempSection = Object.assign(sectionObj, section);
      tempSection.template = contentMap[section.templateUrl] || '';
      tempSection.styles = contentMap[section.stylesUrl] || '';
      tempSection.script = contentMap[section.scriptUrl] || '';
      tempSection.properties = section.properties || JSON.parse(contentMap[section.propertiesUrl] || '{}');

      return tempSection;
    });

    return definition;
  }
}
