import { Component, Input } from '@angular/core';

@Component({
    templateUrl: 'spinner.component.html',
    selector: 'spinner'
})
export class SpinnerComponent {
    get loading(): boolean {
        return this._loading;
    }

    @Input()
    set loading(value: boolean) {
        this._loading = value;
    }

    private _loading: boolean;
}
