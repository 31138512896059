import { DragulaModule } from 'ng2-dragula';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@veloce/components';
import { DataSelectorComponent, MassEditComponent, SidebarComponent, TableComponent } from './components';

@NgModule({
  declarations: [DataSelectorComponent, TableComponent, MassEditComponent, SidebarComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ComponentsModule, DragulaModule.forRoot()],
  exports: [DataSelectorComponent, TableComponent, MassEditComponent, SidebarComponent]
})
export class HRComponentsModule {}
