import {
    AfterContentInit,
    ChangeDetectorRef,
    ContentChild,
    ContentChildren, Directive, ElementRef, forwardRef, HostBinding, Input, OnInit,
    QueryList, Renderer2,
} from "@angular/core";

@Directive({
    selector: '[carousel-sidebar]',
    exportAs: 'carousel'
})
export class CarouselSidebarDirective implements AfterContentInit {

    @ContentChildren(forwardRef(() => CarouselSidebarSeat))
    seats: QueryList<CarouselSidebarSeat>;

    @ContentChild('carouselInner')
    carouselInner: ElementRef;

    seatsArray: any[];

    constructor(
        private changeDetector: ChangeDetectorRef,
        private renderer: Renderer2
    ) { }

    ngAfterContentInit(): void {
        this.seats.forEach((s, i) => {
            s.order = i;
        });

        this.seatsArray = [].concat(this.seats.toArray());

        this.changeDetector.detectChanges();
    }

    next() {
        if (!this.seats || this.seats.length < 1) { return; }

        this.removeClass('seats-reverse');

        this.seatsArray.push(this.seatsArray.shift());

        this.seatsArray.forEach((seat, i) => {
            seat.order = i;
        });

        this.animate();
    }


    prev() {
        if (!this.seats || this.seats.length < 1) { return; }

        this.addClass('seats-reverse');

        const active = this.seatsArray.pop();

        this.seatsArray.unshift(active);

        this.seatsArray.forEach((seat, i) => {
            seat.order = i;
        });

        this.animate();
    }

    private animate() {
        this.removeClass('seats-set');
        window.setTimeout(() => {
            this.addClass('seats-set');
            this.changeDetector.markForCheck();
        }, 50);

        this.changeDetector.detectChanges();
    }

    private removeClass(className: string) {
        this.renderer.removeClass(this.carouselInner.nativeElement, className);
    }

    private addClass(className: string) {
        this.renderer.addClass(this.carouselInner.nativeElement, className);
    }
}

@Directive({
    selector: '[carousel-seat]',
    exportAs: 'seat'
})
export class CarouselSidebarSeat implements OnInit {

    @Input()
    label: string;

    @HostBinding('style.order')
    order: number = 2;

    ngOnInit(): void {
    }
}