import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'vl-loader-button',
    templateUrl: 'loader-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderButtonComponent {
    @Input() public isLoading: boolean;
}
