import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SidebarButton } from '../../models/sidebar.model';

@Component({
  selector: 'hr-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  @Input() selectedItem: SidebarButton;

  SidebarButton = SidebarButton;

  @Output() private sidebarSelectedChanged = new EventEmitter<SidebarButton>();

  buttonClickHandler(selected: SidebarButton): void {
    this.sidebarSelectedChanged.emit(selected);
  }
}
