export class PagedEntity<T> {
  page = 0;
  pageSize: number;
  private readonly data: T[];

  constructor(data: T[] = [], page: number, pageSize: number) {
    this.data = data;
    this.page = page;
    this.pageSize = pageSize;
  }

  get items(): T[] {
    return this.data.slice(this.getStart(), this.getEnd());
  }

  get allItems(): T[] {
    return this.data;
  }

  hasNext(): boolean {
    if (this.data && this.data.length > 0) {
      const currentPage = this.page + 1;
      return currentPage < this.getTotalPages();
    }

    return;
  }

  hasPrev(): boolean {
    return this.page > 0;
  }

  getTotalPages(): number {
    let pages = Math.floor(this.getTotal() / this.pageSize);

    if (this.getTotal() % this.pageSize > 0) {
      pages++;
    }

    return pages;
  }

  getStart(): number {
    return this.page > 0 ? this.page * this.pageSize : 0;
  }

  getEnd(): number {
    let end = this.getStart() + this.pageSize;

    const total = this.getTotal();
    if (end > total) {
      end = total;
    }

    return end;
  }

  getTotal(): number {
    return this.data.length;
  }

  getNext(): number {
    return this.page + 1;
  }

  getPrev(): number {
    return this.page - 1;
  }

  resetPage(): void {
    this.page = 0;
  }

  nextPage(): void {
    this.page++;
  }

  prevPage(): void {
    this.page--;
  }
}
