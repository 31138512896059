import {Operator} from "../../../model/criteria/operator.model";

export class SearchableField {
    name: string;
    displayValue: string;
    type: string;
    valueOptions?: string[] | { [key: string]: string };

    constructor(name: string, type: string, displayValue: string = '', valueOptions?: string[] | { [key: string]: string }) {
        this.name = name;
        this.displayValue = displayValue || name;
        this.type = type;
        this.valueOptions = valueOptions;
    }
}

export const OperatorsByType = {
    TEXT: [
        Operator.LIKE,
        Operator.EQ,
        Operator.NE,
    ],

    NUMBER: [
        Operator.EQ,
        Operator.NE,
        Operator.GT,
        Operator.LT,
    ],

    ENUM: [
        Operator.EQ,
        Operator.NE,
    ],

    LIST: [
        Operator.EQ,
    ]
};
