import {Component, Input, OnDestroy, OnInit,} from "@angular/core";
import {MessageService} from '@veloce/shared';
import {Subscription} from "rxjs";


@Component({
    selector: 'validation-messages',
    template: `<div [hidden]="!messages" class="general-validation-message" [ngClass]="validationClass ? validationClass : ''">
                   <ul class="message-text">
                       <li *ngFor="let message of messages">
                            {{message}}
                        </li>
                    </ul>
               </div>`,
})
export class ValidationMessagesDirective implements OnInit, OnDestroy {

    public messages: any;
    private _validationClass: string;
    private messageSubscription: Subscription;

    @Input() bucket: string;

    @Input()
    set validationClass(validationClass: string) {
        if (validationClass) {
            this._validationClass = validationClass;
        }
    }

   constructor(
       private messageService: MessageService
   ) {}

    get validationClass() {
        return this._validationClass;
    }

    ngOnInit(): void {
        this.messageSubscription = this.messageService.validationMessages.subscribe((msg) => {
            if (this.bucket && this.bucket === msg.messageBucketId) {
                this.messages = msg.message ? [].concat(msg.message) : null;
            }
        });
    }

    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
    }
}


