import {UUID} from "../../../../service/uuid.service";

export class UITab {
    id: string;
    name: string;
    order: number;
    isDefault: boolean;

    constructor(name: string) {
        this.id = UUID.UUID();
        this.name = name;
        this.order = 0;
    }
}