import { Injectable } from '@angular/core';
import { take, map } from 'rxjs/operators';
import { SettingsModel } from '../modules/settings/components/settings-model';
import { BaseHttpService } from '@veloce/shared';
import { EventEmitter } from 'events';

export enum Feature {
  productsModel = 'Products Model',
  priceLists = 'Price Lists',
  catalogs = 'Catalogs',
  offerings = 'Offerings',
  templates = 'Templates',
  dynamicPages = 'Dynamic Pages',
  approvalTypes = 'Approval Types',
  accounts = 'Accounts',
  switchToAgentView = 'Switch To Agent View',
  switchToAdminView = 'Switch To Admin View',
  settings = 'Settings'
}

export type FeatureList = {
  [key in Feature]?: boolean;
};

export const DefaultFeatures = [
  Feature.productsModel,
  Feature.priceLists,
  Feature.settings,
  Feature.switchToAdminView,
  Feature.accounts
];

export const navigationKey = 'navigation';

@Injectable()
export class FeaturesService {
  features: FeatureList;
  initComplete = new EventEmitter();

  readonly settingsModel = new SettingsModel(this.baseHttpService, navigationKey);

  constructor(private baseHttpService: BaseHttpService) {}

  init(): void {
    this.settingsModel
      .fetchSettings({ skipErrorHandler: true })
      .pipe(
        take(1),
        map(({ value }) => {
          if (value) {
            return JSON.parse(value);
          } else {
            return Object.values(Feature).reduce(
              (trunc, item) => ({
                ...trunc,
                [item]: true
              }),
              {}
            );
          }
        })
      )
      .subscribe(data => {
        this.features = {
          ...data,
          ...DefaultFeatures.reduce((acc, item) => ({ ...acc, [item]: true }), {})
        };
        this.initComplete.emit('initComplete');
      });
  }

  update(features: FeatureList): void {
    this.features = features;
    this.initComplete.emit('initComplete');
  }
}
