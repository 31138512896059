import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BadgeColor } from '../../models/badge-color.model';
import {
  ActionCode,
  ApprovalItem,
  ChargeGroupItem,
  ChargeItem,
  ChargeMethod,
  LineItem
} from '../../models/line-item.model';
import { PageChangedEvent } from '../../models/pagination.model';
import { PriceAdjustmentPipe } from '../../pipes/price-adjustment.pipe';
import { PagedEntity } from '../../utils/paged-entity';
import { ShoppingCartUtils } from '../../utils/shopping-cart.utils';

interface ProductTableRowItem {
  id: string;
  name: string;
  actionCode?: string;
  quantity?: number;
  price?: number;
  priceChange?: string;
  subtotal?: number;
  approval?: ApprovalItem[];
  discount?: string;
  repeat?: string;
}

interface ProductTableRowData {
  id: string;
  depth?: number;
  expandable?: boolean;
  expanded?: boolean;
  hidden?: boolean;
  selectable?: boolean;
  value: ProductTableRowItem;
  originalValue: LineItem | ChargeItem | ChargeGroupItem;
}

@Component({
  selector: 'vl-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductTableComponent implements OnInit, OnChanges {
  @Input() rootLineItem: LineItem;
  @Input() approvalItems: ApprovalItem[];
  @Input() isFlatStructure = false;
  @Input() pageSize = 2;
  @Input() activeItemId: string;
  @Output() selectedChargeGroupItem = new EventEmitter<unknown>();
  @Output() selectedChargeItem = new EventEmitter<unknown>();

  upperTableItems: ProductTableRowData[];
  lowerTableItems: ProductTableRowData[];

  isTableExpanded = false;
  pagedEntity: PagedEntity<LineItem>;

  currencyPipe = new CurrencyPipe('en-US');
  priceAdjustmentPipe = new PriceAdjustmentPipe();

  ngOnInit(): void {
    this.populateUpperTableItems();
    this.populateLowerTableItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isFlatStructure, rootLineItem, pageSize } = changes;

    const isFlatChanged = isFlatStructure && !isFlatStructure.isFirstChange();
    const rootItemChanged = rootLineItem && !rootLineItem.isFirstChange();
    const pageSizeChanged = pageSize && !pageSize.isFirstChange();

    if (rootItemChanged) {
      this.populateUpperTableItems();
    }

    if (isFlatChanged || rootItemChanged || pageSizeChanged) {
      this.populateLowerTableItems();
    }
  }

  handlePageChanged(pageChangedEvent: PageChangedEvent): void {
    this.pagedEntity = new PagedEntity<LineItem>(this.pagedEntity.allItems, pageChangedEvent.page, this.pageSize);
    this.lowerTableItems = this.flattenForRendering(this.pagedEntity.items);
  }

  toggleExpandRow(itemId: string): void {
    const tableItems = this.lowerTableItems;
    let parentIndex;
    let parentItem;

    // loop through flattened items in the lower table
    for (let i = 0; i < tableItems.length; i++) {
      const item = tableItems[i];
      if (item.id === itemId) {
        // find parent's index and toggle it's expanded prop
        parentIndex = i;
        parentItem = { ...item, expanded: !item.expanded };
        tableItems.splice(i, 1, parentItem);
      } else if (!isNaN(parentIndex) && i > parentIndex) {
        // process all nested items
        if (parentItem.expanded && item.depth === parentItem.depth + 1) {
          // expand items only one level above when expanding
          tableItems.splice(i, 1, { ...item, hidden: false, expanded: false });
        } else if (!parentItem.expanded && item.depth > parentItem.depth) {
          // collapse all nested items when collapsing
          tableItems.splice(i, 1, { ...item, hidden: true, expanded: false });
        }

        // stop when reached item on the same depth level
        if (item.depth === parentItem.depth) {
          break;
        }
      }
    }

    this.lowerTableItems = [...tableItems];
  }

  chargeGroupItemSelect(groupCharge: unknown): void {
    this.selectedChargeGroupItem.emit(groupCharge);
  }

  chargeItemSelect(chargeItem: unknown): void {
    this.selectedChargeItem.emit(chargeItem);
  }

  getActionCodeBadgeColor(actionCode: ActionCode): BadgeColor {
    switch (actionCode) {
      case ActionCode.ADD:
        return BadgeColor.BLUE;
      case ActionCode.DELETE:
        return BadgeColor.RED;
      case ActionCode.RENEW:
        return BadgeColor.YELLOW;
    }
    return void 0;
  }

  rowTrackByFn(_: number, row: ProductTableRowData): ProductTableRowData['id'] {
    return row.id;
  }

  approvalTrackByFn(_: number, approval: ApprovalItem): ApprovalItem['id'] {
    return approval.id;
  }

  private populateUpperTableItems(): void {
    this.upperTableItems = this.rootLineItem.chargeGroupItems.map(chargeGroup => ({
      id: chargeGroup.id,
      value: this.chargeGroupItemToRowItem(chargeGroup),
      originalValue: chargeGroup
    }));
  }

  private populateLowerTableItems(): void {
    const paginationItems = this.flattenForPagination(this.rootLineItem.lineItems, this.isFlatStructure);

    this.pagedEntity = new PagedEntity<LineItem>(paginationItems, 0, this.pageSize);
    this.lowerTableItems = this.flattenForRendering(this.pagedEntity.items);
  }

  private flattenForPagination(lineItems: LineItem[], deep: boolean): LineItem[] {
    const items: LineItem[] = [];

    const flattenFn = (lineItem: LineItem) => {
      items.push(lineItem);

      if (deep) {
        for (const childLineItem of lineItem.lineItems) {
          flattenFn(childLineItem);
        }
      }
    };

    for (const firstLevelLineItem of lineItems) {
      flattenFn(firstLevelLineItem);
    }

    return items;
  }

  private flattenForRendering(lineItems: LineItem[]): ProductTableRowData[] {
    const items: ProductTableRowData[] = [];

    const flattenFn = (lineItem: LineItem, depth: number) => {
      items.push({
        id: lineItem.id,
        depth,
        value: this.lineItemToRowItem(lineItem),
        originalValue: lineItem,
        hidden: depth !== 0,
        expandable: true
      });

      if (lineItem.chargeItems.length) {
        depth++;

        for (const chargeItem of lineItem.chargeItems) {
          items.push({
            id: chargeItem.id,
            depth,
            value: this.chargeItemToRowItem(chargeItem),
            originalValue: chargeItem,
            hidden: true,
            selectable: true
          });
        }
      }

      if (!this.isFlatStructure) {
        for (const childLineItem of lineItem.lineItems) {
          flattenFn(childLineItem, depth);
        }
      }
    };

    for (const firstLevelLineItem of lineItems) {
      let depth = 0;

      flattenFn(firstLevelLineItem, depth++);
    }

    return items;
  }

  private lineItemToRowItem(item: LineItem): ProductTableRowItem {
    return {
      id: item.id,
      name: item.name,
      actionCode: item.actionCode
    };
  }

  private chargeItemToRowItem(item: ChargeItem): ProductTableRowItem {
    const price =
      item.chargeMethod === ChargeMethod.OneTime
        ? item.netPrice
        : ShoppingCartUtils.getProratedPrice(item.netPrice, item.sellingTerm);

    const priceDiff = item.netUnitPrice - item.baseListUnitPrice;
    const priceChange = priceDiff ? this.currencyPipe.transform(priceDiff) : null;

    const discount = item.priceAdjustment
      ? this.priceAdjustmentPipe.transform(item.priceAdjustment.amount, [item.priceAdjustment.type])
      : null;

    return {
      id: item.id,
      name: item.chargeTypeDisplayValue,
      quantity: item.quantity,
      price,
      priceChange,
      subtotal: item.netPrice,
      approval: this.getApprovals(item.id),
      discount,
      repeat: ShoppingCartUtils.getFrequencyDisplayValue(item.frequencyDuration, item.frequencyUnit)
    };
  }

  private chargeGroupItemToRowItem(item: ChargeGroupItem): ProductTableRowItem {
    const price =
      item.chargeMethod === ChargeMethod.OneTime
        ? item.netPrice
        : ShoppingCartUtils.getProratedPrice(item.netPrice, item.sellingTerm);

    const discount = item.netPrice !== item.listPrice ? this.currencyPipe.transform(item.listPrice) : null;
    const priceChange = discount ? this.currencyPipe.transform(item.listPrice - item.netPrice) : null;

    return {
      id: item.id,
      name: item.chargeTypeDisplayValue,
      price,
      priceChange,
      approval: this.getApprovals(item.id),
      discount,
      subtotal: item.netPrice,
      repeat: ShoppingCartUtils.getFrequencyDisplayValue(item.frequencyDuration, item.frequencyUnit)
    };
  }

  private getApprovals(itemId: LineItem['id']): ProductTableRowItem['approval'] {
    if (this.approvalItems) {
      return this.approvalItems.filter(approvalItem => approvalItem.lineItemId === itemId);
    }

    return [];
  }
}
