import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CatalogCategoriesItem } from '../../models';

@Component({
  selector: 'vl-catalog-categories',
  templateUrl: './catalog-categories.component.html',
  styleUrls: ['./catalog-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogCategoriesComponent implements OnChanges {
  @Input() items: CatalogCategoriesItem[];
  @Input() selectedItemId: CatalogCategoriesItem['id'];

  sortedItems: CatalogCategoriesItem[];

  @Output() private itemSelected = new EventEmitter<CatalogCategoriesItem['id']>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('items')) {
      this.sortedItems = this.sortItems(this.items);
    }
  }

  onItemSelect(e: MouseEvent, { id }: CatalogCategoriesItem): void {
    e.preventDefault();
    this.itemSelected.emit(id);
  }

  isHighlighted(item: CatalogCategoriesItem): boolean {
    const isChild = (i: CatalogCategoriesItem): boolean => {
      return i.id === this.selectedItemId || (i.items || []).some(childItem => isChild(childItem));
    };

    return isChild(item);
  }

  trackById(_: number, { id }: CatalogCategoriesItem): CatalogCategoriesItem['id'] {
    return id;
  }

  private sortItems(items: CatalogCategoriesItem[]): CatalogCategoriesItem[] {
    const sortedItems = items.map(item => {
      if (item.items) {
        return {
          ...item,
          items: this.sortItems(item.items)
        };
      }
      return { ...item };
    });

    return [...sortedItems].sort((a: CatalogCategoriesItem, b: CatalogCategoriesItem) => {
      return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
    });
  }
}
