import { AgGridModule } from 'ag-grid-angular';
import { DragulaModule } from 'ng2-dragula';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@veloce/components';
import { DemoMassEditComponent } from './mass-edit/mass-edit.component';
import { TableConfigurationComponent } from './table-configuration/table-configuration.component';
import { TextCellEditorComponent } from './table/editors/text-cell-editor/text-cell-editor.component';
import {
  CheckboxCellRendererComponent
} from './table/renderers/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { CommentCellRendererComponent } from './table/renderers/comment-cell-renderer/comment-cell-renderer.component';
import { ContentCellRenderComponent } from './table/renderers/content-renderer/content-renderer.component';
import { MenuRendererComponent } from './table/renderers/menu-renderer/menu-renderer.component';
import {
  SubtotalCellRendererComponent
} from './table/renderers/subtotal-cell-renderer/subtotal-cell-renderer.component';
import { TooltipCellRendererComponent } from './table/renderers/tooltip-cell-renderer/tooltip-cell-renderer.component';
import { TableComponent } from './table/table.component';
import { AdminFormComponent } from './template-admin/admin-form/admin-form.component';
import { AdminListComponent } from './template-admin/admin-list/admin-list.component';
import { TemplateAdminComponent } from './template-admin/template-admin.component';
import { TemplateEditComponent } from './template-admin/template-edit/template-edit.component';
import { ViewComponent } from './template-admin/view-base/view-base.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([
      CheckboxCellRendererComponent,
      CommentCellRendererComponent,
      ContentCellRenderComponent,
      MenuRendererComponent,
      SubtotalCellRendererComponent,
      TextCellEditorComponent,
      TooltipCellRendererComponent
    ]),
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    DragulaModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [
    AdminFormComponent,
    AdminListComponent,
    CheckboxCellRendererComponent,
    CommentCellRendererComponent,
    ContentCellRenderComponent,
    DemoMassEditComponent,
    MenuRendererComponent,
    SubtotalCellRendererComponent,
    TableComponent,
    TableConfigurationComponent,
    TemplateAdminComponent,
    TemplateEditComponent,
    TextCellEditorComponent,
    ToastComponent,
    TooltipCellRendererComponent,
    ViewComponent
  ],
  exports: [
    AdminFormComponent,
    DemoMassEditComponent,
    MenuRendererComponent,
    TableConfigurationComponent,
    TemplateAdminComponent,
    ToastComponent
  ]
})
export class DemoComponentsModule {}
