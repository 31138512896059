import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MassEditResult, MassEditSource } from '../../models/mass-edit.model';

interface MassEditData {
  id: MassEditSource['id'];
  label: MassEditSource['label'];
  control: FormControl;
}

@Component({
  selector: 'hr-mass-edit',
  templateUrl: './mass-edit.component.html',
  styleUrls: ['./mass-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassEditComponent implements OnInit {
  @Input() source: MassEditSource[][];
  @Input() header: string;

  data: MassEditData[][] = [];

  @Output() private applyForAll = new EventEmitter<MassEditResult[][]>();
  @Output() private clearTable = new EventEmitter<void>();

  ngOnInit(): void {
    this.data = this.source.map(section => {
      return section.map(item => {
        return {
          id: item.id,
          label: item.label,
          control: new FormControl('', item.validators)
        };
      });
    });
  }

  onApply(): void {
    const result = this.data.map(section => {
      const items = section.map(item => {
        return {
          id: item.id,
          value: item.control.value
        };
      });

      return items.filter(item => !!item.value);
    });

    this.applyForAll.emit(result.filter(section => section.length));
  }

  onClear(): void {
    this.clearTable.emit();
  }

  trackById = (_: number, { id }: MassEditSource): number | string => id;
}
