import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ListItem, OutputDiscountData, WidgetAction } from '../../models';
import { PartialGroupCharge } from './group-charge-widget.model';

@Component({
  selector: 'vl-group-charge-widget',
  styleUrls: ['group-charge-widget.component.scss'],
  templateUrl: 'group-charge-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupChargeWidgetComponent implements OnChanges {
  @Input() groupCharge: PartialGroupCharge;
  @Input() isReadonly: boolean;

  itemList: ListItem[];

  @Output() private handleDiscountChange = new EventEmitter<OutputDiscountData>();
  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();

  ngOnChanges({ groupCharge }: SimpleChanges): void {
    const { currentValue } = groupCharge;
    const { netPrice, listPrice } = currentValue as PartialGroupCharge;

    this.itemList = [
      {
        id: 'NET_PRICE',
        label: 'Net Price',
        value: netPrice
      },
      {
        id: 'LIST_PRICE',
        label: 'List Price',
        value: listPrice
      }
    ];
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }

  onDiscountChange(discountData: OutputDiscountData): void {
    this.handleDiscountChange.emit(discountData);
  }
}
