import {Pipe, PipeTransform} from "@angular/core";
import {EntityUtil} from "../service/entity.util";

@Pipe({
    name: '_byCriteria',
    pure: false
})
export class CriteriaFilterPipe implements PipeTransform {
    transform(unfilteredArray: any[], criteria: Map<string, string>): any {
        let filteredArray:any[] = [];

        unfilteredArray.forEach(item => {
            if (criteria.size > 0) {
                let valuesMatch = Array.from(criteria.keys()).every(filterName => {
                    let filterValue = criteria.get(filterName);

                    if (EntityUtil.isBlank(filterValue)) { return true; }

                    return item[filterName].toLowerCase().indexOf(filterValue.toLowerCase()) != -1;
                });

                if (valuesMatch) {
                    filteredArray.push(item);
                }
            } else {
                filteredArray.push(item);
            }
        });

        return filteredArray;
    }
}