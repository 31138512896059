import { ErrorHandler, Injectable, NgZone, ViewChild } from '@angular/core';
import { ErrorDialogService } from '../error-dialog/error-dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private errorDialog: ErrorDialogService) {}

  handleError(error: Error): void {
    this.zone.run(() => {
      this.errorDialog.showError(error.message);
    });

    // tslint:disable-next-line:no-console
    console.error('Error from global error handler', error);
  }
}
