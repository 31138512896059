import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionItem, InputType } from '../../models/option.model';

@Component({
  selector: 'gt-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionComponent {
  @Input() optionItems: OptionItem[];
  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() inputType: InputType;

  type = InputType;
  inputName = Math.random().toString(36).substr(2, 9);

  @Output() private selectedChange = new EventEmitter<OptionItem>();

  isDisabled(item: OptionItem): boolean {
    return (
      item.isDisabled ||
      (!item.isSelected &&
        this.max &&
        this.max > 1 &&
        this.optionItems.filter(({ isSelected }) => isSelected).length === this.max)
    );
  }

  onInputChange(item: OptionItem, isSelected: boolean): void {
    this.selectedChange.emit({
      ...item,
      isSelected
    });
  }

  trackById = (_: number, { id }: OptionItem): string => id;
}
