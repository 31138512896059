import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddFilterComponent,
  AddressSearchComponent,
  ApplyDiscountFormComponent,
  ApprovalWidgetComponent,
  BadgeComponent,
  BaseWidgetComponent,
  ButtonComponent,
  ButtonGroupComponent,
  CartSettingsWidgetComponent,
  CatalogCategoriesComponent,
  ChargeWidgetComponent,
  CheckboxComponent,
  CodeEditorComponent,
  ConfirmDialogComponent,
  DatepickerComponent,
  DefaultFilterComponent,
  DropdownComponent,
  FilterComponent,
  FilterListComponent,
  FormSliderComponent,
  GroupChargeWidgetComponent,
  InlineEditableDirective,
  InlineEditorComponent,
  InputFilterComponent,
  InputGroupComponent,
  LoaderComponent,
  LoadingStateComponent,
  PaginationComponent,
  PriceOverrideFormComponent,
  PriceWaterfallWidgetComponent,
  ProductHeaderComponent,
  ProductTableComponent,
  QuoteLevelWarningComponent,
  QuoteTotalsWidgetComponent,
  RampTabComponent,
  RampTabsComponent,
  RampWidgetComponent,
  ScrollContainerComponent,
  ShoppingCartHeaderComponent,
  ShoppingCartHeaderItemComponent,
  SliderComponent,
  TableColumnConfigDirective,
  TableComponent,
  TypeaheadComponent,
  VeloceListComponent,
  WidgetItemListComponent
} from './components';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { HighlightIntersectionPipe } from './pipes/highlight-intersection.pipe';
import { PriceAdjustmentPipe } from './pipes/price-adjustment.pipe';
import { ModalService } from './services/modal/modal.service';
import { Language } from './models';
import { ModelLang } from './components/code-editor/languages/model-lang';

declare global {
  interface Window {
    VELO_PUBLIC_PATH: string;
  }
}

declare const monaco: any;

const monacoConfig: NgxMonacoEditorConfig = {
  onMonacoLoad(): void {
    monaco.languages.register({ id: Language.MODEL });
    monaco.languages.setMonarchTokensProvider(Language.MODEL, ModelLang);
  },

  baseUrl: window.VELO_PUBLIC_PATH ? `${window.VELO_PUBLIC_PATH}/assets` : '/assets'
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    MonacoEditorModule.forRoot(monacoConfig),
    TooltipModule.forRoot()
  ],
  declarations: [
    AddFilterComponent,
    AddressSearchComponent,
    ApplyDiscountFormComponent,
    ApprovalWidgetComponent,
    BadgeComponent,
    BaseWidgetComponent,
    ButtonComponent,
    ButtonGroupComponent,
    CartSettingsWidgetComponent,
    CatalogCategoriesComponent,
    ChargeWidgetComponent,
    CheckboxComponent,
    CodeEditorComponent,
    ConfirmDialogComponent,
    DatepickerComponent,
    DefaultFilterComponent,
    DropdownComponent,
    DynamicPipe,
    FilterComponent,
    FilterListComponent,
    FormSliderComponent,
    GroupChargeWidgetComponent,
    HighlightIntersectionPipe,
    InlineEditableDirective,
    InlineEditorComponent,
    InputFilterComponent,
    InputGroupComponent,
    LoaderComponent,
    LoadingStateComponent,
    PaginationComponent,
    PriceAdjustmentPipe,
    PriceOverrideFormComponent,
    PriceWaterfallWidgetComponent,
    ProductHeaderComponent,
    ProductTableComponent,
    QuoteLevelWarningComponent,
    QuoteTotalsWidgetComponent,
    RampTabComponent,
    RampTabsComponent,
    RampWidgetComponent,
    ScrollContainerComponent,
    ShoppingCartHeaderComponent,
    ShoppingCartHeaderItemComponent,
    SliderComponent,
    TableColumnConfigDirective,
    TableComponent,
    TypeaheadComponent,
    VeloceListComponent,
    WidgetItemListComponent
  ],
  providers: [DatePipe, ModalService],
  exports: [
    AddFilterComponent,
    AddressSearchComponent,
    ApprovalWidgetComponent,
    ButtonComponent,
    ButtonGroupComponent,
    CartSettingsWidgetComponent,
    CatalogCategoriesComponent,
    ChargeWidgetComponent,
    CheckboxComponent,
    CodeEditorComponent,
    ConfirmDialogComponent,
    DatepickerComponent,
    DefaultFilterComponent,
    DropdownComponent,
    FilterComponent,
    FilterListComponent,
    FormSliderComponent,
    GroupChargeWidgetComponent,
    InlineEditableDirective,
    InlineEditorComponent,
    InputFilterComponent,
    InputGroupComponent,
    LoaderComponent,
    LoadingStateComponent,
    PaginationComponent,
    PriceWaterfallWidgetComponent,
    ProductHeaderComponent,
    ProductTableComponent,
    QuoteLevelWarningComponent,
    QuoteTotalsWidgetComponent,
    RampTabComponent,
    RampTabsComponent,
    RampWidgetComponent,
    ScrollContainerComponent,
    ShoppingCartHeaderComponent,
    SliderComponent,
    TableColumnConfigDirective,
    TableComponent,
    TypeaheadComponent,
    VeloceListComponent
  ]
})
export class ComponentsModule {}
