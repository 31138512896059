import {Injectable} from '@angular/core';
import {
  PutTraceSegmentsCommandOutput,
  XRay
} from "@aws-sdk/client-xray";

const crypto = window.crypto;

interface SegmentHttpRequest {
  url: string;
  method: string;
  traced?: boolean;
}

interface SegmentHttpResponse {
  status: number;
}

interface SegmentHttp {
  request: SegmentHttpRequest;
  response?: SegmentHttpResponse;
}

interface SegmentStackFrame {
  label: string;
}

interface SegmentException {
  id: string;
  message: string;
  stack?: SegmentStackFrame[];
}

interface SegmentCause {
  working_directory?: string;
  paths?: string[];
  exceptions: SegmentException[];
}

interface SegmentAnnotations {
  host: string
  backend: string
}

interface Segment {
  id: string;
  trace_id?: string;
  type?: string;
  namespace?: string;
  parent_id?: string;
  user: string;
  name: string;
  in_progress: boolean;
  start_time: number;
  end_time?: number;
  http: SegmentHttp;
  error?: boolean;
  throttle?: boolean;
  fault?: boolean;
  cause?: SegmentCause;
  annotations?: SegmentAnnotations;
}

@Injectable()
export class XrayService {
  private readonly xray: XRay;
  private readonly skip: boolean = false;

  constructor() {
    if (window.location.hostname.includes('localhost') ||
      window.location.hostname === '127.0.0.1') {
      // tslint:disable-next-line:no-console
      console.info("Skipping load of XRay SDK for local development");
      this.skip = true;
      return;
    }
    if (!crypto) {
      // tslint:disable-next-line:no-console
      console.info("Skipping load of XRay SDK, because no window.crypto support in browser");
      this.skip = true;
      return;
    }
    this.xray = new XRay({
      region: "us-east-1", credentials: {
        // Veloce Main account: xray-frontend-logging
        accessKeyId: 'AKIA5DL65MQEVSIL5H3B',
        secretAccessKey: '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'
      }
    });
  }

  getTraceHeader(segment: Segment): string {
    if (this.skip) {
      return 'Unsupported';
    }
    return "Root=" + segment.trace_id + ";Parent=" + segment.id + ";Sampled=1";
  }

  beginSegment(method: string, url: string): Segment {
    if (this.skip) {
      return;
    }
    const id = this.getHexId(16);
    const startTime = this.getEpochTime();
    const traceId = '1-' + this.getHexTime() + '-' + this.getHexId(24);

    const segment: Segment = {
      id,
      trace_id: traceId,
      start_time: startTime,
      name: `ui-rest-call:${window.location.hostname}`,
      in_progress: true,
      user: sessionStorage['userid'],
      http: {
        request: {
          url: url,
          method: method.toUpperCase(),
          traced: true
        }
      },
      annotations: {
        host: window.location.hostname,
        backend: window['VELO_API']
      }
    };

    const documents: string[] = [];
    documents[0] = JSON.stringify(segment);
    this.putDocuments(documents);
    return segment;
  }

  endSegment(segment: Segment): void {
    if (this.skip || segment.in_progress === false) {
      return;
    }
    segment.end_time = this.getEpochTime();
    segment.in_progress = false;
    segment.http.response = {
      status: 200
    };
    const documents: string[] = [];
    documents[0] = JSON.stringify(segment);
    this.putDocuments(documents);
  }

  endSegmentWithError(segment: Segment, status?: number, error?: any): void {
    if (this.skip || segment.in_progress === false) {
      return;
    }
    segment.end_time = this.getEpochTime();
    segment.in_progress = false;
    segment.error = status >= 400 && status < 500;
    segment.throttle = status === 429;
    segment.fault = !status || status >= 500 && status < 600;
    if (status) {
      segment.http.response = {
        status: status
      };
    }
    if (error && error instanceof Error) {
      const e = error as Error;

      segment.cause = {
        working_directory: window.location.pathname,
        exceptions: [this.getSegmentException(e)]
      };
    } else if (error) {
      let s: string = '' + error;
      try {
        s = JSON.stringify(error)// prefer JSON representation to avoid typical: Object.object crap
      } catch (unused) {
        // tslint:disable-next-line:no-console
        console.debug(`Failed to serialize error to JSON representation: ${error}`);
      } // cycles are not JSON supported, but might be present!

      const e = new Error(s);
      segment.cause = {
        working_directory: window.location.pathname,
        exceptions: [this.getSegmentException(e)]
      };
    }

    const documents: string[] = [];
    documents[0] = JSON.stringify(segment);
    this.putDocuments(documents);
  }


  private getHexId(length: number): string {
    const bytes = new Uint8Array(length);
    crypto.getRandomValues(bytes);
    let hex = "";
    for (const c of bytes) {
      hex += c.toString(16);
    }
    return hex.substring(0, length);
  }

  private getHexTime(): string {
    return Math.round(new Date().getTime() / 1000).toString(16);
  }

  private getEpochTime(): number {
    return new Date().getTime() / 1000;
  }

  private getSegmentException(error: Error): SegmentException {
    const stack = [];
    const frames = ('' + error.stack).split('\n');
    for (const f of frames) {
      stack.push({
        label: f
      });
    }

    const hashCode = (s: string): number => {
      let hash = 0;
      if (s.length === 0) {
        return hash;
      }
      for (let i = 0; i < s.length; i++) {
        const chr = s.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash = ((hash << 5) - hash) + chr;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    };

    /* Convert value as 32-bit unsigned integer to 8 digit hexadecimal number. */
    const hex32 = (val: number): string => {
      // tslint:disable-next-line:no-bitwise
      val &= 0xFFFFFFFF;
      const hex = val.toString(16).toUpperCase();
      return ("00000000" + hex).slice(-8);
    }

    return {
      id: '00000000' + hex32(hashCode(error.message)),
      message: error.message,
      stack
    };
  }

  private putDocuments(documents: string[]): void {
    const params = {
      TraceSegmentDocuments: documents
    };
    this.xray.putTraceSegments(params, (err: any, data?: PutTraceSegmentsCommandOutput) => {
      if (err) {
        // tslint:disable-next-line:no-console
        console.debug(err, err.stack);
      } else {
        // tslint:disable-next-line:no-console
        console.debug(data);
      }
    });
  }
}

