import { DragulaModule } from 'ng2-dragula';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { AuthGuardFactory } from './guards/auth.guard.service';
import { AuthService } from './modules/login/auth.service';
import { LoginModule } from './modules/login/login.module';
import { ProductModelCache } from './modules/product-model/service/product.model.cache';
import { SharedModule } from './modules/shared/shared.module';
import { BaseHttpService, MessageService, ConfigurationSettingsRepository, XrayService } from '@veloce/shared';
import { ContextStoreService } from './service/context-store.service';
import { DefinitionExportService } from './service/definition.export.service';
import { DefinitionImportService } from './service/definition.import.service';
import { RouteData } from './service/routeData';
import { StartupService } from './service/startup.service';
import { UserProfileService } from './service/user.profile.service';
import { FeaturesService } from './service/features.service';
import { MonacoEditorService } from './service/monaco-editor.service';
import { CurrentStateService } from './service/current-state.service';
import { AdminUserGuard } from './guards/admin-user-guard.service';
import { ErrorHandlerModule } from './modules/errors/error-handler.module';
import { HttpErrorInterceptor } from './modules/errors/directives/http-error.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    LoginModule,
    SharedModule,
    DragulaModule.forRoot(),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ErrorHandlerModule
  ],
  declarations: [AppComponent],
  providers: [
    { provide: 'GLOBAL_BUCKET_ID', useValue: 'all' },
    BaseHttpService,
    MessageService,
    DefinitionExportService,
    DefinitionImportService,
    AuthService,
    ProductModelCache,
    RouteData,
    StartupService,
    XrayService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: StartupService) => () => service.load(),
      deps: [StartupService],
      multi: true
    },
    {
      provide: 'AuthGuard',
      useFactory: AuthGuardFactory(),
      deps: [AuthService, Router, StartupService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    AdminUserGuard,
    UserProfileService,
    ContextStoreService,
    FeaturesService,
    MonacoEditorService,
    CurrentStateService,
    ConfigurationSettingsRepository,
    {
      provide: 'ENV_PROFILES',
      useFactory: (startupService: StartupService) => startupService.getEnvProfiles(),
      deps: [StartupService]
    }
  ],
  exports: []
})
export class AppModule {}
