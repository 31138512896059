import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'list-view-header',
    templateUrl: './list-view-header.component.html'
})
export class ListViewHeaderComponent implements OnInit, OnDestroy {
    private keyUpSubject: Subject<string> = new Subject();

    searchTextForm: FormGroup;
    activeViewMode: string = 'grid'; //possible values grid or list

    constructor() {}

    @Output() viewModeHasChanged: EventEmitter<string> = new EventEmitter();
    @Output() searchTextEntered: EventEmitter<string> = new EventEmitter();

    ngOnInit(): void {
        this.searchTextForm = new FormGroup({
            searchString: new FormControl()
        });

        this.keyUpSubject.pipe(debounceTime(200), distinctUntilChanged()).subscribe(searchText => {
            this.searchTextEntered.emit(_.trim(searchText));
        });
    }

    onGridToListSwitch(mode: string) {
        this.activeViewMode = mode;
        this.viewModeHasChanged.emit(mode);
    }

    handleKeyUp(searchString: string) {
        this.keyUpSubject.next(searchString);
    }

    ngOnDestroy(): void {
        this.keyUpSubject.unsubscribe();
    }
}
