// TODO: file copied from veloce-ui project

enum FrequencyUnit {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY'
}

type FrequencyKeyValue<T> = {
  [key in FrequencyUnit]: T;
};

interface FrequencyUnitPromotion {
  promoFrequency: FrequencyUnit;
  unitsToPromo: number;
}

const FrequencyUnitAdjectives: FrequencyKeyValue<string> = {
  [FrequencyUnit.HOURLY]: 'Hourly',
  [FrequencyUnit.DAILY]: 'Daily',
  [FrequencyUnit.WEEKLY]: 'Weekly',
  [FrequencyUnit.MONTHLY]: 'Monthly',
  [FrequencyUnit.ANNUALLY]: 'Annually'
};

const FrequencyUnitPromotion: FrequencyKeyValue<FrequencyUnitPromotion> = {
  [FrequencyUnit.HOURLY]: {
    promoFrequency: FrequencyUnit.DAILY,
    unitsToPromo: 24
  },
  [FrequencyUnit.DAILY]: {
    promoFrequency: FrequencyUnit.WEEKLY,
    unitsToPromo: 7
  },
  [FrequencyUnit.WEEKLY]: {
    promoFrequency: FrequencyUnit.MONTHLY,
    unitsToPromo: 30
  },
  [FrequencyUnit.MONTHLY]: {
    promoFrequency: FrequencyUnit.ANNUALLY,
    unitsToPromo: 12
  },
  [FrequencyUnit.ANNUALLY]: {
    promoFrequency: undefined,
    unitsToPromo: 0
  }
};

const frequencyAliases = {
  [`${FrequencyUnit.WEEKLY}x2`]: `Bi${FrequencyUnitAdjectives[FrequencyUnit.WEEKLY]}`,
  [`${FrequencyUnit.MONTHLY}x2`]: `Bi${FrequencyUnitAdjectives[FrequencyUnit.MONTHLY]}`,
  [`${FrequencyUnit.MONTHLY}x3`]: 'Quarterly',
  [`${FrequencyUnit.MONTHLY}x6`]: `Semi${FrequencyUnitAdjectives[FrequencyUnit.ANNUALLY]}`
};

const promoteUnit = (frequencyUnit: FrequencyUnit, duration: number): [FrequencyUnit, number] => {
  const { promoFrequency, unitsToPromo } = FrequencyUnitPromotion[frequencyUnit];
  const promotedDuration = duration / unitsToPromo;

  if (promotedDuration % 1 === 0) {
    return [promoFrequency, promotedDuration];
  }

  return [frequencyUnit, duration];
};

export class ShoppingCartUtils {
  static getProratedPrice(price: number, sellingTerm: number): number {
    return sellingTerm && sellingTerm !== 1 ? price / sellingTerm : price;
  }

  static getFrequencyDisplayValue(duration: number, frequencyUnit: string): string {
    if (!frequencyUnit) {
      return '';
    }

    const upperCaseFrequencyUnit = frequencyUnit.toUpperCase() as FrequencyUnit;
    const [frequency, term] = promoteUnit(upperCaseFrequencyUnit, duration);
    const frequencyId = `${frequency}x${term}`;
    const frequencyAlias = frequencyAliases[frequencyId];
    const frequencyAdjective = FrequencyUnitAdjectives[frequency];
    const fixedTerm = Number(term.toFixed(1));
    const normalizedFrequency = fixedTerm > 1 ? `${frequencyAdjective} x${fixedTerm}` : frequencyAdjective;

    return frequencyAlias || normalizedFrequency;
  }
}
