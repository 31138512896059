import { RowType } from './table.model';

export interface MenuItem {
  id: string;
  value: string;
  rowType: RowType;
}

export enum MenuItemEventType {
  ADD = 'ADD',
  CLONE = 'CLONE',
  REMOVE = 'REMOVE',
  CUSTOM = 'CUSTOM'
}

export interface MenuItemEvent {
  type: MenuItemEventType;
  data?: any;
}

export interface CustomMenuItem {
  iconClass: string;
  tooltipText: string;
  eventType: any;
}

export interface RowMenuConfig {
  add: {
    show: boolean;
    tooltipText?: string;
    rowTypes: RowType[];
  };
  delete: {
    show: boolean;
    tooltipText?: string;
  };
  clone: {
    show: boolean;
    tooltipText?: string;
  };
  customItems?: CustomMenuItem[];
}
