import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js/dist/zone';

declare let __webpack_public_path__: string;
declare global {
  interface Window {
    COMPRESSION_METHOD: string; // none, br, gzip
    ADMIN_CONTEXT: boolean;
    RPC_MESSAGE: string; // ???
    SavingMode: string; // ???
    VELO_API: string;
    VELO_DOCGEN_API: string;
    VELO_PUBLIC_PATH: string;
    VELO_KEY?: string;
    VELO_BACK_FN: (recordId: string) => void;
    RPC_BROADCAST?: (response: unknown) => void;
  }
}

__webpack_public_path__ = environment.staticResourcePath;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
