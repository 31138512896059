import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent {
  @Input() isChecked: boolean;
  @Input() isDisabled: boolean;

  @Output() private checkedChange = new EventEmitter<void>();

  onSlideClick(): void {
    if (!this.isDisabled) {
      this.checkedChange.emit();
    }
  }
}
