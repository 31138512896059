import { Subject } from 'rxjs';
import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Condition } from '../../model/criteria/condition.model';
import { CookieStore } from '../../service/cookies';
import { EntityUtil } from '../../service/entity.util';
import { ExpressionFilterService } from '../../service/expression-filter.service';
import { UUID } from '../../service/uuid.service';

@Directive({
  selector: '[grid-filters]',
  exportAs: 'gridFilters'
})
export class GridFiltersDirective implements OnInit {
  private _filters: Map<string, Condition> = new Map();
  private cookieStore: CookieStore<any>;
  private storeSubject: Subject<void> = new Subject();

  constructor(private expressionFilterService: ExpressionFilterService) {}

  @Output() onFiltersUpdate = new EventEmitter<any>();

  @Input()
  set filters(conditions: Condition[]) {
    conditions.forEach(condition => {
      this._filters.set(condition.id, condition);
    });
  }

  @Input()
  set storeBucket(bucket: string) {
    this.cookieStore = new CookieStore(bucket);
  }

  get filters(): Condition[] {
    return Array.from(this._filters.values());
  }

  ngOnInit(): void {
    if (this.cookieStore) {
      this.storeSubject.subscribe(() => {
        this.cookieStore.store(EntityUtil.mapToDictionary(this._filters));
      });

      this._filters = EntityUtil.dictionaryToMap(this.cookieStore.get());
      this.onFiltersUpdate.emit(this.filters);
    }
  }

  updateFilters(condition: Condition) {
    this._filters.set(condition.id, condition);

    this.storeSubject.next();
    this.onFiltersUpdate.emit(this.filters.filter(({ value }) => value));
  }

  addFilter(key: string, type: string, displayValue: string = '', valueOptions?: string[] | { [key: string]: string }) {
    let condition = new Condition();

    condition.id = UUID.UUID();
    condition.key = key;
    condition.type = type;
    condition.displayValue = displayValue || key;

    condition.valueOptions = valueOptions;

    this._filters.set(condition.id, condition);
  }

  removeFilter(event: Event, id: string) {
    this._filters.delete(id);

    this.storeSubject.next();
    this.onFiltersUpdate.emit(this.filters);
  }

  resetFilters(): void {
    this._filters.clear();
    this.onFiltersUpdate.emit(this.filters);
  }
}
