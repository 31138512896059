import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from '@veloce/shared';

@Component({
  selector: 'notification-messages',
  templateUrl: 'notifications.directive.html'
})
export class NotificationsDirective implements OnInit, OnDestroy {
  messages: any = [];
  @Input() bucket: string;

  private messageSubscription: Subscription;

  constructor(private router: Router, private messageService: MessageService, private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.messageSubscription = this.messageService.notificationMessages.subscribe(msg => {
      if (this.bucket && this.bucket === msg.messageBucketId) {
        if (msg.message) {
          this.messages = [].concat(msg.message);

          this.messages.forEach(message => {
            this.scheduleRemoval(message, 5000);
          });
        } else {
          this.messages = [];
        }

        this.ref.markForCheck();
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.messages) {
        this.messages.forEach(msg => {
          if (msg.keepMessage) {
            msg.keepMessage = false;
          } else {
            this.removeMessage(msg);
          }
        });
      }
    });
  }

  private scheduleRemoval(message, timeout: number) {
    if (this.hasUndoAction(message) || message.keepMessage) {
      return;
    }

    message.timeoutId = window.setTimeout(() => {
      this.ref.markForCheck();
      this.removeMessage(message);
    }, timeout);
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  undo(message: any) {
    if (!!message.undoFn) {
      message.undoFn.apply([]);
    }
  }

  hasUndoAction(message: any) {
    return !!message.undoFn;
  }

  public removeMessage(message: any) {
    let index = this.messages.indexOf(message);
    if (index < 0) return;

    this.messages.splice(index, 1);
    if (message.timeoutId) {
      window.clearTimeout(message.timeoutId);
      message.timeoutId = null;
    }
  }
}
