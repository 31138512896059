import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { VlModalRef } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private bsModalService: BsModalService) {}

  show(component: any, options?: ModalOptions): VlModalRef {
    return this.bsModalService.show(component, options);
  }
}
