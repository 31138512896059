import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {XrayService} from "@veloce/shared";
import {throwError} from "rxjs";

@Injectable()
export class StartupService {

    private envProfiles = [];

    constructor(
        private http: HttpClient,
        private xray: XrayService,
        ) {}

    load(): Promise<boolean> {
        return this.initEnvProfiles();
    }

    getEnvProfiles(): string[] {
        return this.envProfiles;
    }

    private initEnvProfiles(): Promise<boolean> {
        this.envProfiles.splice(0, this.envProfiles.length);

        const api = window.VELO_API || '';
        const token = window.VELO_KEY || '';

        let headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
        headers = headers.append('Authorization', token );
        const segment = this.xray.beginSegment("GET", api + '/services/env');
        headers = headers.append('X-Amzn-Trace-Id', this.xray.getTraceHeader(segment));

        const result = this.http.get(api + '/services/env', {
            headers,
            observe: 'body',
        }).pipe(
            map(profiles => {
                this.xray.endSegment(segment);
                if (profiles && Array.isArray(profiles)) {
                    profiles.forEach(p => this.envProfiles.push(p));
                }
                return true;
            }),
            catchError((err: HttpErrorResponse) => {
              this.xray.endSegmentWithError(segment, err.status, err);
                  return throwError(err);
            })
        );
        return result.toPromise();
    }


}
