import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import {AuthService} from "../modules/login/auth.service";
import {Observable, of} from 'rxjs';
import {StartupService} from "../service/startup.service";
import {EnvHolder} from "../model/env.holder";
import { map, catchError } from 'rxjs/operators';

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: AuthService,
        private router: Router,) {
    }

    canLoad(route: Route) {
        const url = `/${route.path}`;

        return this.authService.isLoggedIn()
            .pipe(
                map(status => {
                    if (status) {
                        return true;
                    }
                    throw new Error();
                }),
                catchError(() => {
                    this.router.navigate(['/login'], {queryParams: {redirectTo: url}});
                    return of(false);
                })
            );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isLoggedIn()
            .pipe(
                map(status => {
                    if (status) {
                        return true;
                    }
                    throw new Error();
                }),
                catchError(() => {
                    this.router.navigate(['/login'], {queryParams: {redirectTo: state.url}});
                    return of(false);
                })
            );
    }

    canActivateChild(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}


export class BypassGuard implements CanActivate, CanActivateChild, CanLoad {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

    canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return true;
    }

}

export function AuthGuardFactory() {
    return (authService: AuthService, router: Router, startupService: StartupService) => {

        const isStandalone = startupService.getEnvProfiles().findIndex(profile => profile.toLowerCase() === 'standalone') != -1;
        EnvHolder.standalone = isStandalone;
        if (isStandalone) {
            return new AuthGuard(authService, router);
        } else {
            return new BypassGuard();
        }
    }
}
