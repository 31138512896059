import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DiscountType, DropdownItem, FormControlGroup, OutputDiscountData } from '../../models';

@Component({
  selector: 'vl-apply-discount-form',
  styleUrls: ['apply-discount-form.component.scss'],
  templateUrl: 'apply-discount-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplyDiscountFormComponent implements OnInit {
  @Input() discountTypes: DropdownItem<DiscountType>[] = Object.values(DiscountType).map(v => ({
    id: v,
    value: v
  }));
  @Input() dropdownHeader: string;

  form: FormControlGroup;

  @Output() private handleDiscountChange = new EventEmitter<OutputDiscountData>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.buildForm();
  }

  onDiscardDiscount(): void {
    const discountData: OutputDiscountData = {
      discountType: undefined,
      amount: undefined
    };

    this.handleDiscountChange.emit(discountData);
  }

  onApplyDiscount(): void {
    const { value } = this.form.formGroup;

    this.handleDiscountChange.emit(value);
  }

  onDiscountTypeChange(selectedItem: DropdownItem<DiscountType>): void {
    this.form.discountTypeControl.setValue(selectedItem.value);
  }

  private buildForm(): FormControlGroup {
    const formGroup = this.fb.group({
      discountType: ['', Validators.required],
      amount: ['', Validators.required]
    });
    const discountTypeControl = formGroup.get('discountType');
    const amountControl = formGroup.get('amount');

    return {
      formGroup,
      discountTypeControl,
      amountControl
    };
  }

  get isFormInvalid(): boolean {
    return this.form.formGroup.invalid;
  }
}
