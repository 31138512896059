import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: '_filter',
    pure: true
})
export class SearchFilterPipe implements PipeTransform {
    transform(list: any, filterValue, filterProperty?): any {
        if (filterValue && Array.isArray(list)) {
            let filter = typeof(filterValue) === "boolean" ? filterValue : filterValue.toLowerCase();

            if (filterProperty) {
                return list.filter(item => {
                    let itemProperty = item[filterProperty];

                    if (typeof(filter) === "boolean") {
                        return itemProperty === filter;
                    } else {
                        return itemProperty.toLowerCase().indexOf(filter) != -1
                    }
                });
            } else {
                return list.filter(item => item.toLowerCase().indexOf(filter) != -1);
            }
        } else {
            return list;
        }
    }
}