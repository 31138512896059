import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize } from '../../models';

@Component({
  selector: 'vl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() labelText?: string;
  @Input() isDisabled?: boolean;
  @Input() isOutline?: boolean;
  @Input() isText?: boolean;
  @Input() leftIconName?: string;
  @Input() rightIconName?: string;
  @Input() buttonSize?: ButtonSize = ButtonSize.Default;

  @Output() private readonly handleClick = new EventEmitter<MouseEvent>();

  onClick(event: MouseEvent): void {
    this.handleClick.emit(event);
  }

  get buttonClassMap(): { [key: string]: boolean } {
    return {
      'vl-button--outline': this.isOutline,
      'vl-button--text': this.isText,
      'vl-button--icon-only': !this.labelText,
      [`vl-button--${this.buttonSize}`]: true
    };
  }
}
