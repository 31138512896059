import { Injectable } from '@angular/core';
import { LineItemDTO as LineItem } from '../modules/catalog/model/line-item.dto.model';

@Injectable({ providedIn: 'root' })
export class CurrentStateService {
  currentState: LineItem[] = [];

  update(state: LineItem[]): void {
    this.currentState = state;
  }
}
