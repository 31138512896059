import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentTemplate } from '../models';

enum TemplateAdminView {
  SELECT = 'SELECT',
  EDIT = 'EDIT'
}

@Component({
  selector: 'vd-template-admin',
  styleUrls: ['template-admin.component.scss'],
  templateUrl: 'template-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateAdminComponent {
  @Input() isLoading: boolean;
  @Input() templateDocuments: DocumentTemplate[];
  @Input() templateProperties: DocumentTemplate['properties'];

  activeView: TemplateAdminView = TemplateAdminView.SELECT;
  selectedTemplate: DocumentTemplate;

  @Output() private cancelAdmin = new EventEmitter<void>();
  @Output() private templateSelect = new EventEmitter<DocumentTemplate>();
  @Output() private templateSave = new EventEmitter<any>();
  @Output() private templateRun = new EventEmitter<any>();
  @Output() private templateDelete = new EventEmitter<DocumentTemplate>();

  onTemplateNew(): void {
    this.selectedTemplate = new DocumentTemplate(this.templateProperties);
    this.activeView = TemplateAdminView.EDIT;
  }

  onTemplateSave(data: any): void {
    this.selectedTemplate = void 0;
    this.templateSave.emit(data);
    this.activeView = TemplateAdminView.SELECT;
  }

  onTemplateRun(data: any): void {
    this.templateRun.emit(data);
  }

  onAdminCancel(): void {
    this.cancelAdmin.emit();
  }

  onTemplateSelect(templateIndex: number): void {
    this.templateSelect.emit(this.templateDocuments[templateIndex]);
  }

  onTemplateEditCancel(): void {
    this.selectedTemplate = void 0;
    this.activeView = TemplateAdminView.SELECT;
  }

  onTemplateConfigure(templateIndex: number): void {
    this.selectedTemplate = this.templateDocuments[templateIndex];
    this.activeView = TemplateAdminView.EDIT;
  }

  onTemplateDelete(templateIndex: number): void {
    this.templateDelete.emit(this.templateDocuments[templateIndex]);
  }

  get isSelectViewActive(): boolean {
    return this.activeView === TemplateAdminView.SELECT;
  }

  get isEditViewActive(): boolean {
    return this.activeView === TemplateAdminView.EDIT;
  }

  get templateList(): string[] {
    return (this.templateDocuments || []).map(({ name }) => name);
  }
}
