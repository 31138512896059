import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DataSelectorEvent, DataSelectorEventType, DataSelectorItem } from '../../models/data-selector.model';

@Component({
  selector: 'hr-data-selector',
  styleUrls: ['data-selector.component.scss'],
  templateUrl: 'data-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSelectorComponent implements OnInit, OnDestroy {
  @Input() selectorTitle: string;
  @Input() dataItems: DataSelectorItem[];
  @Input() isSortable? = false;
  @Input() minSelectedCount = 0;

  readonly dragulaGroup = 'items';

  @Output() private dataSelectorEvent = new EventEmitter<DataSelectorEvent>();

  @ViewChild('mirrorContainerRef', { static: true }) private mirrorContainerRef: ElementRef;

  private onDestroy = new Subject<void>();

  constructor(private dragulaService: DragulaService) {}

  ngOnInit(): void {
    this.dragulaService.createGroup(this.dragulaGroup, {
      direction: 'vertical',
      mirrorContainer: this.mirrorContainerRef.nativeElement
    });
    this.dragulaService
      .dropModel()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(({ targetModel: sortedDataItems }) => {
        this.dataSelectorEvent.emit({
          type: DataSelectorEventType.SORT_ORDER,
          payload: sortedDataItems
        });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.dragulaService.destroy(this.dragulaGroup);
  }

  toggle(dataItem: DataSelectorItem, isChecked: boolean): void {
    this.dataSelectorEvent.emit({
      type: DataSelectorEventType.TOGGLE_ITEM,
      payload: { ...dataItem, isChecked }
    });
  }

  trackById = (_: number, { id }: DataSelectorItem): string => id;

  get checkedCount(): number {
    return this.dataItems.filter(({ isChecked }) => isChecked).length;
  }
}
