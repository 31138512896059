import { NgModule } from '@angular/core';
import {BrowserModule} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoginRoutingModule} from "./login-routing.module";
import {LoginComponent} from "./components/login.component";
import {SharedModule} from "../shared/shared.module";
import {UserProfileRepository} from "./user.profile.repository";

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        LoginRoutingModule,
        SharedModule
    ],
    declarations: [
        LoginComponent,
    ],
    providers: [
        UserProfileRepository
    ]
})
export class LoginModule { }

