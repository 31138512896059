import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApprovalItem, WidgetAction } from '../../models';

@Component({
  selector: 'vl-approval-widget',
  templateUrl: './approval-widget.component.html',
  styleUrls: ['./approval-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalWidgetComponent {
  @Input() approvalItems: ApprovalItem[];

  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }

  trackById(_: number, item: ApprovalItem): string {
    return item.id;
  }
}
