import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AdminFormField,
  DocumentTemplate,
  DocumentTemplateSettingType,
  TemplateProperty,
  TemplateQuery
} from '../../models';

enum Tab {
  SETTINGS,
  SCRIPT,
  QUERIES,
  ATTRIBUTES
}

interface TemplateResult {
  file: File;
  template: DocumentTemplate;
}

const builtInPropertyFilter = ({ name }) => name === 'dateFormat' || name === 'currencyFormat';

@Component({
  selector: 'vd-template-edit',
  styleUrls: ['template-edit.component.scss'],
  templateUrl: 'template-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateEditComponent implements OnInit {
  @Input() template: DocumentTemplate;
  @Input() templateProperties: DocumentTemplate['properties'] = [];

  Tab = Tab;

  templateFields: AdminFormField[] = [];
  queryFields: AdminFormField[];
  attributeFields: AdminFormField[];

  queryInEdit: FormGroup;
  attributeInEdit: FormGroup;

  activeTab = Tab.SETTINGS;

  form: FormGroup;

  @Output() private save = new EventEmitter<TemplateResult>();
  @Output() private run = new EventEmitter<TemplateResult>();
  @Output() private cancel = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.template = this.template || new DocumentTemplate();
    const { properties } = this.template;
    this.template.properties = properties && properties.length ? properties : this.templateProperties;

    this.initForm();
    this.initTemplateFields();
  }

  initForm(): void {
    const { id, fileName, name, script, queries = [], properties = [] } = this.template;

    this.form = this.fb.group({
      id,
      fileName: this.fb.control(fileName, [Validators.required]),
      file: void 0,
      name: this.fb.control(name, [Validators.required]),
      script,
      queries: this.fb.array(
        queries.map(q =>
          this.fb.group({
            id: q.id,
            queryName: q.queryName,
            resultObjectName: q.resultObjectName,
            objectName: q.objectName,
            fields: q.fields.join(';'),
            statement: q.statement
          })
        )
      ),
      attributes: this.fb.array(
        properties
          .filter(property => !builtInPropertyFilter(property))
          .map(property => {
            return this.fb.group({
              id: property.id,
              name: property.name,
              label: property.label,
              value: property.value,
              type: property.type,
              lov: property.lov && property.lov.join('\n')
            });
          })
      )
    });

    if (properties && properties.length) {
      this.form.addControl(
        'properties',
        properties.reduce((propertyGroup: FormGroup, prop) => {
          propertyGroup.addControl(prop.name, this.fb.control(prop.value));

          return propertyGroup;
        }, this.fb.group({}))
      );
    }
  }

  initTemplateFields(): void {
    const {
      file: fileControl,
      fileName: fileNameControl,
      name: nameControl,
      properties: propControl
    } = this.form.controls;

    this.templateFields.push(
      {
        id: 'file',
        label: 'File Name',
        type: DocumentTemplateSettingType.FILE,
        formControl: fileControl as FormControl,
        fileNameControl: fileNameControl as FormControl
      },
      {
        id: 'name',
        label: 'Name',
        type: DocumentTemplateSettingType.TEXT,
        formControl: nameControl as FormControl
      }
    );

    if (this.template.properties && this.template.properties.length) {
      this.template.properties.filter(builtInPropertyFilter).forEach(({ name, label, type, lov = [] }) => {
        this.templateFields.push({
          id: name,
          label,
          type,
          formControl: (propControl as FormGroup).get(name) as FormControl,
          lov: lov.map(v => ({ id: v, value: v }))
        });
      });
    }
  }

  getQueryList(): string[] {
    return this.form.value.queries.map(({ queryName }: TemplateQuery) => queryName);
  }

  isQueryFormInvalid(): boolean {
    return this.queryFields.some(field => field.formControl.invalid);
  }

  onQueryConfigure(queryIndex: number): void {
    this.queryInEdit = (this.form.controls.queries as FormArray).controls[queryIndex] as FormGroup;

    this.queryFields = this.generateQueryAdminFormFields(this.queryInEdit.value);
  }

  onQueryAdd(): void {
    this.queryFields = this.generateQueryAdminFormFields();
  }

  onQueryDelete(queryIndex: number): void {
    (this.form.controls.queries as FormArray).removeAt(queryIndex);
  }

  onQuerySave(): void {
    const templateQuery = this.queryFields.reduce((query: { [index: string]: any }, field) => {
      query[field.id] = field.formControl.value;
      return query;
    }, new TemplateQuery());

    if (this.queryInEdit) {
      this.queryInEdit.patchValue(templateQuery);
    } else {
      (this.form.controls.queries as FormArray).push(this.fb.group(templateQuery));
    }

    this.queryFields = void 0;
    this.queryInEdit = void 0;
  }

  onQueryCancel(): void {
    this.queryFields = void 0;
    this.queryInEdit = void 0;
  }

  onTemplateSave(): void {
    this.save.emit(this.getFileAndTemplate());
  }

  onTemplateRun(): void {
    this.run.emit(this.getFileAndTemplate());
  }

  onTemplateCancel(): void {
    this.cancel.emit();
  }

  getAttributeList(): string[] {
    return this.form.value.attributes.map(({ name }: TemplateProperty) => name);
  }

  isAttributeFormInvalid(): boolean {
    return this.attributeFields.some(field => field.formControl.invalid);
  }

  onAttributeConfigure(index: number): void {
    this.attributeInEdit = (this.form.controls.attributes as FormArray).controls[index] as FormGroup;

    this.attributeFields = this.generateAttributeFormFields(this.attributeInEdit.value);
  }

  onAttributeAdd(): void {
    this.attributeFields = this.generateAttributeFormFields();
  }

  onAttributeDelete(index: number): void {
    (this.form.controls.attributes as FormArray).removeAt(index);
  }

  onAttributeSave(): void {
    const attributeQuery = this.attributeFields.reduce((attribute: { [index: string]: any }, { id, formControl }) => {
      return {
        ...attribute,
        [id]: formControl.value
      };
    }, {});

    if (this.attributeInEdit) {
      this.attributeInEdit.patchValue(attributeQuery);
    } else {
      (this.form.controls.attributes as FormArray).push(this.fb.group(attributeQuery));
    }

    this.attributeFields = void 0;
    this.queryInEdit = void 0;
  }

  onAttributeCancel(): void {
    this.attributeFields = void 0;
    this.queryInEdit = void 0;
  }

  onTabActivate(tab: Tab): void {
    this.activeTab = tab;
  }

  private getFileAndTemplate(): TemplateResult {
    const { id, file, name, properties = [], queries = [], attributes = [], script } = this.form.value;

    const template = new DocumentTemplate();

    template.id = id;
    template.fileName = file ? file.name : this.template.fileName;
    template.name = name;
    template.script = script;

    template.queries = queries.map((q: TemplateQuery) => ({
      ...q,
      fields: (q.fields || [])
        .toString()
        .split(/\s|;|,/)
        .filter((f: string) => f !== '')
    }));

    if (this.template.properties) {
      template.properties = this.template.properties.filter(builtInPropertyFilter).map(prop => ({
        ...prop,
        value: properties[prop.name]
      }));
    }

    template.properties = [
      ...template.properties,
      ...attributes.map(attribute => {
        return {
          ...attribute,
          lov: attribute.lov && attribute.lov.split('\n')
        };
      })
    ];

    return { file, template };
  }

  private generateQueryAdminFormFields(templateQuery: TemplateQuery = new TemplateQuery()): AdminFormField[] {
    const { queryName, resultObjectName, objectName, fields, statement } = templateQuery;

    return [
      {
        id: 'queryName',
        label: 'Name',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(queryName, [Validators.required])
      },
      {
        id: 'resultObjectName',
        label: 'Result Object Name',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(resultObjectName)
      },
      {
        id: 'objectName',
        label: 'Object Name',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(objectName)
      },
      {
        id: 'fields',
        label: 'Fields',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(fields)
      },
      {
        id: 'statement',
        label: 'Statement',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(statement)
      }
    ];
  }

  private generateAttributeFormFields(property: Partial<TemplateProperty> = {}): AdminFormField[] {
    const { name, label, type = DocumentTemplateSettingType.CHECKBOX, value, lov } = property;
    const defaultFields = [
      {
        id: 'type',
        label: 'Type',
        type: DocumentTemplateSettingType.SELECT,
        lov: [
          {
            id: DocumentTemplateSettingType.CHECKBOX,
            value: DocumentTemplateSettingType.CHECKBOX
          },
          {
            id: DocumentTemplateSettingType.SELECT,
            value: DocumentTemplateSettingType.SELECT
          },
          {
            id: DocumentTemplateSettingType.TEXT,
            value: DocumentTemplateSettingType.TEXT
          }
        ],
        formControl: this.fb.control(type, [Validators.required])
      },
      {
        id: 'name',
        label: 'Name',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(name, [Validators.required])
      },
      {
        id: 'label',
        label: 'Label',
        type: DocumentTemplateSettingType.TEXT,
        formControl: this.fb.control(label, [Validators.required])
      }
    ];

    if (type === DocumentTemplateSettingType.SELECT) {
      return [
        ...defaultFields,
        {
          id: 'lov',
          label: 'Value',
          type: DocumentTemplateSettingType.TEXTAREA,
          formControl: this.fb.control(lov, [Validators.required])
        }
      ];
    }

    return [
      ...defaultFields,
      {
        id: 'value',
        label: 'Value',
        type: DocumentTemplateSettingType.TEXTAREA,
        formControl: this.fb.control(value, [Validators.required])
      }
    ];
  }
}
