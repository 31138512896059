import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

// tslint:disable:use-component-selector
@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  title: string;
  subTitle: string;
  description: string;

  declineBtn: string;
  acceptBtn: string;

  @Output() declined = new EventEmitter<void>();
  @Output() accepted = new EventEmitter<void>();

  constructor(private bsModalRef: BsModalRef) {}

  handleDecline(): void {
    this.declined.emit();
    this.completeObservables();
    this.bsModalRef.hide();
  }

  handleAccept(): void {
    this.accepted.emit();
    this.completeObservables();
  }

  private completeObservables(): void {
    this.accepted.complete();
    this.declined.complete();
  }
}
