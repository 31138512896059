import * as _ from 'lodash';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TraversableEntity } from '../../model/traversable-entity.model';

@Component({
    selector: 'tree-component',
    template: '<ng-template #container></ng-template>'
})
export class TreeComponent implements OnInit {
    @Input() node: TraversableEntity<any>;

    @Input()
    indent: number = 1;

    @Input()
    childrenState: { [index: string]: boolean };

    @Input() template: TemplateRef<any>;

    @Input() context: any;

    @ViewChild('container', { read: ViewContainerRef, static: true })
    container: ViewContainerRef;

    showChild(parentId: string) {
        const shown = this.childrenState[parentId];

        if (shown) {
            delete this.childrenState[parentId];
        } else {
            this.childrenState[parentId] = true;
        }
    }

    ngOnInit() {
        this.childrenState = this.childrenState ?? {};
        let context;

        const defaultContext = {
            node: this.node,
            childrenState: this.childrenState,
            showChild: this.showChild.bind(this),
            indent: this.indent
        };

        if (this.context) {
            context = _.assign(defaultContext, this.context);
        } else {
            context = _.assign(defaultContext);
        }

        this.container.createEmbeddedView(this.template, context);
    }
}
