export class Condition {
    id?: string;
    key: string;
    displayValue?: string;
    type?: string;
    operator: string;
    operatorDescription?: string;
    value: string;
    valueOptions?: string[] | { [key: string]: string };
}
