import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Validators } from '@angular/forms';
import { DataSelectorEvent, DataSelectorEventType, DataSelectorItem } from '../../models/data-selector.model';
import { MassEditResult, MassEditSource } from '../../models/mass-edit.model';
import { SidebarButton } from '../../models/sidebar.model';

interface DomainType {
  id: string;
  label: string;
  subGroup: string;
  isSelected: boolean;
}

interface TableColumn {
  id: string;
  label: string;
  subGroup: string;
  domainTypes: DomainType[];
}

interface Worker {
  id: string;
  value: number;
  fseValue: number;
  isAvailable: boolean;
}

interface TableDataRow {
  id: string;
  label: string;
  workersTotal: number;
  fseTotal: number;
  isSelected: boolean;
  workers: Worker[];
}

interface InlineEditEventPayload {
  productId: string;
  domainTypeName: string;
  value: number;
}

interface MassEditEventPayload {
  [key: string]: number;
}

@Component({
  selector: 'hr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnChanges {
  @Input() tableColumns: TableColumn[];
  @Input() tableData: TableDataRow[];
  @Input() productTypes: DataSelectorItem[];
  @Input() workerTypes: DataSelectorItem[];
  @Input() activeSidebarTab: SidebarButton;

  currentlyEditableCell: HTMLElement;
  activeSidebarBtn: SidebarButton;
  SidebarButton = SidebarButton;

  massEditSource: MassEditSource[][] = [];

  @Output() private inlineEdit = new EventEmitter<InlineEditEventPayload>();
  @Output() private massEditApplyAll = new EventEmitter<MassEditEventPayload>();
  @Output() private massEditClearAll = new EventEmitter<void>();
  @Output() private productDomainToggle = new EventEmitter<[string, boolean]>();
  @Output() private workerDomainToggle = new EventEmitter<[string, boolean]>();
  @Output() private sidebarToggle = new EventEmitter<SidebarButton>();

  private readonly columnCountCSSVariable = '--colCount';
  private readonly rowCountCSSVariable = '--rowCount';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.activeSidebarBtn = this.activeSidebarTab;
  }

  ngOnChanges(_: SimpleChanges): void {
    this.setCSSVariables();
    this.populateMassEditSource();
  }

  trackById = (_: number, { id }: any): string | number => id;

  onInlineEditClick(event: MouseEvent): void {
    event.stopPropagation();

    const editableCell = event.currentTarget as HTMLElement;

    // return if clicking on the same cell
    if (this.currentlyEditableCell === editableCell) {
      return;
    }

    this.currentlyEditableCell = editableCell;
  }

  onInlineEditCancel(): void {
    this.currentlyEditableCell = void 0;
  }

  onInlineEditSubmit(productId: string, { name, value }: any): void {
    this.currentlyEditableCell = void 0;

    this.inlineEdit.emit({ productId, domainTypeName: name, value });
  }

  onSidebarBtnSelect(button: SidebarButton): void {
    this.activeSidebarBtn = button !== this.activeSidebarBtn ? button : null;
    this.sidebarToggle.emit(this.activeSidebarBtn);
  }

  onMassEditApplyAll(data: MassEditResult[][]): void {
    const payload: MassEditEventPayload = {};

    for (const section of data) {
      for (const item of section) {
        payload[item.id] = item.value;
      }
    }

    this.onSidebarBtnSelect(undefined);
    this.massEditApplyAll.emit(payload);
  }

  onMassEditClearAll(): void {
    this.onSidebarBtnSelect(undefined);
    this.massEditClearAll.emit();
  }

  onColumnSelectorChange({ type, payload }: DataSelectorEvent): void {
    if (type === DataSelectorEventType.TOGGLE_ITEM) {
      const { id, isChecked } = payload as DataSelectorItem;
      this.workerDomainToggle.emit([id, isChecked]);
    }
  }

  onRowSelectorChange({ type, payload }: DataSelectorEvent): void {
    if (type === DataSelectorEventType.TOGGLE_ITEM) {
      const { id, isChecked } = payload as DataSelectorItem;
      this.productDomainToggle.emit([id, isChecked]);
    }
  }

  private setCSSVariables(): void {
    const colCount = this.tableColumns.reduce((count, { domainTypes }) => (count += domainTypes.length), 0) * 2;
    const rowCount = this.tableData.length;

    this.el.nativeElement.style.setProperty(this.columnCountCSSVariable, colCount);
    this.el.nativeElement.style.setProperty(this.rowCountCSSVariable, rowCount);
  }

  private populateMassEditSource(): void {
    this.massEditSource = this.tableColumns.map(({ domainTypes }) => {
      return domainTypes.map(({ id, label }) => ({
        id,
        label,
        validators: [Validators.required]
      }));
    });

    this.massEditSource.unshift([{ id: 'addToAll', label: 'Add % to all fields', validators: [Validators.required] }]);
  }
}
