import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ListItem, WidgetAction } from '../../models';
import { WaterfallItem } from './price-waterfall-widget.model';

@Component({
  selector: 'vl-price-waterfall-widget',
  templateUrl: 'price-waterfall-widget.component.html',
  styleUrls: ['price-waterfall-widget.component.scss']
})
export class PriceWaterfallWidgetComponent implements OnChanges {
  @Input() priceWaterfalls: WaterfallItem[];

  itemList: ListItem[];
  footerItemList: ListItem[];

  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();

  ngOnChanges({ priceWaterfalls }: SimpleChanges): void {
    const { currentValue } = priceWaterfalls;
    const total = (currentValue as WaterfallItem[]).reduce((sum, { amount }: WaterfallItem) => sum + amount, 0);

    this.itemList = this.priceWaterfalls.map(({ type, amount, explanation }: WaterfallItem) => ({
      id: `${type}-${amount}-${explanation}`,
      label: explanation,
      value: amount
    }));
    this.footerItemList = [
      {
        id: 'TOTAL',
        label: '',
        value: total
      }
    ];
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }
}
