import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateft',
    pure: false
})
export class DateFormatPipe implements PipeTransform {
    transform(value: any, pattern: string): any {
        if (!value) { return value; }

        const dateValue = value instanceof Date  ? value : new Date(value);

        return moment(dateValue, pattern).format(pattern);
    }
}

