import {Directive, Input, } from "@angular/core";
import {EntityUtil} from "../service/entity.util";

@Directive({selector: '[sidebarTabs]', exportAs: 'sidebarTabs'})
export class SidebarTabsDirective {

    private _sidebarTabs: string;
    private _defaultTab: string;
    private _index: number;

    get sidebarTabs(): string {
        return this._sidebarTabs;
    }

    @Input()
    set sidebarTabs(value: string) {
        this._sidebarTabs = value;
        this._defaultTab = value;
        this._index = 0;
    }

    switchTab(tabName: string, index?: number) {
        this._sidebarTabs = tabName;

        if (EntityUtil.isPresent(index)) {
            this._index = index;
        }
    }

    get activeTab(): string {
        return this._sidebarTabs;
    }

    get index(): number {
        return this._index;
    }

    reset() {
        this._sidebarTabs = this._defaultTab;
        this._index = 0;
    }
}