import { Component, Input } from '@angular/core';
import { BadgeColor } from '../../models/badge-color.model';

@Component({
  selector: 'vl-badge',
  template: `
    <div [tooltip]="tooltipText" class="vl-badge vl-badge--{{ getBadgeColor() }}">
      {{ text }}
    </div>
  `,
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() text: string;
  @Input() tooltipText: string;
  @Input() color: BadgeColor = BadgeColor.RED;

  getBadgeColor(): string {
    return BadgeColor[this.color].toLowerCase();
  }
}
