import {Injectable} from "@angular/core";

import {Observable, throwError} from "rxjs";
import {shareReplay} from "rxjs/operators";
import {BaseHttpService} from '@veloce/shared';

@Injectable()
export class UserProfileRepository {

    user: Observable<any>;

    constructor(
        private httpService: BaseHttpService
    ) { }

    getUser(): Observable<any> {
        if (!this.user) {
            this.user = this.httpService.api({
                url: '/user',
                errorHandler: (error) => {
                    return throwError(new Error('Unable to get authenticated user details'));
                }
            }).pipe(
                shareReplay(1)
            );
        }

        return this.user;
    }
}
