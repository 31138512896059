import * as _ from 'lodash';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { merge, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { SortDirection, SortPayload } from '../../model/sorting';
import { GridSortService } from '../../modules/shared/services/grid-sort.service';
import { EntityUtil } from '../../service/entity.util';
import { TableSortbableColumnDirective } from '../table-sortable-column/table-sortable-column.directive';

@Directive({
  selector: '[filtered-table]',
  exportAs: 'filteredTable'
})
export class TableFilterDirective implements AfterViewInit, OnDestroy {
  @HostBinding('class.hovered')
  public isHovering: boolean;

  private onDestroy = new Subject<void>();
  private sortState = {};

  @Output() onFilterUpdate = new EventEmitter<any>();
  @Output() onSortUpdate = new EventEmitter<SortPayload>();

  @ContentChildren(forwardRef(() => ColFilterComponent))
  columnFilters: QueryList<ColFilterComponent>;

  @ContentChildren(forwardRef(() => TableSortbableColumnDirective))
  columnSort: QueryList<TableSortbableColumnDirective>;

  tableFilter: Map<string, string> = new Map<string, string>();

  constructor(public sortService: GridSortService) {}

  private initSortSubscription() {
    const subscriptions = this.columnSort.map(({ sortDirection$ }) => sortDirection$);

    merge(...subscriptions)
      .pipe(
        map(sort => {
          const sortState = {
            ...this.sortState,
            ...sort
          };

          this.sortState = _.omitBy(sortState, direction => direction === SortDirection.None);

          return this.sortState;
        }),
        takeUntil(this.onDestroy)
      )
      .subscribe((sorting: SortPayload) => this.onSortUpdate.emit(sorting));
  }

  ngAfterViewInit(): void {
    this.columnFilters.forEach((columnFilter, index) => {
      columnFilter.onKeyUp.subscribe(this.updateFilter.bind(this));
    });

    this.initSortSubscription();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  updateFilter(columnFilter: Map<string, string>) {
    columnFilter.forEach((value, key) => {
      this.tableFilter.set(key, value);
    });

    let filter: Map<string, string> = new Map();

    this.tableFilter.forEach((value, key) => {
      filter.set(key, value);
    });

    this.onFilterUpdate.emit(filter);
  }

  resetFilters(tableFilter?: Map<string, string>) {
    this.columnFilters.forEach((columnFilter, index) => {
      if (tableFilter && tableFilter.has(columnFilter.filteredColumn)) {
        columnFilter.searchString = tableFilter.get(columnFilter.filteredColumn);
      } else {
        columnFilter.searchString = '';
      }
    });
  }

  resetSort() {
    this.columnSort.forEach(directive => {
      directive.reset();
    });
    this.sortState = {};
  }
}

@Component({
  selector: '[filteredColumn]',
  templateUrl: 'table-filter.directive.html'
})
export class ColFilterComponent implements OnInit {
  private _filteredColumn: string;
  private _keyUpDebouncer: Subject<any> = new Subject();
  private criteria: Map<string, string> = new Map();

  searchString: string = '';
  onKeyUp = new EventEmitter<Map<string, string>>();

  @ViewChild('colDropdown') colDropdown: BsDropdownDirective;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('filterIcon') filterIcon: ElementRef;

  @Input()
  set filteredColumn(value: string) {
    this._filteredColumn = value;
  }

  get filteredColumn(): string {
    return this._filteredColumn;
  }

  @HostBinding('class.active-search')
  get isActive() {
    return !EntityUtil.isEmpty(this.searchString);
  }

  @HostListener('click', ['$event'])
  public handleClick(event) {
    if (event.target === this.filterIcon.nativeElement) {
      event.stopPropagation();
    }

    this.toggle();
  }

  @HostListener('mouseleave', ['$event'])
  public handleMouseleave(event) {
    event.stopPropagation();
  }

  handleInputClick(event: any) {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this._keyUpDebouncer.pipe(debounceTime(500)).subscribe(searchString => {
      this.criteria.set(this._filteredColumn, searchString);
      this.onKeyUp.emit(this.criteria);
    });
  }

  clearSearch(event?: any) {
    if (event) {
      event.stopPropagation();
    }
    this.searchString = '';
    this.emit(this.searchString);
  }

  handleKeyUp(event: any) {
    let searchText = _.trim(this.searchString || '');

    if (searchText.length < 1) {
      this.clearSearch(event);
      return;
    }

    this.emit(searchText);
  }

  private toggle() {
    if (this.colDropdown.isOpen) {
      this.colDropdown.hide();
    } else {
      this.colDropdown.show();
      this.searchInput.nativeElement.focus();
    }
  }

  private emit(searchString: string) {
    this._keyUpDebouncer.next(searchString);
  }
}
