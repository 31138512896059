import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorDialogService {
  errorMethodCallSource = new Subject<string>();
  errorMethodCalled$ = this.errorMethodCallSource.asObservable();

  showError(error: string): void {
    this.errorMethodCallSource.next(error);
  }
}
