import { orderBy } from 'lodash';
import { Injectable } from '@angular/core';
import { SortPayload } from '../../../model/sorting';

@Injectable()
export class GridSortService {
    public sort<T>(rows: T[], sortable: SortPayload): T[] {
        const sortFields = Object.keys(sortable);
        const sortDirections = Object.values(sortable);

        return orderBy(rows, sortFields, sortDirections);
    }
}
