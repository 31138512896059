import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';

@Injectable()
export class AdminUserGuard implements CanLoad {
  constructor(
    private router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (window.ADMIN_CONTEXT) {
      return true;
    }

    return this.reject();
  }

  private reject(): boolean {
    this.router.navigate(['/configuration/404']);

    return false;
  }
}
