import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: '[validation-messages]',
    templateUrl: 'validation.directive.html'
})
export class ValidationDirective {
    @Input() showForPristine: boolean = false;
    @Input() form: FormGroup;
    @Input() controlId: string;

    isHidden() {
        let hidden;
        const control = this.form.controls[this.controlId];

        if (this.showForPristine) {
            hidden = control.valid;
        } else {
            hidden = true;
        }

        return hidden;
    }
}
