import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConditionFilterService } from './service/condition.filter.service';
import { Filter, FilterableType, FilterCondition } from '../../../models';

@Component({
  selector: 'vl-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent {
  @Input() filters: Filter[] = [];
  @Input() set defaultFilters(filters: Filter[]) {
    for (const filter of filters) {
      this.createFilter(filter);
    }
  }

  @Output() filtersUpdated = new EventEmitter<Filter[]>();

  constructor(private searchService: ConditionFilterService) {}

  get nonInputFilters(): Filter[] {
    return this.filters.filter(({ filterable: { type } }) => type !== FilterableType.INPUT);
  }

  get inputFilter(): Filter {
    return this.filters.find(({ filterable: { type } }) => type === FilterableType.INPUT);
  }

  search<T>(unfilteredArray: T[], filterConditions: FilterCondition[]): T[] {
    return this.searchService.search(unfilteredArray, filterConditions);
  }

  upsertFilter(filter: Filter, index?: number): void {
    if (filter.filterable.type === FilterableType.INPUT) {
      this.updateInputFilter(filter);
    } else if (index === null || index === undefined) {
      this.createFilter(filter);
    } else {
      this.updateFilter(filter, index);
    }

    this.emitUpdate();
  }

  removeFilter(index: number): void {
    this.filters = this.filters.filter((filter, idx) => idx !== index);
    this.emitUpdate();
  }

  private createFilter(filter: Filter): void {
    this.filters = [...this.filters, filter];
  }

  private updateFilter(filter: Filter, index: number): void {
    this.filters = this.filters.map((f, idx) => (index === idx ? filter : f));
  }

  private updateInputFilter(filter: Filter): void {
    this.filters = [...this.filters.filter(({ filterable: { type } }) => type !== FilterableType.INPUT)];

    if (filter.condition.value && filter.condition.value.length > 0) {
      this.filters = [...this.filters, filter];
    }
  }

  private emitUpdate(): void {
    this.filtersUpdated.emit(this.filters);
  }
}
