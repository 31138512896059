import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { ProductModel, ProductModelsContainer } from '../model/product.model';
import { BaseHttpService } from '@veloce/shared';
import { ModelTranslatorUtil as Translator } from './model.translator.util';
import { ModelUtils } from './product.model.utils';
import { shareReplay, map } from 'rxjs/operators';

@Injectable()
export class ProductModelCache {
  private serviceUrl: string = '/models';

  constructor(private httpService: BaseHttpService) {}

  private cachedModel: Map<string, Observable<ProductModelsContainer>> = new Map();

  getProductModel(currentModelId: string, version?: string): Observable<ProductModelsContainer> {
    if (!this.cachedModel.has(currentModelId) || version) {
      this.clearCache();

      this.cachedModel.set(currentModelId, this.load(currentModelId, version).pipe(shareReplay(1)));
    }

    return this.cachedModel.get(currentModelId);
  }

  getCachedModel(): Observable<ProductModelsContainer> {
    let ids = Array.from(this.cachedModel.keys());

    return ids && ids.length > 0 && this.cachedModel.get(ids[0]);
  }

  clearCache() {
    this.cachedModel.clear();
  }

  private load(id: string, version?: string): Observable<ProductModelsContainer> {
    let mainModel = this.httpService.api({
      url: version ? `${this.serviceUrl}/${id}/versions/${version}` : `${this.serviceUrl}/${id}`
    });

    let linkedModels = this.httpService
      .api({
        url: version ? `${this.serviceUrl}/${id}/versions/${version}/linked` : `${this.serviceUrl}/${id}/linked`
      })
      .pipe(
        map(result => result || []),
        map((linkedModels: ProductModel[]) => {
          if (linkedModels) {
            linkedModels.forEach(model => Translator.toLocalModel(model));
          }
          return linkedModels;
        })
      );

    return forkJoin([mainModel, linkedModels]).pipe(
      map(r => {
        let container = new ProductModelsContainer();
        container.main = r[0];
        container.linked = r[1] && r[1].length > 0 ? r[1] : [];

        ModelUtils.override(container.main, container.linked);
        Translator.toLocalModel(container.main, container.linked);

        return container;
      })
    );
  }
}
