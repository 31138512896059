import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminUserGuard } from './guards/admin-user-guard.service';
import { AccountsModule } from './modules/accounts/accounts.module';
import { ApprovalAdminModule } from './modules/approval-admin/approval-admin.module';
import { CatalogAdminModule } from './modules/catalog-admin/catalog-admin.module';
import { CatalogModule } from './modules/catalog/catalog.module';
import { DocGenStandaloneModule } from './modules/doc-gen-standalone/doc-gen-standalone.module';
import { DynamicPagesModule } from './modules/dynamic-pages/dynamic-pages.module';
import { ECommerceModule } from './modules/e-commerce/e-commerce.module';
import { OfferingsModule } from './modules/offerings/offerings.module';
import { PriceListModule } from './modules/price-list/price-list.module';
import { ProductModelModule } from './modules/product-model/product-model.module';
import { SettingsModule } from './modules/settings/settings.module';
import { UiTemplatesModule } from './modules/ui-templates/ui-templates.module';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'models' },
  {
    path: 'models',
    loadChildren: (): Promise<ProductModelModule> =>
      import('./modules/product-model/product-model.module').then(m => m.ProductModelModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'price-lists',
    loadChildren: (): Promise<PriceListModule> =>
      import('./modules/price-list/price-list.module').then(m => m.PriceListModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'catalogs',
    loadChildren: (): Promise<CatalogAdminModule> =>
      import('./modules/catalog-admin/catalog-admin.module').then(m => m.CatalogAdminModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'settings',
    loadChildren: (): Promise<SettingsModule> =>
      import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'configuration',
    loadChildren: (): Promise<CatalogModule> => import('./modules/catalog/catalog.module').then(m => m.CatalogModule),
    canLoad: ['AuthGuard']
  },
  {
    path: 'e-commerce',
    loadChildren: (): Promise<ECommerceModule> =>
      import('./modules/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'accounts',
    loadChildren: (): Promise<AccountsModule> =>
      import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'offerings',
    loadChildren: (): Promise<OfferingsModule> =>
      import('./modules/offerings/offerings.module').then(m => m.OfferingsModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'ui-templates',
    loadChildren: (): Promise<UiTemplatesModule> =>
      import('./modules/ui-templates/ui-templates.module').then(m => m.UiTemplatesModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'dynamic-pages',
    loadChildren: (): Promise<DynamicPagesModule> =>
      import('./modules/dynamic-pages/dynamic-pages.module').then(m => m.DynamicPagesModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  },
  {
    path: 'doc-gen',
    loadChildren: (): Promise<DocGenStandaloneModule> =>
      import('./modules/doc-gen-standalone/doc-gen-standalone.module').then(m => m.DocGenStandaloneModule)
  },
  {
    path: 'approval-admin',
    loadChildren: (): Promise<ApprovalAdminModule> =>
      import('./modules/approval-admin/approval-admin.module').then(m => m.ApprovalAdminModule),
    canLoad: ['AuthGuard', AdminUserGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
