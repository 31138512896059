import { Component, Input } from '@angular/core';
import { ApprovalItem } from '../../models/line-item.dto.model';

@Component({
  selector: 'vl-quote-level-warning',
  templateUrl: './quote-level-warning.component.html',
  styleUrls: ['./quote-level-warning.component.scss']
})
export class QuoteLevelWarningComponent {
  @Input() approvalItems: ApprovalItem[];

  selectionPluralMap = {
    '=1': '# item',
    other: '# items'
  };
}
