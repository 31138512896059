import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';
import { TableColumnConfigDirective } from './directives/table-column-config.directive';

@Component({
  selector: 'vl-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  @Input() dataSource: unknown[];
  @Input() layout: 'auto' | 'fixed' = 'fixed';
  @Input() isHeaderHidden: boolean;
  @Input() trackByFn: (i: number, el: unknown) => unknown;

  @ContentChildren(TableColumnConfigDirective) columnConfigs: QueryList<TableColumnConfigDirective>;
  @ContentChild('tableRow', { read: TemplateRef }) tableRowTpl: TemplateRef<any>;

  getColumnWidth(clmn: TableColumnConfigDirective): string {
    if (clmn.width) {
      return `${clmn.width}px`;
    }

    if (this.columnConfigs.some(c => !!c.width)) {
      return null;
    }

    const widthShare = 100 / this.columnConfigs.reduce((acc, i) => (acc += i.weight || 1), 0);

    return (clmn.weight ? clmn.weight * widthShare : widthShare) + '%';
  }

  trackByIndex(index: number, _: any): number {
    return index;
  }

  columnTrackByFn(index: number, _: TableColumnConfigDirective): number {
    return index;
  }
}
