import { CurrencyStateTotalPrices } from './current-state-item.model';

export enum PriceAdjustmentType {
  DISCOUNT_PERCENT = 'DISCOUNT_PERCENT',
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  MARKUP_PERCENT = 'MARKUP_PERCENT',
  MARKUP_AMOUNT = 'MARKUP_AMOUNT',
  OVERRIDE_AMOUNT = 'OVERRIDE_AMOUNT'
}

export enum ActionCode {
  ADD = 'ADD',
  DELETE = 'DELETE',
  EXIST = 'EXIST',
  UPDATE = 'UPDATE',
  RENEW = 'RENEW',
  OPTION = 'OPTION'
}

export enum FrequencyUnit {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY'
}

export enum ChargeMethod {
  Recurring = 'RECURRING',
  OneTime = 'ONE_TIME'
}

// TODO: make it not partial
export interface PartialChargeGroupItem {
  id: string;
  chargeMethod: ChargeMethod;
  netPrice: number;
  frequencyUnit?: FrequencyUnit;
  frequencyDuration?: number;
}

// TODO: make it not partial
export interface PartialLineItem {
  chargeGroupItems: PartialChargeGroupItem[];
  endDate: string;
  id: string;
  planId: string;
  qty: number;
  sellingFrequencyDuration: number;
  sellingFrequencyUnit: FrequencyUnit;
  startDate: string;
  term: number;
}

export interface ApprovalItem {
  id: string;
  quoteId: string;
  active: boolean;
  status: string;
  code: number;
  type: string;
  reason: string;
  workflow: string;
  value: string;
  lineItemId: string;
  chargeType: string;
}

interface LineItemProperties {
  '#productName': string;
  '#modelId': string;
  '#configurable': string;
  '#displayUrl': string;
  '#modelName': string;
  '#productId': string;
  [key: string]: string;
}

export interface LineItemAttribute {
  name: string;
  type: string;
  value: number | string;
  cfgStatus: string;
}

export interface ChargeGroupItem {
  chargeType: string;
  chargeMethod: ChargeMethod;
  frequencyUnit: FrequencyUnit;
  frequencyDuration: number;
  sellingTerm: number;
  listPrice: number;
  netPrice: number;
  id: string;
  enablePriceAdjustment: boolean;
  chargeTypeDisplayValue: string;
  tiers: any[];
}

interface PriceWaterfall {
  type: string;
  amount: number;
  explanation: string;
}

interface PriceAdjustment {
  type: PriceAdjustmentType;
  amount: number;
  explanation: string;
}

export interface ChargeItem {
  id: string;
  chargeId: string;
  chargeType: string;
  chargeMethod: string;
  baseListPrice: number;
  baseListUnitPrice: number;
  price: number;
  unitPrice: number;
  listPrice: number;
  listUnitPrice: number;
  netPrice: number;
  netUnitPrice: number;
  quantity: number;
  startDate: string;
  endDate: string;
  frequencyUnit: FrequencyUnit;
  frequencyDuration: number;
  sellingTerm: number;
  priceWaterfalls: PriceWaterfall[];
  priceItemId: string;
  chargeTypeDisplayValue: string;
  enablePriceAdjustment: boolean;
  enableListPriceAdjustment: boolean;
  priceAdjustment: PriceAdjustment;
}

export interface DomainType {
  name: string;
  displayName: string;
  minCard: number;
  maxCard: number;
  cardinality: number;
  cfgStatus: string;
  properties: { [propName: string]: string };
  recommendedPrices: unknown[];
}

export interface PortDomain {
  name: string;
  type: string;
  minCard: number;
  maxCard: number;
  domainTypes: DomainType[];
}

export class LineItem {
  actionCode: ActionCode;
  assetId: string;
  attributeDomains: { [key: string]: unknown[] };
  attributes: LineItemAttribute[];
  bundle: boolean;
  cfgStatus: string;
  chargeGroupItems: ChargeGroupItem[];
  chargeItems: ChargeItem[];
  endDate: string;
  id: string;
  lineItems: LineItem[];
  messages: string[] = [];
  name: string;
  optionalCharges: unknown[];
  parentId: string;
  path: unknown;
  planId: string;
  port: string;
  portDomains: { [key: string]: PortDomain };
  productId: string;
  productName: string;
  properties: LineItemProperties;
  qty: number;
  rootId: string;
  proratedFrequencyEnabled?: boolean;
  sellingFrequencyDuration: string;
  sellingFrequencyUnit: FrequencyUnit;
  serviceStartDate: Date;
  startDate: string;
  totalDiscounts: unknown[];
  totalPrices: {
    [key: string]: CurrencyStateTotalPrices;
  };
  type: string;
  configurable?: boolean;
  domainComputations?: { [index: string]: boolean };
  enableRamp?: boolean;
  offeringId?: string;
  offeringInstanceId?: string;
  offeringItemId?: string;
  rampInstanceId?: string;
}
