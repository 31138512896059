import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DataSelectorItem } from './table-configuration.model';

interface TableConfig {
  items: DataSelectorItem[];
  settings: DataSelectorItem[];
}

@Component({
  selector: 'vd-table-configuration',
  templateUrl: './table-configuration.component.html',
  styleUrls: ['./table-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableConfigurationComponent implements OnInit, OnDestroy {
  @Input() tableConfig: TableConfig;

  readonly dragulaGroup = 'items';
  transformedData: DataSelectorItem[];
  isDisabled = true;

  dataItems: TableConfig['items'];
  settingItems: TableConfig['settings'];

  @Output() private configSave = new EventEmitter<TableConfig>();
  @Output() private configReset = new EventEmitter<void>();
  @Output() private closePanel = new EventEmitter<void>();

  @ViewChild('mirrorContainerRef', { static: true }) private mirrorContainerRef: ElementRef;

  private onDestroy = new Subject<void>();

  constructor(private dragulaService: DragulaService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const { items = [], settings = [] } = this.tableConfig;
    this.transformedData = items.map(item => ({ ...item }));
    this.settingItems = settings;

    this.dragulaService.createGroup(this.dragulaGroup, {
      direction: 'vertical',
      mirrorContainer: this.mirrorContainerRef.nativeElement
    });

    this.dragulaService
      .dropModel()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(({ targetModel: sortedDataItems }) => {
        this.transformedData = sortedDataItems;
        this.isDisabled = false;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.dragulaService.destroy(this.dragulaGroup);
  }

  toggle(item: DataSelectorItem, isChecked: boolean): void {
    this.transformedData = this.transformedData.map(transformItem => {
      return item.id === transformItem.id ? { ...transformItem, isChecked } : transformItem;
    });
    this.isDisabled = false;
  }

  toggleSetting(item: DataSelectorItem, isChecked: boolean): void {
    this.settingItems = this.settingItems.map(settingItem => {
      return settingItem.id === item.id ? { ...settingItem, isChecked } : settingItem;
    });
    this.isDisabled = false;
  }

  close(): void {
    this.closePanel.emit();
  }

  trackById = (_: number, { id }: DataSelectorItem): string => id;

  resetToDefault(): void {
    this.configReset.emit();
  }

  saveConfiguration(): void {
    this.configSave.emit({ items: this.transformedData, settings: this.settingItems });
  }
}
