import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DropdownItem } from '@veloce/components';
import { AdminFormField, DocumentTemplateSettingType } from '../../models';

@Component({
  selector: 'vd-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminFormComponent {
  @Input() fields: AdminFormField[];

  DocumentTemplateSettingType = DocumentTemplateSettingType;

  onMenuItemSelect(item: DropdownItem, dropdownControl: AbstractControl): void {
    dropdownControl.setValue(item.id);
  }

  onFileUpload(event: any, field: AdminFormField): void {
    if (event.target.files.length) {
      const [file] = event.target.files;

      field.formControl.setValue(file);
      field.fileNameControl.setValue(file.name);
    }
  }

  onCheckboxToggle({ checked }: HTMLInputElement, checkboxControl: AbstractControl): void {
    checkboxControl.setValue(checked);
  }

  trackById = (_: number, { id }: AdminFormField): string => id;

  markFileControlAsTouched(field: AdminFormField): void {
    field.formControl.markAsTouched();
  }

  hasError({ type, formControl, fileNameControl }: AdminFormField): boolean {
    if (type === DocumentTemplateSettingType.FILE) {
      return formControl.touched && fileNameControl.invalid;
    }
    return formControl.touched && formControl.invalid;
  }
}
