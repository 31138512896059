import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityUtil } from '../service/entity.util';

@Component({
    selector: 'td.more-cell',
    templateUrl: 'lists.more.directive.html'
})
export class ListsMoreComponent {
    @Input() list: any;
    @Input() index: any;
    @Input() cloneable: boolean = false;
    @Output() onRemove = new EventEmitter<any>();
    @Output() onClone = new EventEmitter<any>();

    //Prevent modal to be triggered
    public handleClick(event: any) {
        event.stopPropagation();
    }

    public remove(): void {
        let fn = this._remove;

        if (this.list && (this.index || this.index > -1)) {
            this._remove(this.list, this.index);
        } else {
            this.onRemove.emit(fn);
        }
    }

    private _remove(list: any, index: number) {
        if (Array.isArray(list)) {
            return list.splice(index, 1)[0];
        } else {
            let deleted = list[index];
            delete list[index];
            return deleted;
        }
    }

    public clone(): void {
        let fn = this._clone;

        if (this.list && (this.index || this.index > -1)) {
            this._clone(this.list, this.index);
        } else {
            this.onClone.emit(fn);
        }
    }

    private _clone(list: any, index: any) {
        let cloned = EntityUtil.clone(list[index]);
        let name = cloned.name;
        if (name) {
            cloned.name = name = 'Copy_Of_' + name;
        }
        if (Array.isArray(list)) {
            let i = -1;
            while (name && list.findIndex(e => e.name === name) > -1) {
                i = i + 1;
                name = cloned.name + '_' + i;
            }
            cloned.name = name;
            list.splice(index, 0, cloned);
        } else {
            list['Copy_Of_' + index] = cloned;
        }
        return cloned;
    }
}
