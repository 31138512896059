import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize, PageChangedEvent } from '../../models';
import { PagedEntity } from '../../utils';

@Component({
  selector: 'vl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() isDisabled: boolean;
  @Input() alignment: 'default' | 'center' = 'default';
  @Input() pagedItems: PagedEntity<any>;

  ButtonSize = ButtonSize;

  @Output() private readonly pageChanged = new EventEmitter<PageChangedEvent>();

  nextPage(): void {
    if (this.pagedItems.hasNext()) {
      this.emitPageChanged(this.pagedItems.getNext());
    }
  }

  prevPage(): void {
    if (this.pagedItems.hasPrev()) {
      this.emitPageChanged(this.pagedItems.getPrev());
    }
  }

  emitPageChanged(newPage: number): void {
    this.pageChanged.emit({
      page: newPage
    });
  }
}
