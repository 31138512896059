import { Type } from '../model/type.model';
import { ProductModel } from '../model/product.model';
import { UIDefinition } from '../../runtime/model/uiDefinition.model';
import { EntityUtil } from '../../../service/entity.util';
import { Container, ContainerFactory } from '../../runtime/model/container.model';
import { Attribute } from '../model/attribute.model';
import { Section } from '../../runtime/mockups/model/section.model';
import { UITab } from '../../runtime/mockups/model/ui-tab.model';
import { ModelUtils } from './product.model.utils';

export class ModelTranslatorUtil {
  static toLocalModel(productModel: ProductModel, linked?: ProductModel[]): void {
    let types: Type[] = productModel.types;

    let linkedTypes: Type[] = linked ? ModelUtils.collectTypes(linked) : [];

    types.forEach(type => {
      type.id = type.name;
      type.modelId = productModel.id;
      ModelTranslatorUtil.toLocalType(type, types, linkedTypes);
    });

    productModel.uiDefinitions = productModel.uiDefinitionsSource ? JSON.parse(productModel.uiDefinitionsSource) : [];

    productModel.uiDefinitions.forEach(uiDefinition => {
      ModelTranslatorUtil.toLocalUIDefinition(uiDefinition);
    });

    productModel.externals.forEach((external: Attribute) => {
      external.id = external.name;
    });

    productModel.constants.forEach((constant: Attribute) => {
      constant.id = constant.name;
    });
  }

  static toLocalType(type: Type, types: Type[], linkedTypes: Type[]) {
    if (type.parent) {
      type.parentType = types.filter(t => t.name === type.parent)[0];
      if (!type.parentType) {
        type.parentType = linkedTypes.find(t => t.name === type.parent);
      }
    } else {
      let obj = {};
      obj['empty'] = '';

      type.parentType = obj['empty']; //hack to have default empty values set in html selects
    }

    if (type.requireRules) {
      type.requireRules.forEach(requireRule => {
        if (requireRule.component && requireRule.component.type) {
          requireRule.component.componentType = types.filter(t => t.name === requireRule.component.type)[0];
        }
      });
    }

    type.ports.forEach(port => {
      port.id = port.name;

      if (port.type) {
        port.portType = types.filter(t => t.name === port.type)[0];
        if (!port.portType) {
          port.portType = linkedTypes.find(t => t.name === port.type);
        }
      }

      if (port.defaultComponents) {
        port.defaultComponents.forEach(component => {
          if (component.type) {
            component.componentType = types.filter(t => t.name === component.type)[0];
          }
        });
      }

      port.hostType = type.id;
    });
  }

  static toServerModel(productModel: ProductModel): ProductModel {
    productModel.types.forEach(type => {
      delete type['id'];

      if (type.parentType) {
        type.parent = type.parentType.name;
      } else {
        type.parent = null;
      }

      delete type['parentType'];

      if (type.requireRules) {
        type.requireRules.forEach(requireRule => {
          if (requireRule.component && requireRule.component.componentType) {
            requireRule.component.type = requireRule.component.componentType.name;

            delete requireRule.component['componentType'];
          }
        });
      }

      type.ports.forEach(port => {
        delete port['id'];
        delete port['hostType'];

        if (port.portType) {
          port.type = port.portType.name;

          delete port['portType'];
        }

        if (port.attributes) {
          port.attributes.forEach(attribute => {
            delete attribute['primitiveType'];
            delete attribute['precision'];
          });
        }

        if (port.defaultComponents) {
          port.defaultComponents.forEach(component => {
            if (component.componentType) {
              component.type = component.componentType.name;
              delete component['componentType'];
            }
          });
        }
      });
    });

    if (productModel.uiDefinitions?.length === 1) {
      productModel.uiDefinitions = [
        {
          ...productModel.uiDefinitions[0],
          primary: true
        }
      ];
    }

    productModel.uiDefinitions?.forEach(uiDefinition => {
      ModelTranslatorUtil.toServerUIDefinition(uiDefinition);
    });

    productModel.uiDefinitionsSource = JSON.stringify(productModel.uiDefinitions);

    productModel.externals.forEach((external: Attribute) => {
      delete external['id'];
    });

    productModel.constants.forEach((constant: Attribute) => {
      delete constant['id'];
    });

    return productModel;
  }

  static toServerUIDefinition(uiDefinition: UIDefinition) {
    delete uiDefinition['id'];

    if (uiDefinition.containers) {
      uiDefinition.containers.forEach(rootContainer => {
        EntityUtil.traverseTree(rootContainer, container => {
          delete container['close']; //TODO ALE we should get rid from 'close'

          if (container.parent) {
            container.parentId = container.parent.id;

            delete container['parent'];
            delete container['configControl'];
          }
        });
      });
    }
  }

  static toLocalUIDefinition(uiDefinition: UIDefinition) {
    uiDefinition.id = uiDefinition.name;

    let transformFn = function (container: Container) {
      let result = ContainerFactory.createContainer(container.containerType);
      Object.assign(result, container);

      return result;
    };

    let traversalFn = function (container: Container) {
      let result: Container = transformFn.apply(this, [container]);

      if (result.children) {
        result.children.forEach((childContainer, index) => {
          result.children[index] = traversalFn(childContainer);
          result.children[index].parent = result;
        });
      }

      return result;
    };

    if (uiDefinition.containers) {
      uiDefinition.containers.forEach((rootContainer, index) => {
        uiDefinition.containers[index] = traversalFn.apply(this, [rootContainer]);
      });
    }

    if (uiDefinition.sections || (uiDefinition.sections = [])) {
      uiDefinition.sections.forEach((section: Section, index) => {
        let sectionObject = new Section(section.name);

        Object.assign(sectionObject, section);

        uiDefinition.sections[index] = sectionObject;
      });
    }

    if (!uiDefinition.tabs || uiDefinition.tabs.length < 1) {
      uiDefinition.tabs = [new UITab('Page 1')];
    }
  }
}
