import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItem, RampTabAction } from '../../../models';

@Component({
  selector: 'vl-ramp-tab',
  templateUrl: './ramp-tab.component.html',
  styleUrls: ['./ramp-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RampTabComponent {
  @Input() rampTitle: string;
  @Input() productsCount: number;
  @Input() price: number;
  @Input() date: Date;
  @Input() dateFormat = 'MM/dd/yyyy';
  @Input() isActive: boolean;
  @Input() isDisabledMenu: boolean;
  @Input() shouldHideProduct: boolean;
  @Input() shouldHidePrice: boolean;

  @Output() rampActionSelect = new EventEmitter<RampTabAction>();

  actionButtonData: DropdownItem<RampTabAction>[] = [
    { id: RampTabAction.EDIT, value: RampTabAction.EDIT },
    { id: RampTabAction.REMOVE, value: RampTabAction.REMOVE }
  ];

  handleRampAction(item: DropdownItem<RampTabAction>): void {
    if (!this.isDisabledMenu) {
      this.rampActionSelect.emit(item.value);
    }
  }

  handleRampSelection(): void {
    this.rampActionSelect.emit(RampTabAction.SELECT);
  }
}
