import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'vd-content-cell-renderer',
  template: `<div [innerHTML]="content"></div>`
})
export class ContentCellRenderComponent implements ICellRendererAngularComp {
  content: string;

  agInit(params: ICellRendererParams): void {
    this.content = this.getContent(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.content = this.getContent(params);
    return true;
  }

  getContent(params: ICellRendererParams): string {
    const { cellRendererParams } = params.colDef;
    const content = cellRendererParams?.cellContentGetter(params);

    return content;
  }
}
