import { merge, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { DropdownItem } from '@veloce/components';
import { ErrorLevel, MassEditControl, MassEditControlType } from '../models';

interface ActionControl {
  label: string;
  iconClass?: string;
  isOutline?: boolean;
  alwaysActive?: boolean;
}

interface ActionConfig {
  [key: string]: ActionControl;
}

interface ActionResult {
  [key: string]: any;
}

@Component({
  selector: 'vd-mass-edit',
  templateUrl: './mass-edit.component.html',
  styleUrls: ['./mass-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoMassEditComponent implements OnChanges, OnDestroy {
  @Input() selected: number;
  @Input() productControls: MassEditControl[];
  @Input() formGroupValidators?: ValidatorFn[] = [];
  @Input() actionConfig: ActionConfig;
  @Input() isVerticalState: boolean;
  @Input() isBlockLayout: boolean;

  MassEditControlType = MassEditControlType;

  form: FormGroup;
  hasError$: Observable<boolean>;
  ErrorLevel = ErrorLevel;
  formValueChangeSubscription = new Subscription();

  @Output() private readonly actionSubmitted = new EventEmitter<ActionResult>();

  ngOnChanges(changes: SimpleChanges): void {
    const { productControls } = changes;

    if (productControls && productControls.currentValue !== productControls.previousValue) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  initForm(): void {
    if (!this.productControls) {
      this.form = void 0;
      return;
    }

    this.form = new FormGroup({});

    this.productControls.forEach(({ name, formControl }) => {
      this.form.addControl(name, formControl || new FormControl(''));
    });

    if (this.formGroupValidators) {
      this.form.setValidators(this.formGroupValidators);
    }

    this.hasError$ = this.form.statusChanges.pipe(
      map(_ => {
        const hasFormGroupError = this.form.invalid && this.form.errors && this.form.errors[ErrorLevel.ERROR];
        return (
          hasFormGroupError ||
          Object.values(this.form.controls).reduce((hasError, c) => {
            const { errors } = c as FormControl;

            return hasError || (errors && errors[ErrorLevel.ERROR]);
          }, false)
        );
      })
    );

    this.formValueChangeSubscription.unsubscribe();
    this.formValueChangeSubscription = merge(
      ...this.productControls
        .filter(({ onValueChange, formControl }) => !!onValueChange && !!formControl)
        .map(({ formControl, onValueChange }) => {
          return formControl.valueChanges.pipe(map(value => ({ value, onValueChange })));
        })
    ).subscribe(({ value, onValueChange }) => {
      onValueChange(this.form, value);
    });
  }

  onAction(action: string): void {
    this.actionSubmitted.emit({ [action]: this.form?.value });
    if (this.form) {
      this.form.reset();
    }
  }

  onMenuItemReset(control: AbstractControl): void {
    control.reset();
  }

  onMenuItemSelect(dropdownItem: DropdownItem, productControl: MassEditControl): void {
    const value = dropdownItem.id ? dropdownItem.value : null;

    if (productControl.onValueChange) {
      productControl.onValueChange(this.form, value);
    }

    const control = this.form.controls[productControl.name];
    control.setValue(value);
    control.markAsDirty();
  }

  getListOfValues(productControl: MassEditControl): DropdownItem[] {
    return [{ id: null, value: '--' }, ...productControl.lov(this.form.value).map(v => ({ id: v, value: v }))];
  }

  isInvalidControl(controlName: MassEditControl['name'], errorLevel: ErrorLevel, checkDirty: boolean = false): boolean {
    const control = this.form.controls[controlName];

    return (
      control && control.invalid && control.errors && control.errors[errorLevel] && (checkDirty ? control.dirty : true)
    );
  }

  onCheckedChange(control: MassEditControl): void {
    const formControl = this.form.controls[control.name];
    if (formControl) {
      formControl.setValue(!formControl.value);
    }
  }

  trackByReference = (index: number, massEditControl: MassEditControl): MassEditControl => massEditControl;
}
