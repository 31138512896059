import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  OnInit,
} from '@angular/core';
import { Filter, FilterableOp } from './model/filterable.model';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'vl-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filter: Filter;

  inputValue: string;

  private readonly keyUpSubject = new Subject<void>();
  private readonly onDestroy = new Subject<void>();
  @Output() private readonly searchUpdated = new EventEmitter<Filter>();

  ngOnInit(): void {
    this.activateSearch();
  }

  ngOnChanges(): void {
    this.inputValue = this.filter?.condition?.value;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  onKeyUp(): void {
    this.keyUpSubject.next();
  }

  private activateSearch(): void {
    this.keyUpSubject
      .pipe(
        debounceTime(100),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => this.search());
  }

  private search(): void {
    const searchString = (this.inputValue || '').trim();

    const filter = {
      ...new Filter(this.filter.filterable),
      condition: {
        key: this.filter.filterable.name,
        value: searchString,
        operator: FilterableOp.LIKE
      }
    };

    this.searchUpdated.emit(filter);
  }
}
