import { FormControl } from '@angular/forms';
import { DropdownItem } from '@veloce/components';

export enum DocumentTemplateSettingType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  FILE = 'FILE',
  CHECKBOX = 'CHECKBOX',
  TEXTAREA = 'TEXTAREA'
}

export class TemplateQuery {
  id?: string;
  queryName: string;
  resultObjectName: string;
  objectName: string;
  fields: string[] = [];
  statement: string;
}

export interface TemplateProperty {
  id: string;
  name: string;
  label: string;
  value: string;
  type?: DocumentTemplateSettingType;
  lov?: string[];
}

export class DocumentTemplate {
  id: string;
  fileName: string;
  name: string;
  script: string;
  queries: TemplateQuery[] = [];

  constructor(public properties: TemplateProperty[] = []) {}
}

export interface AdminFormField {
  id: string;
  label: string;
  type: string;
  fileNameControl?: FormControl;
  isReadonly?: boolean;
  formControl: FormControl;
  lov?: DropdownItem[];
}
