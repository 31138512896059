import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ListItem, OutputDiscountData, WidgetAction } from '../../models';
import { ChargeItemId, PartialChargeItem } from './charge-widget.model';

@Component({
  selector: 'vl-charge-widget',
  templateUrl: './charge-widget.component.html',
  styleUrls: ['./charge-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargeWidgetComponent implements OnChanges {
  @Input() chargeItem: PartialChargeItem;
  @Input() isReadonly: boolean;

  ChargeItemId = ChargeItemId;
  headerItemList: ListItem[];
  itemList: ListItem<ChargeItemId>[];
  selectedChargeItemId: ChargeItemId;

  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();
  @Output() private handleListPriceDiscountChange = new EventEmitter<OutputDiscountData>();
  @Output() private handleNetPriceOverride = new EventEmitter<number>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges({ chargeItem }: SimpleChanges): void {
    const { currentValue } = chargeItem;
    const { sellingTerm, listPrice, listUnitPrice, netPrice, netUnitPrice } = currentValue as PartialChargeItem;

    this.headerItemList = [
      {
        id: 'SELLING_TERM',
        label: 'Selling term',
        value: sellingTerm
      }
    ];
    this.itemList = [
      {
        id: ChargeItemId.LIST_PRICE,
        label: 'List Price',
        value: listPrice,
        isClickable: true
      },
      {
        id: ChargeItemId.NET_PRICE,
        label: 'Net Price',
        value: netPrice,
        isClickable: true
      },
      {
        id: ChargeItemId.RECURRING_PRICE,
        label: 'Recurring Price',
        value: this.getProratedPrice(listPrice, sellingTerm),
        isClickable: false
      },
      {
        id: ChargeItemId.NET_RECURRING_PRICE,
        label: 'Net Recurring Price',
        value: this.getProratedPrice(netPrice, sellingTerm),
        isClickable: false
      },
      {
        id: ChargeItemId.UNIT_PRICE,
        label: 'Unit Price',
        value: listUnitPrice,
        isClickable: false
      },
      {
        id: ChargeItemId.NET_UNIT_PRICE,
        label: 'Net Unit Price',
        value: netUnitPrice,
        isClickable: false
      }
    ];
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }

  onChargeItemSelect(chargeItemId: ChargeItemId): void {
    this.selectChargeItem(chargeItemId);
  }

  onListPriceDiscountChange(discountData: OutputDiscountData): void {
    this.selectChargeItem(undefined);
    this.handleListPriceDiscountChange.emit(discountData);
  }

  onNetPriceOverride(amount: number): void {
    this.selectChargeItem(undefined);
    this.handleNetPriceOverride.emit(amount);
  }

  private selectChargeItem(chargeItemId: ChargeItemId): void {
    const selectedItem = this.itemList.find(item => item.id === chargeItemId);
    if (!selectedItem || !selectedItem.isClickable) {
      return;
    }

    this.selectedChargeItemId = selectedItem.id;
    this.cdr.markForCheck();
  }

  private getProratedPrice(price: number, sellingTerm: number): number {
    return sellingTerm ? price / sellingTerm : price;
  }
}
