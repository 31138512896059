import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dictionary } from '../../model/dictionary.model';
import { MessageService } from '@veloce/shared';

@Component({
    selector: '.add-property',
    templateUrl: 'add-property.component.html'
})
export class AddPropertyComponent implements OnInit {
    readonly messageBucketId = 'dictionary.properties';

    @Input() title: string;
    @Input() dictionary: Dictionary;
    @Input() keyLabel: string = 'Name';
    @Input() valueLabel: string = 'Value';
    @Output() onSave = new EventEmitter<any>();
    @Output() onCancel = new EventEmitter<any>();

    form: FormGroup;
    mode: string = 'Add';

    constructor(private formBuilder: FormBuilder, private messageService: MessageService) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            id: '',
            name: ['', Validators.required],
            value: ['', Validators.required]
        });
    }

    save(formValue: any) {
        if (formValue.id) {
            delete this.dictionary[formValue.id];
        }

        this.dictionary[formValue.name] = formValue.value;
        this.messageService.clearMsg(this.messageBucketId);
        this.onSave.emit(this.dictionary);
    }

    cancel() {
        this.messageService.clearMsg(this.messageBucketId);
        this.onCancel.emit();
    }

    initForm(key?: any) {
        this.mode = 'Add';
        this.form.reset();

        if (key) {
            this.form.controls['id'].setValue(key);
            this.form.controls['name'].setValue(key);
            this.form.controls['value'].setValue(this.dictionary[key]);

            this.mode = 'Edit';
        }
    }
}
