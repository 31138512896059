import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export const handleValidationError = (error: HttpResponse<any> | any) => throwError(() => {
  const err = error.message || JSON.stringify(error);
  return `${err}`;
});

export const extractErrorMessage = (err: HttpErrorResponse) => {
  let errMsg: string = 'Server error occurred';
  if (err.error) {
    errMsg = `${(err.error.message && ' ' + err.error.message) || ''} ${
      (err.error.exception && ' ' + err.error.exception) || ''
    }  ${err.status}`;
  } else if (err.message) {
    errMsg = `${err.message}`;
  }
  return errMsg;
}
