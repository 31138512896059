import {UUID} from "../../../service/uuid.service";
import {ConfigControl} from "./config.control.model";
import {UIControl} from "./ui.control.model";
import {Traversable} from "./traversable.model";
import {Selectable} from "./selectable.model";

export class Container implements Traversable<Container>, Selectable {
    id: string;

    label: string;

    parentId: string;

    parent: Container;

    children: Container[] = [];

    uiControl: UIControl;

    configControl: ConfigControl;

    order: number;

    containerType: string;

    typeValue: string;

    visible: boolean;

    abstractContainer: boolean = false;

    custom: boolean;

    cssClass: string;

    constructor() {
        this.id = UUID.UUID();
    }

    addChild(child: Container): void {
        child.parent = this;
        this.children.push(child);
    }

    addChildAtIndex(child: Container, index?: number): void {
        child.parent = this;

        this.children.splice(index && (index || this.children.length), 0, child);
    }

    addChildren(children: Container[]): void {
        if (children) {
            children.forEach(child => {
                this.addChild(child);
            });
        }
    }

    get path(): string {
        if (this.uiControl && this.uiControl.path) {
            return this.uiControl.path.toLowerCase();
        }

        return '';
    }

    get controlType(): string {
        if (this.uiControl && this.uiControl.controlType) {
            return this.uiControl.controlType;
        }

        return '';
    }

    set controlType(value: string) {
        if (this.uiControl && this.uiControl.controlType) {
            this.uiControl.controlType = value;
        }
    }
}

export class AttributeContainer extends Container {
    declaration: string;

    constructor() {
        super();
        this.containerType = 'ATTRIBUTE';
    }
}

export class PortContainer extends Container {
    constructor() {
        super();
        this.containerType = 'PORT';
    }
}

export class GroupContainer extends Container {
    constructor() {
        super();
        this.containerType = 'GROUP';
    }
}

export class ContainerFactory {

    static createContainer(containerType: string): Container {
        if (containerType === "ATTRIBUTE") {
            return new AttributeContainer();
        } else if (containerType === "PORT") {
            return new PortContainer();
        } else if (containerType === "GROUP") {
            return new GroupContainer();
        }

        return new Container();
    }
}
