import { Cookie } from 'ng2-cookies';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { apiBaseUrl } from '@veloce/shared';
import { MessageService } from '@veloce/shared';
import { UserProfileService } from '../../service/user.profile.service';

@Injectable()
export class AuthService {
    private readonly serviceUrl: string = '/auth';
    readonly tokenCookie = 'token';

    constructor(
        private http: HttpClient,
        private router: Router,
        private userProfileService: UserProfileService,
        private messageService: MessageService
    ) {}

    login(username: string, password: string, messageBucketId: string) {
        let body = new HttpParams({
            fromObject: {
                username: username,
                password: password
            }
        });

        return this.http.post(apiBaseUrl + this.serviceUrl + '/login', body).pipe(
            map(response => {
                let data = response || {};
                if (data['token']) {
                    Cookie.set(this.tokenCookie, data['token']);
                    this.toggleLogState(username);

                    this.messageService.clearMsg(messageBucketId);
                } else {
                    throw new Error('Token not found');
                }
            }),
            catchError((error: any) => {
                this.messageService.publishMsg('Incorrect email or password', messageBucketId);
                this.logout();

                return of(null);
            })
        );
    }

    signup(fullName: string, username: string, password: string, messageBucketId: string) {
        let body = new HttpParams({
            fromObject: {
                fullName: fullName,
                username: username,
                password: password
            }
        });

        return this.http.post(apiBaseUrl + this.serviceUrl + '/signup', body).pipe(
            map(response => {
                let data = response || {};

                this.messageService.clearMsg(messageBucketId);

                return data['message'];
            }),
            catchError((error: HttpErrorResponse) => {
                let errMsg: string = 'Server error occurred';

                if (error.error) {
                    errMsg = `${(error.error.message && ' ' + error.error.message) || ''}`;
                } else if (error.message) {
                    errMsg = `${error.message}`;
                }

                this.messageService.publishMsg(errMsg, messageBucketId);

                return of(null);
            })
        );
    }

    reset(username: string, messageBucketId: string) {
        let body = new HttpParams({
            fromObject: {
                username: username
            }
        });

        return this.http.post(apiBaseUrl + this.serviceUrl + '/reset', body).pipe(
            map(response => {
                let data = response || {};

                this.messageService.clearMsg(messageBucketId);

                return data['message'];
            }),
            catchError((error: any) => {
                this.messageService.publishMsg(this.extractErrorMessage(error), messageBucketId);

                return of(null);
            })
        );
    }

    changePassword(password: string, confirmKey: string, messageBucketId: string) {
        let body = new HttpParams({
            fromObject: {
                password: password,
                confirmKey: confirmKey
            }
        });

        return this.http.post(apiBaseUrl + this.serviceUrl + '/update-password', body).pipe(
            map(response => {
                let data = response || {};

                this.messageService.clearMsg(messageBucketId);

                return data['message'];
            }),
            catchError((error: any) => {
                let errMsg = this.extractErrorMessage(error);

                this.messageService.publishMsg(errMsg, messageBucketId);

                return throwError(errMsg);
            })
        );
    }

    logout() {
        Cookie.delete(this.tokenCookie);
        this.toggleLogState(null);
        this.router.navigate(['/login']);
    }

    isLoggedIn(): Observable<boolean> {
        return this.userProfileService.getUser().pipe(
            map(user => {
                return true;
            }),
            catchError((err: Error) => {
                return of(false);
            })
        );
    }

    private toggleLogState(username: string) {
        this.userProfileService.loggedInUser = username;
    }

    private extractErrorMessage(errorResponse: HttpErrorResponse): string {
        let errMsg: string = 'Server error occurred';

        if (errorResponse.error) {
            errMsg = `${(errorResponse.error.message && ' ' + errorResponse.error.message) || ''}`;
        } else if (errorResponse.message) {
            errMsg = `${errorResponse.message}`;
        }

        return errMsg;
    }
}
