import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DropdownComponent, DropdownItem, TypeaheadItem } from '@veloce/components';
import { EditorType, ErrorLevel } from '../../../models';

interface TypeAheadConfig {
  debounceTime: number;
  asyncItemGetter: (value: string) => Promise<TypeaheadItem[]>;
}

interface DatePickerConfig {
  dateFormat: string;
}

interface DropdownConfig {
  menuItems: DropdownItem[];
  autoDropup?: boolean;
  dropdownHeight?: number;
}

@Component({
  selector: 'vd-text-cell-editor',
  templateUrl: './text-cell-editor.component.html',
  styleUrls: ['./text-cell-editor.component.scss']
})
export class TextCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  @ViewChild('input', { read: ViewContainerRef, static: false }) input: ViewContainerRef;
  @ViewChild('dropdown', { read: DropdownComponent, static: false }) dropdown: DropdownComponent;

  formControl: FormControl;

  readonly errorClass = 'vd-cell--error';
  readonly warningClass = 'vd-cell--warning';

  EditorType = EditorType;
  inputType: EditorType;

  typeAheadConfig: TypeAheadConfig;
  datePickerConfig: DatePickerConfig;
  dropdownConfig: DropdownConfig;
  dropdownSelectedItem: DropdownItem;

  private hasError = false;
  private params: ICellEditorParams;
  private destroy$ = new Subject<void>();

  agInit(params: ICellEditorParams): void {
    const { eGridCell, colDef, value } = params;
    let { cellEditorParams } = colDef;

    cellEditorParams = typeof cellEditorParams === 'function' ? cellEditorParams(params) : cellEditorParams;

    this.params = params;
    this.formControl = new FormControl(value || '');
    this.inputType = cellEditorParams?.type || EditorType.TEXT;

    switch (this.inputType) {
      case EditorType.TYPEAHEAD:
        this.typeAheadConfig = cellEditorParams.typeAheadConfig;
        break;
      case EditorType.DATEPICKER:
        this.datePickerConfig = cellEditorParams.datePickerConfig;
        break;
      case EditorType.DROPDOWN:
        const { dropdownConfig } = cellEditorParams;
        this.dropdownConfig = typeof dropdownConfig === 'function' ? dropdownConfig(params) : dropdownConfig;
        this.dropdownSelectedItem = this.dropdownConfig.menuItems.find(item => item.value === this.formControl.value);
        break;
    }

    if (!(this.params as any).skipValidation && cellEditorParams?.validators) {
      this.formControl.setValidators(cellEditorParams.validators);

      this.formControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => {
        if (!eGridCell) {
          return;
        }
        eGridCell.classList.remove(this.errorClass);
        eGridCell.classList.remove(this.warningClass);
        this.hasError = false;

        if (this.formControl.invalid) {
          if (this.formControl.errors[ErrorLevel.ERROR]) {
            eGridCell.classList.add(this.errorClass);
            this.hasError = true;
          } else if (this.formControl.errors[ErrorLevel.WARNING]) {
            eGridCell.classList.add(this.warningClass);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.input) {
        this.input.element.nativeElement.focus();
      }
      if (this.dropdown) {
        this.dropdown.show();
      }
    });
  }

  onBlur(): void {
    this.stopEditing();
  }

  onTextAreaKeyDown(e: KeyboardEvent): void {
    if (this.inputType === EditorType.TEXTAREA && e.code === 'Enter' && e.shiftKey) {
      e.stopPropagation();
    }
  }

  onTypeAheadItemSelect(item: TypeaheadItem): void {
    this.formControl.setValue(item.value);
    this.stopEditing();
  }

  onDateSelect(): void {
    this.stopEditing();
  }

  onDropdownValueChange(item: DropdownItem): void {
    this.formControl.setValue(item.value);
    this.stopEditing();
  }

  getValue(): string {
    return this.formControl.value;
  }

  isCancelAfterEnd(): boolean {
    return this.hasError;
  }

  private stopEditing(): void {
    this.params.api.stopEditing(this.hasError);
  }
}
