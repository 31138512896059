import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Feature, FeaturesService } from '../../../service/features.service';
import { MonacoEditorConfig, MonacoEditorService } from '../../../service/monaco-editor.service';

@Component({
  selector: 'root-navigation',
  templateUrl: 'root-navigation.component.html'
})
export class RootNavigationComponent implements OnInit {
  @Input() showRightSidebar: boolean;
  @Input() agentView: boolean;

  isSidebarOpen = true;
  isTransitionNeeded = false;

  isSafe = environment.isSafe;

  features = this.featuresService.features;
  feature = Feature;

  editorConfig: MonacoEditorConfig;

  constructor(
    private featuresService: FeaturesService,
    private cdr: ChangeDetectorRef,
    private monacoService: MonacoEditorService
  ) {}

  ngOnInit(): void {
    this.featuresService.init();

    this.featuresService.initComplete.on('initComplete', () => {
      this.features = this.featuresService.features;
      this.cdr.detectChanges();
    });

    this.monacoService.editorConfigChange.subscribe(config => {
      this.editorConfig = config;
    });
  }

  onRightSidebarToggle(): void {
    this.isTransitionNeeded = true;
    this.isSidebarOpen = !this.isSidebarOpen;

    setTimeout(() => {
      this.isTransitionNeeded = false;
    }, 300);
  }

  onEditorClose(editor): void {
    const value = editor.getValue();
    this.monacoService.closeEditor(value);
  }
}
