import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OptionComponent } from './components/option/option.component';

@NgModule({
  declarations: [OptionComponent],
  imports: [CommonModule],
  exports: [OptionComponent]
})
export class GartnerComponentsModule {}
