import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vd-view-base',
  styleUrls: ['view-base.component.scss'],
  templateUrl: 'view-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewComponent {
  @Input() titleText: string;
}
