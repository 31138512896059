import { Subject } from 'rxjs';
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { SortDirection, SortPayload } from '../../model/sorting';

const DEFAULT_DIRECTIONS = [SortDirection.Ascending, SortDirection.Descending, SortDirection.None];

@Directive({
    selector: '[sortedColumn]'
})
export class TableSortbableColumnDirective {
    @Input() private readonly sortedColumn: string;

    private sortDirection = new Subject<SortPayload>();
    private sortDirections = [...DEFAULT_DIRECTIONS];

    public direction: SortDirection;
    public sortDirection$ = this.sortDirection.asObservable();

    @HostListener('click')
    public handleClick() {
        this.direction = this.sortDirections[0];

        this.sortDirection.next({
            [this.sortedColumn]: this.direction
        });
        this.sortDirections.shift();
        this.sortDirections.push(this.direction);
    }

    public reset(): void {
        this.sortDirections = [...DEFAULT_DIRECTIONS];
        this.direction = SortDirection.None;
    }

    @HostBinding('class.sort-asc')
    get isAsc(): boolean {
        return this.direction === SortDirection.Ascending;
    }

    @HostBinding('class.sort-dsc')
    get isDsc(): boolean {
        return this.direction === SortDirection.Descending;
    }
}
