import iziToast from 'izitoast';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum ToastType {
  Error = 1,
  Warning,
  Success,
  Info
}

interface ToastSettings {
  isCompact?: boolean;
  alignment?: 'left' | 'center' | 'right';
  timeout?: number;
}

const toastTypeClassMap = {
  [ToastType.Error]: 'vd-toast--error',
  [ToastType.Warning]: 'vd-toast--warning',
  [ToastType.Success]: 'vd-toast--success',
  [ToastType.Info]: 'vd-toast--info'
};

@Component({
  selector: 'vd-toast',
  styleUrls: ['toast.component.scss'],
  templateUrl: 'toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent implements OnInit {
  @Input() toasterConfig?: ToastSettings;

  @Output() private toastInitialized = new EventEmitter<unknown>();

  ngOnInit(): void {
    iziToast.settings({
      animateInside: false,
      closeOnClick: true,
      drag: false,
      progressBar: false,
      target: '#toasterAnchor',
      timeout: isNaN(this.toasterConfig?.timeout) ? 3000 : this.toasterConfig.timeout
    });

    this.toastInitialized.emit({
      error: this.toastError.bind(this),
      warning: this.toastWarning.bind(this),
      success: this.toastSuccess.bind(this),
      info: this.toastInfo.bind(this)
    });
  }

  private toast(message: string, className: string): void {
    iziToast.show({ message, class: className });
  }

  private toastError(message: string): void {
    this.toast(message, toastTypeClassMap[ToastType.Error]);
  }

  private toastWarning(message: string): void {
    this.toast(message, toastTypeClassMap[ToastType.Warning]);
  }

  private toastSuccess(message: string): void {
    this.toast(message, toastTypeClassMap[ToastType.Success]);
  }

  private toastInfo(message: string): void {
    this.toast(message, toastTypeClassMap[ToastType.Info]);
  }

  get toastClassMap(): { [key: string]: boolean } {
    return {
      'iziToast--compact': this.toasterConfig?.isCompact,
      [`iziToast-alignment--${this.toasterConfig?.alignment}`]: !!this.toasterConfig?.alignment
    };
  }
}
