import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TypeaheadItem } from '../../models';

type FeatureType =
  | 'country'
  | 'region'
  | 'postcode'
  | 'district'
  | 'place'
  | 'locality'
  | 'neighborhood'
  | 'address'
  | 'poi';
type Coordinates = [number, number];

interface Context {
  id: string;
  text: string;
  wikidata?: string;
  short_code?: string;
}

interface Feature {
  id: string;
  place_name: string;
  context: Context[];
  address?: string;
  text: string;
  center?: Coordinates;
  geometry: { type: string; coordinates: Coordinates };
}

interface PlacesResponse {
  attribution: string;
  features: Feature[];
  query: string[];
  type: string;
}

@Component({
  selector: 'vl-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSearchComponent implements OnChanges {
  @Input() label: string;
  @Input() control: FormControl = new FormControl('');
  @Input() isMandatory: boolean;
  @Input() countryCodes: string[] = []; // ISO 3166-1 alpha-2 country codes
  @Input() suggestionTypes: FeatureType[] = [];
  @Input() debounceTime: number = 200;
  @Input() minChars: number = 3;
  @Input() isDropup: false;

  itemsGetter: (searchString: string) => Promise<TypeaheadItem[]>;

  @Output() private itemSelect = new EventEmitter<TypeaheadItem>();

  private readonly ACCESS_TOKEN =
    'pk.eyJ1IjoibGJsaW5vdiIsImEiOiJjazIxeWhucXQwM3V3M2VwZ3JxcDVyc3B3In0.XXJ9bP4h5O9-Nrxt2dCtWg';

  constructor(private http: HttpClient) {}

  ngOnChanges(): void {
    this.itemsGetter = (searchString: string) => {
      const url = new URL(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchString}.json`);
      url.searchParams.append('access_token', this.ACCESS_TOKEN);
      if (this.countryCodes.length) {
        url.searchParams.append('country', this.countryCodes.join(','));
      }
      if (this.suggestionTypes.length) {
        url.searchParams.append('types', this.suggestionTypes.join(','));
      }
      url.searchParams.append('limit', '10');

      return this.http
        .get<PlacesResponse>(url.href)
        .pipe(
          map(res =>
            res.features.map(f => {
              return { id: f.id, value: f.place_name, data: f };
            })
          ),
          catchError(() => [])
        )
        .toPromise();
    };
  }

  onTypeAheadItemSelect(item: TypeaheadItem): void {
    const { context, address, text, center, geometry } = item.data as Feature;

    this.itemSelect.emit({
      ...item,
      data: {
        ...context.reduce((acc, ctx) => {
          const [ctxType] = ctx.id.split('.');
          return { ...acc, [ctxType]: ctx };
        }, {}),
        address: `${address ? `${address} ` : ''}${text || ''}`,
        coordinates: geometry ? geometry.coordinates : center
      }
    });
  }
}
