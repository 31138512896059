import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'card-menu',
  templateUrl: 'card-menu.component.html',
  styleUrls: ['card-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardMenuComponent {
  @Output() onClickRename = new EventEmitter<void>();
  @Output() onClickRemove = new EventEmitter<void>();
}
