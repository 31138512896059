import { Pipe, PipeTransform } from '@angular/core';

const removeDiacritics = (value: string): string => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

@Pipe({
  name: 'highlightIntersection'
})
export class HighlightIntersectionPipe implements PipeTransform {
  transform(originalValue: string, searchValue: string): string {
    const normalizedSearchValue = removeDiacritics(searchValue).trim().toLowerCase();

    if (!normalizedSearchValue) {
      return originalValue;
    }

    const normalizedValue = removeDiacritics(originalValue).toLowerCase();

    let searchFromPos = 0;

    const resultStringChunks: string[] = [];

    while (true) {
      const stringStart = normalizedValue.indexOf(normalizedSearchValue, searchFromPos);

      if (stringStart === -1) {
        resultStringChunks.push(originalValue.slice(searchFromPos));
        break;
      }

      const stringEnd = stringStart + normalizedSearchValue.length;
      resultStringChunks.push(
        originalValue.slice(searchFromPos, stringStart) +
          '<strong>' +
          originalValue.slice(stringStart, stringEnd) +
          '</strong>'
      );

      searchFromPos = stringEnd;
    }

    return resultStringChunks.join('');
  }
}
