import { FilterableOp, FilterCondition } from '../model/filterable.model';
import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConditionFilterService {
  search<T extends { [index: string]: any }>(unfilteredArray: T[] = [], filterConditions: FilterCondition[] = []): T[] {
    if (unfilteredArray.length < 1 || filterConditions.length < 1) {
      return unfilteredArray;
    }

    const conditionsByName: { [key: string]: FilterCondition[] } = groupBy(filterConditions, 'key');

    return Object.values(conditionsByName).reduce((sum, conditions) => {
      conditions.forEach((condition: FilterCondition) => {
        const filterByOperator = this.getFilterByOperator(condition);
        sum = sum.filter(filterByOperator);
      });

      return sum;
    }, unfilteredArray);
  }

  private getFilterByOperator<T extends { [index: string]: any }>(condition: FilterCondition): (item: T) => boolean {
    const { operator, key, value } = condition;

    switch (operator) {
      case FilterableOp.EQ:
        return (item: T) => {
          const itemValue = `/${item[key]}/`;
          const conditionValue = `/${value}/`;
          return RegExp(conditionValue, 'i').test(itemValue);
        };
      case FilterableOp.NE:
        return (item: T) => {
          const itemValue = `/${item[key]}/`;
          const conditionValue = `/${value}/`;
          return !RegExp(conditionValue, 'i').test(itemValue);
        };

      case FilterableOp.LIKE:
        return (item: T) => new RegExp(value, 'i').test(item[key]);

      case FilterableOp.SAME:
        return (item: T) => this.toMoment(item[key]).isSame(this.toMoment(value));

      case FilterableOp.AF:
        return (item: T) => this.toMoment(item[key]).isAfter(this.toMoment(value));

      case FilterableOp.BF:
        return (item: T) => this.toMoment(item[key]).isBefore(this.toMoment(value));

      case FilterableOp.EA:
        return (item: T) => this.toMoment(item[key]).isSameOrAfter(this.toMoment(value));

      case FilterableOp.EB:
        return (item: T) => this.toMoment(item[key]).isSameOrBefore(this.toMoment(value));
      default:
        throw new Error(`${operator} is not supported`);
    }
  }

  private toMoment(date: string): moment.Moment {
    return moment(date).startOf('day');
  }
}
