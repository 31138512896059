import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseHttpService } from './baseHttp.service';

export interface ConfigurationSettings {
  id: string;
  key: string;
  value: string;
}

@Injectable()
export class ConfigurationSettingsRepository {
  constructor(private baseHttpService: BaseHttpService) {}

  fetchSettings(settingsKey: string): Observable<ConfigurationSettings> {
    return this.baseHttpService
      .api({
        url: `/configuration-settings/byKey/${settingsKey}`,
        method: 'get'
      })
      .pipe(map(response => response ?? { value: null }));
  }

  upsertSettings(settings: ConfigurationSettings): Observable<void> {
    return this.baseHttpService.api({
      url: `/configuration-settings/${settings}`,
      method: 'post',
      body: {
        value: JSON.stringify(settings)
      }
    });
  }
}
