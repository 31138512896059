import { Observable, Subject } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { EntityUtil } from '../../../../service/entity.util';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
    private readonly defaultMessage = 'Are you sure?';
    private subj: Subject<boolean> = new Subject<boolean>();

    @ViewChild(ModalDirective, { static: true }) public confirmModal: ModalDirective;

    public message: string;

    constructor(private cdr: ChangeDetectorRef) {}

    public showModal(message?: string): Observable<boolean> {
        this.message = EntityUtil.isBlank(message) ? this.defaultMessage : message;
        this.cdr.detectChanges();
        this.confirmModal.show();

        return this.subj.asObservable();
    }

    public yes(): void {
        this.confirmModal.hide();

        this.subj.next(true);
    }

    public no(): void {
        this.confirmModal.hide();

        this.subj.next(false);
    }
}
