import {Dictionary} from "../../../model/dictionary.model";
import {Annotated} from "./annotated.model";

export class Attribute implements Annotated {
    public id: string;
    public name: string;
    public declaration: string;
    public primitiveType: string;
    public precision: number;
    public annotations: Dictionary = {};

    static newInstance(name: string, id?: string, declaration?: string, primitiveType?: string, precision?: number): Attribute {
        let attribute = new Attribute();

        attribute.name = name;
        attribute.id = id;
        attribute.declaration = declaration;
        attribute.primitiveType = primitiveType;
        attribute.precision = precision;

        return attribute;
    }
}

export const AttributeDomainTypes = {
    INTERVALS: 'Intervals',
    STATEMENT: 'Statement',
    PROXY: 'Proxy',
}