import { Injectable, EventEmitter } from '@angular/core';
import { Language, Theme } from '@veloce/components';

export interface MonacoEditorConfig {
  language: Language;
  theme: Theme;
  content: string;
  label: string;
}

@Injectable()
export class MonacoEditorService {
  editorConfigChange = new EventEmitter<MonacoEditorConfig>();
  editorDataChange = new EventEmitter<string>();

  openEditor(config: MonacoEditorConfig): void {
    this.editorConfigChange.emit(config);
  }

  closeEditor(data: string): void {
    this.editorConfigChange.emit(null);
    this.editorDataChange.emit(data);
  }
}
