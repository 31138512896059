import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSize, VeloceListAddedItem, VeloceListProduct, VeloceListView } from '../../models';

@Component({
  selector: 'vl-veloce-list',
  templateUrl: './veloce-list.component.html',
  styleUrls: ['./veloce-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VeloceListComponent {
  @Input() lineItems: VeloceListProduct[];
  @Input() listView: VeloceListView;
  @Input() disabledItems: string[] = [];
  @Input() textOnButton: string = 'Add to cart';

  view = VeloceListView;
  ButtonSize = ButtonSize;

  @Output() private addToCart = new EventEmitter<VeloceListAddedItem>();
  @Output() private config = new EventEmitter<VeloceListProduct>();
  @Output() private selectItem = new EventEmitter<string>();

  onAddToCart(quantity: string, item: VeloceListProduct): void {
    this.addToCart.emit({
      item,
      quantity: Number(quantity)
    });
  }

  onConfigClick(item: VeloceListProduct): void {
    this.config.emit(item);
  }

  onNameClick(id: string): void {
    this.selectItem.emit(id);
  }

  isDisabled(id: string): boolean {
    return this.disabledItems.includes(id);
  }

  trackById = (_: number, { id }: VeloceListProduct): string => id;
}
