import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigurationContext } from '../model/configuration-context.model';

@Injectable({ providedIn: 'root' })
export class ContextStoreService {
  private context = new BehaviorSubject<ConfigurationContext>(null);

  resolve = (): ConfigurationContext => this.context.value && ({ ...this.context.value });
  resolve$ = (): Observable<ConfigurationContext> => this.context;
  delete = (): void => this.context.next(null);

  resolveOrCreate(): ConfigurationContext {
    if (!this.context.value) {
      this.context.next(new ConfigurationContext());
    }

    return this.resolve();
  }

  update(context: ConfigurationContext): void {
    this.context.next({
      ...new ConfigurationContext(),
      ...context
    });
  }

  patch(partialContext: Partial<ConfigurationContext>): ConfigurationContext {
    const originalContext = this.context.value ? { ...this.context.value } : new ConfigurationContext();
    const newContext = {
      ...merge(originalContext, partialContext),
      properties: this.mergeWithCaseSensitivity([originalContext.properties ?? {}, partialContext.properties ?? {}])
    };
    this.context.next(newContext);
    return this.resolve();
  }

  mergeWithCaseSensitivity(args: any[]) {
    return args.reduce((acc, item) => {
      return Object.entries(item).reduce((trunk, [key, value]) => {
        const targetField = Object.keys(trunk).find(field => field.toLowerCase() === key.toLowerCase());

        if (targetField) {
          delete trunk[targetField];
        }

        return {
          ...trunk,
          [key]: value
        };
      }, acc);
    }, {});
  }
}
