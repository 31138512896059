import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ChargeMethod, LineItem, RampTabAction } from '../../../models';
import { PagedEntity } from '../../../utils';

@Component({
  selector: 'vl-ramp-tabs',
  templateUrl: './ramp-tabs.component.html',
  styleUrls: ['./ramp-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RampTabsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() isDisabled: string;
  @Output() rampActionSelect = new EventEmitter<[RampTabAction, string]>();

  @ViewChild('rampTabsElement') rampTabsElement: ElementRef;

  tabs: LineItem[];
  pagedTabs: PagedEntity<LineItem>;
  rampTabsContainerWidth: number;
  showControls: boolean;
  activeRampId: string;

  private readonly rampWidth = 220;
  private resizeDebounce: Subject<void> = new Subject();
  private readonly unsubscribe = new Subject<void>();
  private readonly bounceTime = 300;

  constructor(private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef) {}

  @Input() set rampTabs(value: LineItem[]) {
    const [{ id }] = value;

    this.activeRampId = id;
    this.tabs = value;
    this.pagedTabs = new PagedEntity<LineItem>(this.tabs, this.calculateCurrentPage(), 1);
  }

  ngOnInit(): void {
    this.resizeDebounce.pipe(debounceTime(this.bounceTime), takeUntil(this.unsubscribe)).subscribe(() => {
      this.handleWindowResize();
    });
  }

  ngAfterViewChecked(): void {
    this.updateControlsVisibility();
    this.shift();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  handlePrevClick(): void {
    this.pagedTabs.prevPage();

    this.shift();
  }

  handleNextClick(): void {
    this.pagedTabs.nextPage();

    this.shift();
  }

  @HostListener('window:resize') onWindowResize(): void {
    this.resizeDebounce.next();
  }

  handleRampActionSelect(action: RampTabAction, id: string): void {
    if (RampTabAction.SELECT === action) {
      this.activate(id);

      return;
    }

    this.rampActionSelect.emit([action, id]);
  }

  calculatePrice({ totalPrices }: LineItem): number {
    return Object.values(totalPrices)
      .filter(({ chargeMethod }) => chargeMethod === ChargeMethod.Recurring)
      .reduce((total, { netPrice }) => total + netPrice, 0);
  }

  trackById(_: number, ramp: LineItem): string {
    return ramp.id;
  }

  private activate(ramp: string): void {
    this.activeRampId = ramp;
  }

  private handleWindowResize(): void {
    this.updateControlsVisibility();

    const currentPage = this.calculateCurrentPage();
    if (currentPage === 0) {
      this.pagedTabs.resetPage();
      this.shift();
    }
  }

  private shift(): void {
    const indent = this.pagedTabs.page * this.rampWidth * -1;
    this.renderer.setStyle(this.rampTabsElement.nativeElement, 'transform', `translateX(${indent}px)`);
  }

  private calculateCurrentPage(): number {
    let currentPage = (this.pagedTabs && this.pagedTabs.page) || 0;

    if (currentPage !== 0 && this.areControlsVisible()) {
      if (this.pagedTabs.page === this.tabs.length) {
        currentPage = this.pagedTabs.page - 1;
      }
    } else {
      currentPage = 0;
    }

    return currentPage;
  }

  private areControlsVisible(): boolean {
    const rampTabsWidth = this.rampWidth * this.tabs.length;

    return rampTabsWidth > this.rampTabsContainerWidth;
  }

  private updateControlsVisibility(): void {
    this.rampTabsContainerWidth = this.rampTabsElement.nativeElement.getBoundingClientRect().width;
    this.showControls = this.areControlsVisible();

    this.changeDetectorRef.detectChanges();
  }
}
