import { Pipe, PipeTransform } from '@angular/core';
import { PriceAdjustmentType } from '../models/line-item.model';

@Pipe({
  name: 'priceAdjustment'
})
export class PriceAdjustmentPipe implements PipeTransform {
  transform(value: number, [priceAdjustmentType]: [PriceAdjustmentType]): string {
    switch (priceAdjustmentType) {
      case PriceAdjustmentType.DISCOUNT_PERCENT:
        return `-${value}%`;
      case PriceAdjustmentType.DISCOUNT_AMOUNT:
        return `-$${value}`;
      case PriceAdjustmentType.MARKUP_PERCENT:
        return `+${value}%`;
      case PriceAdjustmentType.MARKUP_AMOUNT:
        return `+$${value}`;
      case PriceAdjustmentType.OVERRIDE_AMOUNT:
        return `$(${value})`;
      default:
        return void 0;
    }
  }
}
