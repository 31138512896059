import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { ButtonSize } from '../../models';

@Component({
  selector: 'vl-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonGroupComponent implements OnDestroy {
  @Input() isOutline?: boolean;
  @Input() isCollapsible?: boolean;
  @Input() isCollapsed?: boolean;
  @Input() buttonGroupSize?: ButtonSize = ButtonSize.Default;

  isMenuOpened = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    window.document.removeEventListener('click', this.closeMenu);
  }

  toggleMenu(event: MouseEvent): void {
    event.stopPropagation();

    if (this.isMenuOpened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  private closeMenu = (): void => {
    this.isMenuOpened = false;
    this.cdr.markForCheck();
  };

  private openMenu(): void {
    this.isMenuOpened = true;
    window.document.addEventListener('click', this.closeMenu, { once: true });
  }

  get buttonGroupClassMap(): { [key: string]: boolean } {
    return {
      'vl-button-group--outline': this.isOutline,
      [`vl-button-group--${this.buttonGroupSize}`]: true
    };
  }
}
