import { Injectable } from '@angular/core';
import { Condition } from '../model/criteria/condition.model';
import { Expression } from '../model/criteria/expression.model';
import { EntityUtil } from './entity.util';

@Injectable({
    providedIn: 'root'
})
export class ExpressionFilterService {
    public searchLineItems(unfilteredArray: any[], expression?: Expression) {
        if (EntityUtil.isEmpty(unfilteredArray)) {
            return unfilteredArray;
        }

        if (!expression || !expression.conditions) {
            return unfilteredArray;
        }

        let byName = expression.conditions.reduce((sum, current) => {
            sum[current.key] = [...(sum[current.key] || []), current];
            return sum;
        }, {});

        let searchResult = Object.keys(byName).reduce((sum, key) => {
            let conditions = byName[key];

            conditions.forEach(condition => {
                let filterByOperator = this.getFilterByOperator(condition);
                sum = sum.filter(filterByOperator);
            });

            return sum;
        }, unfilteredArray);

        return searchResult;
    }

    private getFilterByOperator(condition: Condition) {
        let resultFn;

        switch (condition.operator) {
            case 'EQ':
                resultFn = item => {
                    let itemValue = `/${item[condition.key]}/`;
                    let conditionValue = `/${condition.value}/`;
                    return new RegExp(conditionValue, 'i').test(itemValue);
                };
                break;
            case 'NE':
                resultFn = item => {
                    let itemValue = `/${item[condition.key]}/`;
                    let conditionValue = `/${condition.value}/`;
                    return !new RegExp(conditionValue, 'i').test(itemValue);
                };
                break;
            case 'LIKE':
                resultFn = item => new RegExp(condition.value, 'i').test(item[condition.key]);
                break;
            default:
                throw new Error(`${condition.operator} is not supported`);
                break;
        }

        return resultFn;
    }
}
