import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[vlTableColumnConfig]'
})
export class TableColumnConfigDirective {
  @Input() weight: number;
  @Input() width: number;
  @Input() align: 'left' | 'center' | 'right';
  @ContentChild('tableHeaderCell', { read: TemplateRef }) columnTplRef: TemplateRef<any>;
  @ContentChild('tableCell', { read: TemplateRef }) cellTplRef: TemplateRef<any>;
}
