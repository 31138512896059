import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'vl-form-slider',
  templateUrl: './form-slider.component.html',
  styleUrls: ['./form-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSliderComponent {
  @Input() label: string;
  @Input() inputControl: FormControl;

  @Output() private checkedChange = new EventEmitter<void>();

  onSliderClick(): void {
    this.inputControl.markAsTouched();
    this.inputControl.setValue(!this.inputControl.value);

    this.checkedChange.emit();
  }

  get isInputInvalid(): boolean {
    const { touched, invalid } = this.inputControl;

    return touched && invalid;
  }
}
