import * as moment from 'moment';

export const convertDateToGmtZone = date => {
  if (!date) {
    return;
  }
  const timeZoneOffset = date.getTimezoneOffset();
  return timeZoneOffset < 0
    ? moment(date).subtract(Math.abs(timeZoneOffset), 'minutes').toDate()
    : moment(date).add(Math.abs(timeZoneOffset), 'minutes').toDate();
}
