import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandler } from './directives/global-error-handler';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { VeloceElementsModule } from '@veloce/elements';
import { ErrorDialogService } from './error-dialog/error-dialog.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [ErrorDialogComponent],
  imports: [CommonModule, VeloceElementsModule, SharedModule],
  providers: [ErrorDialogService, { provide: ErrorHandler, useClass: GlobalErrorHandler }],
  exports: [ErrorDialogComponent]
})
export class ErrorHandlerModule {}
