import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartSettings, ConfigurationContext, DropdownItem, WidgetAction } from '../../models';

interface PriceList {
  id: string;
  name: string;
  description?: string;
}

@Component({
  selector: 'vl-cart-settings-widget',
  templateUrl: 'cart-settings-widget.component.html',
  styleUrls: ['cart-settings-widget.component.scss']
})
export class CartSettingsWidgetComponent implements OnInit {
  @Input() settings: ConfigurationContext;
  @Input() priceLists: PriceList[];

  form: FormGroup;
  priceListMenu: DropdownItem[];

  @Output() private handleWidgetActionSelect = new EventEmitter<WidgetAction>();
  @Output() private settingsChange = new EventEmitter<CartSettings>();
  @Output() private settingsDiscard = new EventEmitter<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const { startDate, priceListId } = this.settings;
    const { name } = this.priceLists.find(({ id }) => priceListId === id);

    this.priceListMenu = this.priceLists.map(({ id, name: value }) => ({ id, value }));
    this.initForm({
      priceListId,
      startDate,
      name
    });
  }

  onWidgetActionSelect(action: WidgetAction): void {
    this.handleWidgetActionSelect.emit(action);
  }

  onPriceListChange(item: DropdownItem): void {
    const priceListId = item.id;
    const { name } = this.priceLists.find(({ id }) => priceListId === id);

    this.form.setValue({
      ...this.form.value,
      priceListId,
      name
    });
  }

  onDiscard(): void {
    this.settingsDiscard.emit();
  }

  onApply(): void {
    const { value } = this.form;

    this.settingsChange.emit(value);
  }

  private initForm({ priceListId, startDate, name }: CartSettings): void {
    this.form = this.formBuilder.group({
      priceListId: [priceListId, Validators.required],
      startDate: [startDate, Validators.required],
      name: [name, Validators.required]
    });
  }
}
