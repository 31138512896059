import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApprovalItem, ConfigurationContext, HeaderBlock, HeaderItem, PriceSummaryItem } from '../../models';

interface PriceList {
  id: string;
  name: string;
}

interface TotalPrices {
  netPrice: number;
  listPrice: number;
}

@Component({
  selector: 'vl-shopping-cart-header',
  templateUrl: './shopping-cart-header.component.html',
  styleUrls: ['./shopping-cart-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartHeaderComponent implements OnInit {
  @Input() configurationContext: ConfigurationContext;
  @Input() priceList: PriceList;
  @Input() totalPrices: PriceSummaryItem[];
  @Input() approvalItems: ApprovalItem[];
  @Input() isReadonly: boolean = false;
  @Input() isCollapsed: boolean = false;
  @Input() selectedBlock: HeaderBlock;

  infoBlockSource: HeaderItem[];
  totalBlockSource: HeaderItem[];
  quoteStatusBlockSource: HeaderItem;
  HeaderBlock = HeaderBlock;

  @Output() private blockSelect = new EventEmitter<HeaderBlock>();

  ngOnInit(): void {
    const totalRecurringPrices = this.getTotalRecurringItems(this.totalPrices);
    const totalOneTimePrices = this.getTotalOneTimeItems(this.totalPrices);
    const hasApprovalItems = this.approvalItems && this.approvalItems.length > 0;

    this.infoBlockSource = [
      {
        id: 'startDay',
        caption: 'Effective start day',
        type: 'date',
        value: this.configurationContext ? this.configurationContext.startDate : void 0
      },
      {
        id: 'priceList',
        caption: 'Price list',
        type: 'string',
        value: this.priceList ? this.priceList.name : void 0
      }
    ];

    this.totalBlockSource = [
      {
        id: 'oneTimeTotals',
        caption: 'One time totals',
        type: 'currency',
        value: totalOneTimePrices ? totalOneTimePrices.listPrice : void 0,
        netPrice: totalOneTimePrices ? totalOneTimePrices.netPrice : void 0
      },
      {
        id: 'recurringTotals',
        caption: 'Recurring totals',
        type: 'currency',
        value: totalRecurringPrices ? totalRecurringPrices.listPrice : void 0,
        netPrice: totalRecurringPrices ? totalRecurringPrices.netPrice : void 0
      }
    ];

    this.quoteStatusBlockSource = hasApprovalItems && {
      id: 'approvalRequired',
      caption: 'Quote Status',
      type: 'text',
      value: 'Approval required'
    };
  }

  onBlockSelect(block: HeaderBlock): void {
    if (this.isReadonly) {
      return;
    }

    this.blockSelect.emit(block);
  }

  trackById(_: number, { id }: HeaderItem): string {
    return id;
  }

  private getTotalByChargeMethod(priceSummaryItem: PriceSummaryItem[], chargeMethod: string): TotalPrices {
    return priceSummaryItem
      .filter(item => item.chargeMethod === chargeMethod)
      .reduce(
        (acc: TotalPrices, { netPrice, listPrice }): TotalPrices => ({
          netPrice: acc.netPrice + netPrice,
          listPrice: acc.listPrice + listPrice
        }),
        {
          netPrice: 0,
          listPrice: 0
        }
      );
  }

  private getTotalRecurringItems(priceSummaryItem?: PriceSummaryItem[]): TotalPrices {
    return priceSummaryItem ? this.getTotalByChargeMethod(priceSummaryItem, 'RECURRING') : void 0;
  }

  private getTotalOneTimeItems(priceSummaryItem?: PriceSummaryItem[]): TotalPrices {
    return priceSummaryItem ? this.getTotalByChargeMethod(priceSummaryItem, 'ONE_TIME') : void 0;
  }

  get isInfoBlockSelected(): boolean {
    return this.selectedBlock === HeaderBlock.QUOTE_SETTINGS;
  }

  get isTotalsBlockSelected(): boolean {
    return this.selectedBlock === HeaderBlock.QUOTE_TOTALS;
  }

  get isQuoteStatusBlockSelected(): boolean {
    return this.selectedBlock === HeaderBlock.QUOTE_STATUS;
  }
}
