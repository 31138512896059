import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { convertDateToGmtZone } from '@veloce/shared';

@Component({
  selector: 'vl-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements OnInit, OnChanges, AfterViewInit {
  @HostBinding('attr.datepicker-id') @Input() datepickerId: string;

  @Input() inputControl: AbstractControl;
  @Input() labelText?: string;
  @Input() dateFormat? = 'MM/dd/yyyy';
  @Input() isOpenOnInit? = false;
  @Input() isDisabled?: boolean;

  DatePipe = DatePipe;

  initialDate: Date;
  isViewInit = false;

  isDatepickerVisible: boolean;
  datepickerConfig: Partial<BsDatepickerConfig> = {
    adaptivePosition: true,
    customTodayClass: 'bs-datepicker-today',
    selectFromOtherMonth: true,
    showWeekNumbers: false,
    useUtc: true
  };
  @Output() private valueChange = new EventEmitter<Date>();
  @Output() private toggle = new EventEmitter<boolean>();

  @ViewChild(BsDatepickerDirective) private datepicker: BsDatepickerDirective;

  ngOnInit(): void {
    const date = moment.utc(this.inputControl.value).toDate();
    this.initialDate = convertDateToGmtZone(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { inputControl } = changes;
    if (inputControl && !inputControl.firstChange && inputControl.currentValue !== inputControl.previousValue) {
      const date = moment.utc(inputControl.currentValue?.value).toDate();
      this.datepicker._bsValue = convertDateToGmtZone(date);
    }
  }

  ngAfterViewInit(): void {
    this.isViewInit = true;
    if (this.isOpenOnInit && this.datepicker) {
      this.datepicker.show();
    }
  }

  onValueChange(date: Date): void {
    if (!this.isViewInit) {
      return;
    }

    this.inputControl.setValue(date);
    this.inputControl.markAsUntouched();

    this.valueChange.emit(date);
  }

  stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }

  onToggle(isOpened: boolean): void {
    setTimeout(() => {
      this.toggle.emit(isOpened);
    });
  }
}
