import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { UIDefinition } from '../modules/runtime/model/uiDefinition.model';
import { fileName } from '../modules/runtime/mockups/model/section.model';

@Injectable()
export class DefinitionExportService {
  private ILLEGAL_SYMBOL_REGEX = /[\/\?<>\\:\*\|":]/g;
  private CONTROL_SYMBOL_REGEX = /[\x00-\x1f\x80-\x9f]/g;
  private RESERVED_SYMBOL_REGEX = /^\.+$/;
  private WINDOWS_RESERVED_SYMBOL_REGEX = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  private WINDOWS_ENDING_DOT_REGEX = /\.+$/i;
  private REPLACEMENT_SYMBOL = 'x';

  constructor() {}

  prepareDefinitionZip(definition: UIDefinition, containerName: string) {
    const definitionClone = Object.assign({}, definition);
    const existingPaths: string[] = [];

    let zip = new JSZip();

    if (definitionClone.sections && definitionClone.sections.length) {
      definitionClone.sections.forEach(section => {
        let path;
        if (!section.parentId) {
          path = this.sanitize(section.label, this.REPLACEMENT_SYMBOL);
        } else {
          path = this.getSectionPath(definitionClone.sections, section.parentId, [
            this.sanitize(section.label, this.REPLACEMENT_SYMBOL)
          ]);
        }
        if (existingPaths.indexOf(path) < 0) {
          existingPaths.push(path);
        } else {
          let index = 2;
          let newPath = path;
          while (existingPaths.indexOf(newPath) > -1) {
            newPath = `${path}_${index}`;
            index++;
          }
          path = newPath;
          existingPaths.push(path);
        }
        const nameArr = path.split('/');
        const tabName = definitionClone.tabs.find(tab => tab.id === section.page).name;

        const filePathName = `${containerName}/${definitionClone.name}/${tabName}/${path}/${
          nameArr[nameArr.length - 1]
        }`;

        const templateUrl = `${filePathName}.html`;
        const stylesUrl = `${filePathName}.css`;
        const scriptsUrl = `${filePathName}.js`;
        const propertiesUrl = `${filePathName}.${fileName.properties}`;

        section.templateUrl = templateUrl;
        section.stylesUrl = stylesUrl;
        section.scriptUrl = scriptsUrl;
        section.propertiesUrl = propertiesUrl;

        zip.file(templateUrl, atob(section.template || ''));
        zip.file(stylesUrl, atob(section.styles || ''));
        zip.file(scriptsUrl, atob(section.script || ''));
        zip.file(propertiesUrl, JSON.stringify(section.properties, null, 2) || '');
      });
    }

    zip.file(
      `${containerName}/${definitionClone.name}/${fileName.metadata}`,
      JSON.stringify([definitionClone], null, 2)
    );
    return zip;
  }

  getSectionPath(sections, parentId: string, nameArr: string[]): string {
    const parentSection = sections.find(section => section.id === parentId);
    nameArr.unshift(parentSection.label);
    if (parentSection.parentId) {
      this.getSectionPath(sections, parentSection.parentId, nameArr);
    }
    return nameArr.join('/');
  }

  sanitize(input: string, replacement: string) {
    return input
      .replace(this.ILLEGAL_SYMBOL_REGEX, replacement)
      .replace(this.CONTROL_SYMBOL_REGEX, replacement)
      .replace(this.RESERVED_SYMBOL_REGEX, replacement)
      .replace(this.WINDOWS_RESERVED_SYMBOL_REGEX, replacement)
      .replace(this.WINDOWS_ENDING_DOT_REGEX, replacement);
  }
}
