import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChargeMethod, CurrencyStateItem, CurrencyStateTotalPrices, HeaderItem } from '../../models';

interface TotalPrices {
  value: number;
  netPrice: number;
}

interface ChargeMethodTotals {
  [key: string]: TotalPrices;
}

const calculateTotals = (totals: TotalPrices, listPrice: number, netPrice: number): TotalPrices => ({
  value: totals.value + listPrice,
  netPrice: totals.netPrice + netPrice
});

const getTotalPrice = (totalPrices: { [key: string]: CurrencyStateTotalPrices }): ChargeMethodTotals => {
  return Object.values(totalPrices).reduce(
    (totals: ChargeMethodTotals, { chargeMethod, listPrice, netPrice }) => ({
      ...totals,
      [chargeMethod]: calculateTotals(totals[chargeMethod], listPrice, netPrice)
    }),
    {
      [ChargeMethod.OneTime]: { value: 0, netPrice: 0 },
      [ChargeMethod.Recurring]: { value: 0, netPrice: 0 }
    }
  );
};

@Component({
  selector: 'vl-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductHeaderComponent implements OnInit {
  @Input() lineItem: CurrencyStateItem;
  @Input() isSelected: boolean;
  @Input() isDetailed?: boolean;

  productTotalsItems: HeaderItem[];

  @Output() private handleSelect = new EventEmitter<void>();

  ngOnInit(): void {
    const totals = getTotalPrice(this.lineItem.totalPrices);

    this.productTotalsItems = [
      {
        id: ChargeMethod.OneTime,
        caption: 'One time total',
        type: 'currency',
        ...totals[ChargeMethod.OneTime]
      },
      {
        id: ChargeMethod.Recurring,
        caption: 'Recurrent total',
        type: 'currency',
        ...totals[ChargeMethod.Recurring]
      }
    ];
  }

  captionClickHandler(): void {
    this.handleSelect.emit();
  }

  trackByItemId = (_: number, { id }: HeaderItem): string => id;
}
