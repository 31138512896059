import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderItem } from '../../models';

@Component({
  selector: 'vl-shopping-cart-header-item',
  templateUrl: './shopping-cart-header-item.component.html',
  styleUrls: ['./shopping-cart-header-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShoppingCartHeaderItemComponent {
  @Input() item: HeaderItem;
  @Input() isSelected?: boolean;
  @Input() isReadonly?: boolean;
}
